import type { ComboBoxValidationValue } from "@react-types/combobox";
import type { ValidationError } from "@react-types/shared";
import { useCallback, useMemo } from "react";

import { useI18n } from "../../use-i18n";
import type { DateRange } from "./types";

interface ValidationSpecification {
  required?: { message?: string };
  // require a message for length as we don't know length of what
  minLength?: { message: string; value: number };
  maxLength?: { message: string; value: number };
  minValue?: { message?: string; value: number };
  maxValue?: { message?: string; value: number };
  minDate?: { message?: string; value: string; valueDate: Date };
  maxDate?: { message?: string; value: string; valueDate: Date };
  isDateUnavailable?: {
    message?: string;
    check: (value: Date) => boolean;
  };
  pattern?: { message?: string; value: string };
}

export type InputTypes =
  | number
  | string
  | string[]
  | Date
  | DateRange
  | ComboBoxValidationValue
  | undefined;

type InputType =
  | "number"
  | "string"
  | "array"
  | "date"
  | "range"
  | "combo-selection"
  | "undefined"
  | "unknown";

export type ValidationResult = true | ValidationError | null | undefined;

const getInputType = (value: InputTypes): InputType => {
  if (!value) {
    return "undefined";
  }
  if (Array.isArray(value)) {
    return "array";
  }
  if (
    (value as DateRange)?.start?.toISOString !== undefined &&
    (value as DateRange)?.end?.toISOString !== undefined
  ) {
    return "range";
  }
  if ((value as Date)?.toISOString) {
    return "date";
  }
  if (typeof value === "number") {
    return "number";
  }
  if (Object.hasOwn(value as ComboBoxValidationValue, "selectedKey")) {
    return "combo-selection";
  }
  if (typeof value === "string") {
    return "string";
  }
  console.error(`Attempting to validate an unknown type: "${typeof value}"`, value);
  return "unknown";
};

const getRuleValues = (rules: ValidationSpecification) =>
  Object.entries(rules).reduce(
    (acc, [key, value]) => {
      if (value?.value !== undefined) {
        acc[key] = value.value;
      }
      return acc;
    },
    {} as Record<string, string | number>
  );

const getParams = (
  ruleValues: ValidationSpecification,
  label: string,
  type: InputType,
  value: InputTypes
) =>
  ({
    ...ruleValues,
    label,
    ...(type === "date" && { value: (value as Date).toLocaleDateString() }),
    ...(type === "range" && {
      start: (value as DateRange).start.toLocaleDateString(),
      end: (value as DateRange).end.toLocaleDateString(),
    }),
    ...((type === "string" || type === "number") && { value: `${value}` }),
    ...(type === "array" && { value: (value as string[]).join(", ") }),
  }) as unknown as { [key: string]: string };

const checkRequired = (type: InputType, v: InputTypes) => {
  if (type === "undefined") {
    return false;
  }
  if (typeof v === "number") {
    return !Number.isNaN(v);
  }
  if (type === "date") {
    return v instanceof Date;
  }
  if (type === "range") {
    const { start, end } = v as DateRange;
    return start && end;
  }
  if (type === "combo-selection") {
    return (v as ComboBoxValidationValue).selectedKey !== null;
  }
  if (type === "array") {
    const arrayWithoutNulls = (v as Array<unknown | null | undefined>).filter(
      item => ![null, undefined, ""].includes(item as null | undefined | string)
    );
    return arrayWithoutNulls.length > 0;
  }
  return (v as string | string[]).length > 0;
};

const checkMin = (v: number, min: number) => v < min;

const checkMinLength = (v: string | string[], minLength: number) => checkMin(v.length, minLength);

const checkMax = (v: number, max: number) => v > max;

const checkMaxLength = (v: string | string[], maxLength: number) => checkMax(v.length, maxLength);

const checkPattern = (v: string, pattern: string) => new RegExp(pattern).test(v);

const checkEmail = (v: string) =>
  new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(v);

const checkUrl = (v: string) => new RegExp(/^(http|https):\/\/[^ "]+$/).test(v);

const checkTel = (v: string) => new RegExp(/^[+]*[0-9 -]{6,}$/).test(v);

const checkMinDate = (rules: ValidationSpecification, inputDate: Date) =>
  rules.minDate && inputDate < rules.minDate.valueDate;

const checkMaxDate = (rules: ValidationSpecification, inputDate: Date) =>
  rules.maxDate && inputDate > rules.maxDate.valueDate;

const checkAvailableDate = (rules: ValidationSpecification, inputDate: Date) =>
  rules.isDateUnavailable && rules.isDateUnavailable.check(inputDate);

export const useValidation = ({
  label,
  type,
  rules,
}: {
  label: string;
  type?: string;
  rules: ValidationSpecification;
}) => {
  const { t } = useI18n();

  const ruleValues = useMemo(() => getRuleValues(rules), [rules]);

  const validationHandler = useCallback(
    (inputValue: InputTypes): ValidationResult => {
      const inputType = getInputType(inputValue);
      const params = getParams(ruleValues, label, inputType, inputValue);

      if (type === "unknown") {
        return true;
      }

      // check required first for all fields
      if (rules.required && !checkRequired(inputType, inputValue)) {
        return t(rules.required?.message || "{{label}} is required", params);
      }

      if (["number", "combo-selection", "undefined"].includes(inputType)) {
        // no further validation applies to these types
        return true;
      }

      if (inputType === "date") {
        const inputDate = inputValue as Date;
        if (checkAvailableDate(rules, inputDate)) {
          return t(rules.isDateUnavailable?.message || "The selected date is unavailable", params);
        }
        if (checkMinDate(rules, inputDate)) {
          return t(rules.minDate?.message || "Must be after {{minDate}}", params);
        }
        if (checkMaxDate(rules, inputDate)) {
          return t(rules.maxDate?.message || "Must be before {{maxDate}}", params);
        }
      }

      if (inputType === "range") {
        const { start, end } = inputValue as DateRange;
        if (end < start) {
          return t("The end date must be {{start}} or later", params);
        }
        if (checkMinDate(rules, start)) {
          return t(rules.minDate?.message || "{{label}} must start after {{minDate}}", params);
        }
        if (checkMaxDate(rules, end)) {
          return t(rules.maxDate?.message || "{{label}} must end before {{maxDate}}", params);
        }
        // run each date between start and end through the isDateUnavailable check
        const dateRange = [];
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
          dateRange.push(new Date(d));
        }
        if (rules.isDateUnavailable) {
          const firstUnavailableDate = dateRange.find(d => checkAvailableDate(rules, d));
          if (firstUnavailableDate) {
            return t(rules.isDateUnavailable?.message || "{{label}} is unavailable on {{value}}", {
              ...params,
              value: firstUnavailableDate.toLocaleDateString(),
            });
          }
        }
        return true;
      }

      // string and array types remaining from this point
      if (
        rules.minLength &&
        // validate empty arrays as valid if not required (required arrays are checked above)
        ((inputType === "array" && (inputValue as string[]).length > 0) || inputType === "string")
      ) {
        if (checkMinLength(inputValue as string | string[], rules.minLength.value)) {
          return t(
            rules.minLength.message || "{{label}} must have at least {{minLength}} items",
            params
          );
        }
      }

      if (
        rules.maxLength &&
        checkMaxLength(inputValue as string | string[], rules.maxLength.value)
      ) {
        return t(rules.maxLength.message, params);
      }

      if (inputType === "array") {
        return true;
      }

      // we can now assume inputType is "string"
      const inputString = String(inputValue);

      if (type === "email" && !checkEmail(inputString)) {
        return t("Email format is not valid", params);
      }

      if (type === "url" && !checkUrl(inputString)) {
        return t("URL format is not valid", params);
      }

      if (type === "tel" && !checkTel(inputString)) {
        return t("Phone number format is not valid", params);
      }

      if (type === "tel" && checkMinLength(inputString, 6)) {
        return t("{{label}} must be at least 6 characters long", params);
      }

      if (rules.pattern && !checkPattern(inputString, rules.pattern.value)) {
        return t(rules.pattern?.message || "{{label}} does not match expected format", params);
      }

      // we have exhausted all validation checks and found no errors
      return true;
    },
    [rules]
  );

  return { validationHandler };
};
