import { css } from "@emotion/core";
import type { ChangeEvent, InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import type { TextFieldProps as ReactAriaTextFieldProps } from "react-aria-components";
import {
  TextArea as ReactAriaTextArea,
  TextField as ReactAriaTextField,
} from "react-aria-components";
import { useFormContext } from "react-hook-form";

import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import type { PresetInputWidths } from "./nc-input";
import { inputStyles, inputWidthStyles, NcInput } from "./nc-input";
import { useValidation } from "./use-validation";

interface FieldTextProps
  extends FieldProps,
    PresetInputWidths,
    Omit<ReactAriaTextFieldProps, "name" | "validate" | "onBlur" | "autoFocus"> {
  type?: "textArea" | "text" | "password" | "email" | "tel" | "url" | "search";
}

const styles = css`
  ${inputStyles};

  width: 100%;
`;

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement>, PresetInputWidths {}

export const NcTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoComplete = "off",
      autoCapitalize = "none",
      inputWidth = "full",
      maxLength = 4000,
      ...props
    },
    ref
  ) => {
    return (
      <ReactAriaTextArea
        data-nc="NcTextArea"
        ref={ref}
        css={[styles, inputWidthStyles[inputWidth]]}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        maxLength={maxLength}
        {...props}
      />
    );
  }
);

export const NcFieldText = ({
  label,
  labelNode,
  name,
  description,
  type = "text",
  inputWidth = "large",
  variant,
  onChange = () => {},
  ...props
}: FieldTextProps) => {
  const {
    register,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { validationHandler } = useValidation({
    label,
    type,
    rules: {
      required: props?.isRequired ? {} : undefined,
      minLength: props?.minLength
        ? {
            message: `{{label}} must be at least {{minLength}} characters long`,
            value: props.minLength,
          }
        : undefined,
      maxLength: props?.maxLength
        ? {
            message: `{{label}} must be {{maxLength}} characters long or less`,
            value: props.maxLength,
          }
        : undefined,
      pattern: props?.pattern
        ? {
            value: props.pattern,
          }
        : undefined,
    },
  });
  const field = register(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    onChange?.(value);
    setValue(name, value, { shouldValidate: true });
  };

  return (
    <ReactAriaTextField
      data-nc="NcFieldText"
      defaultValue={defaultValues?.[name]}
      name={name}
      validate={validationHandler}
      {...props}
    >
      <NcField {...{ variant, label: labelNode || label, description }}>
        {type === "textArea" ? (
          <NcTextArea
            {...{
              inputWidth,
              ...field,
              onChange: handleChange,
            }}
          />
        ) : (
          <NcInput
            {...{
              type,
              inputWidth,
              ...field,
              onChange: handleChange,
            }}
          />
        )}
      </NcField>
    </ReactAriaTextField>
  );
};
