import { css } from "@emotion/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import InviteClient from "~/dashboard/clients/invite-client";
import { useI18n } from "~/hooks/use-i18n";

import { useAccount } from "../../account/account.context";
import { Button } from "../../shared/components/forms";
import {
  BREAKPOINT_LARGE_ONLY_QUERY,
  BREAKPOINT_LARGE_QUERY,
  BREAKPOINT_MID_QUERY,
  BREAKPOINT_SMALL_MAX_QUERY,
  BREAKPOINT_XLARGE_QUERY,
  theme,
} from "../../shared/theme";
import { AddIcon, MailIcon } from "../icons";

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${BREAKPOINT_SMALL_MAX_QUERY} {
    margin-top: ${theme.space[3]};
    padding-top: ${theme.space[3]};
    border-top: ${theme.border(theme.colors.neutral.medium)};
    flex-grow: 1;
  }

  @media ${BREAKPOINT_MID_QUERY} {
    border-left: ${theme.border(theme.colors.neutral.medium)};
    flex-direction: column;
    margin-left: ${theme.space[4]};
    padding-left: ${theme.space[4]};
  }

  @media ${BREAKPOINT_LARGE_QUERY} {
    margin-left: ${theme.space[5]};
    padding-left: ${theme.space[5]};
    padding-right: 0.7rem;
  }

  @media ${BREAKPOINT_XLARGE_QUERY} {
    flex-direction: row;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & + button {
      margin: ${theme.space[2]} 0 0 0;

      @media ${BREAKPOINT_SMALL_MAX_QUERY}, ${BREAKPOINT_XLARGE_QUERY} {
        margin: 0 0 0 ${theme.space[2]};
      }
    }

    @media ${BREAKPOINT_MID_QUERY} {
      min-width: 10rem;
      width: 100%;
    }

    @media ${BREAKPOINT_LARGE_ONLY_QUERY} {
      min-width: 11rem;
    }

    svg {
      margin-right: ${theme.space[2]};
    }
  }
`;

const ClientActions = () => {
  const { t } = useI18n("org");
  const { account, organisation } = useAccount();
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const canCreateClients = account.privileges?.find(
    privilege => privilege.key === "PRIV_CAN_CREATE_CLIENT"
  );

  return canCreateClients ? (
    <div data-testid="client-actions" className="client-actions" css={styles}>
      <Button disabled={organisation.readOnlyMode} onClick={() => navigate("/n/clients/add")}>
        <AddIcon />
        {t("org:dashboard.clients.add")}
      </Button>
      <Button onClick={() => setIsInviteModalOpen(true)}>
        <MailIcon />
        {t("org:dashboard.clients.invite.title")}
      </Button>
      <InviteClient isOpen={isInviteModalOpen} closeModal={() => setIsInviteModalOpen(false)} />
    </div>
  ) : (
    <></>
  );
};

export default ClientActions;
