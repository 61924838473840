import { css } from "@emotion/core";
import type { ReactNode } from "react";
import {
  Breadcrumb as ReactAriaBreadcrumb,
  Breadcrumbs as ReactAriaBreadcrumbs,
  type BreadcrumbProps as ReactAriaBreadcrumbProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const styles = {
  breadcrumbs: css`
    display: flex;
    background-color: ${ncTheme.colors.uiLight};
    padding-inline: ${ncTheme.spacing(4)};
    padding-block: ${ncTheme.spacing(2)};
  `,
  breadcrumb: css`
    &:not(:last-child)::after {
      content: "/";
      content: "/" / "";
      alt: " ";
      padding: 0 5px;
    }
  `,
};

interface BreadcrumbsProps {
  children: ReactNode;
}

const NcBreadcrumbs = ({ children, ...props }: BreadcrumbsProps) => {
  return (
    <ReactAriaBreadcrumbs data-nc="NcBreadcrumbs" css={styles.breadcrumbs} {...props}>
      {children}
    </ReactAriaBreadcrumbs>
  );
};

interface BreadcrumbProps extends ReactAriaBreadcrumbProps {
  children: ReactNode;
}

const Breadcrumb = ({ children, ...props }: BreadcrumbProps) => {
  return (
    <ReactAriaBreadcrumb data-nc="NcBreadcrumbs.Breadcrumb" css={styles.breadcrumb} {...props}>
      {children}
    </ReactAriaBreadcrumb>
  );
};

NcBreadcrumbs.Breadcrumb = Breadcrumb;

export { NcBreadcrumbs };
