import { variant } from "styled-system";

import styled, { theme } from "../../theme";
import Box from "../primitives/box";

const menuItemVariants = {
  variants: {
    primary: {
      color: theme.colors.primary.dark,
      backgroundColor: theme.colors.neutral.lightest,
      outlineColor: theme.colors.neutral.medium,
      "&:hover": {
        color: theme.colors.primary.darkest,
        backgroundColor: theme.colors.primary.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:focus": {
        color: theme.colors.primary.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:active": {
        color: theme.colors.primary.darkest,
        backgroundColor: theme.colors.primary.lightest,
        outlineColor: theme.colors.neutral.dark,
      },
      "&:disabled": {
        color: theme.colors.primary.medium,
        backgroundColor: theme.colors.primary.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
    },
    default: {
      color: theme.colors.neutral.darkest,
      backgroundColor: theme.colors.neutral.lightest,
      outlineColor: theme.colors.neutral.medium,
      "&:hover": {
        color: theme.colors.neutral.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:focus": {
        color: theme.colors.neutral.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:active": {
        color: theme.colors.neutral.darkest,
        backgroundColor: theme.colors.neutral.medium,
        outlineColor: theme.colors.neutral.dark,
      },
      "&:disabled": {
        color: theme.colors.neutral.medium,
        backgroundColor: theme.colors.neutral.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
    },
    warning: {
      color: theme.colors.warning.dark,
      backgroundColor: theme.colors.neutral.lightest,
      outlineColor: theme.colors.neutral.medium,
      "&:hover": {
        color: theme.colors.warning.darkest,
        backgroundColor: theme.colors.warning.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:focus": {
        color: theme.colors.warning.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:active": {
        color: theme.colors.warning.darkest,
        backgroundColor: theme.colors.warning.lightest,
        outlineColor: theme.colors.neutral.dark,
      },
      "&:disabled": {
        color: theme.colors.warning.medium,
        backgroundColor: theme.colors.warning.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
    },
    danger: {
      color: theme.colors.danger.dark,
      backgroundColor: theme.colors.neutral.lightest,
      outlineColor: theme.colors.neutral.medium,
      "&:hover": {
        color: theme.colors.danger.darkest,
        backgroundColor: theme.colors.danger.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:focus": {
        color: theme.colors.danger.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:active": {
        color: theme.colors.danger.darkest,
        backgroundColor: theme.colors.danger.lightest,
        outlineColor: theme.colors.neutral.dark,
      },
      "&:disabled": {
        color: theme.colors.danger.medium,
        backgroundColor: theme.colors.danger.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
    },
    info: {
      color: theme.colors.info.dark,
      backgroundColor: theme.colors.neutral.lightest,
      outlineColor: theme.colors.neutral.medium,
      "&:hover": {
        color: theme.colors.info.darkest,
        backgroundColor: theme.colors.info.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:focus": {
        color: theme.colors.info.darkest,
        backgroundColor: theme.colors.neutral.light,
        outlineColor: theme.colors.neutral.medium,
      },
      "&:active": {
        color: theme.colors.info.darkest,
        backgroundColor: theme.colors.info.lightest,
        outlineColor: theme.colors.neutral.dark,
      },
      "&:disabled": {
        color: theme.colors.info.medium,
        backgroundColor: theme.colors.info.lightest,
        outlineColor: theme.colors.neutral.medium,
      },
    },
  },
};

export const MenuItem = styled(Box)`
  ${variant(menuItemVariants)};
  width: 100%;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  -webkit-font-smoothing: subpixel-antialiased;
  user-select: none;
  -webkit-touch-callout: none;
  border: none;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    z-index: 1;
  }
`.withComponent("button");

MenuItem.displayName = "MenuItem";

MenuItem.defaultProps = {
  type: "button",
  variant: "default",
  py: 2,
  px: 4,
};

export default MenuItem;
