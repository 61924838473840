import { DateTime } from "luxon";

type Names = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

export const getFullName = (names: Names) =>
  [names.firstName, names.middleName, names.lastName].filter(Boolean).join(" ");

export const getAge = (birthDate: DateTime) =>
  Math.floor(DateTime.now().diff(birthDate, "years").years);
