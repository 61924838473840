import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { AccountContext } from "~/account/account.context";
import {
  ClientInviteResponse,
  ClientPayload,
  DuplicateClient,
  InviteClientFormData,
  inviteClientFormSchema,
  SendInvitePayload,
  useDuplicateClientsQuery,
  useInviteClientMutation,
  useSendInviteMutation,
} from "~/dashboard/clients/client-invite-queries";
import DuplicateClientsForm from "~/dashboard/clients/duplicate-clients-form";
import InviteClientForm from "~/dashboard/clients/invite-client-form";
import InviteClientModal, { ModalState } from "~/dashboard/clients/invite-client-modal";
import { usePendingInvitesQuery } from "~/dashboard/dashboard-queries";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";

interface InviteClientProps {
  isOpen: boolean;
  closeModal: () => void;
}

const getClientDataInput = ({ firstName, lastName, email }: InviteClientFormData) =>
  ({ firstName, lastName, email }) as ClientPayload;

const getInviteFormInput = ({
  inviteSubject,
  inviteMessage,
  inviteHeading,
  inviteButton,
}: InviteClientFormData) =>
  ({
    inviteSubject,
    inviteMessage,
    inviteHeading,
    inviteButton,
  }) as unknown as SendInvitePayload;

const getObjectsFromFormInput = (values: InviteClientFormData) => ({
  clientData: getClientDataInput(values),
  inviteData: getInviteFormInput(values),
});

const InviteClient = ({ closeModal, isOpen }: InviteClientProps) => {
  const { t } = useI18n(["org", "admin-client-settings"]);
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { organisation } = useContext(AccountContext);
  const settings = JSON.parse(organisation.settingsJson || "{}") as {
    clientInviteDefaults?: { [key: string]: string };
  };

  const defaultValues = {
    ...(t("admin-client-settings:clientInvite.defaults", { returnObjects: true }) as unknown as {
      [key: string]: string;
    }),
    ...settings.clientInviteDefaults,
  };

  const [resolvingDuplicates, setResolvingDuplicates] = useState(false);
  const [mergeWithExisting, setMergeWithExisting] = useState<DuplicateClient | undefined>(
    undefined
  );
  const form = useForm<InviteClientFormData>({
    mode: "onBlur",
    resolver: zodResolver(inviteClientFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, getValues, reset } = form;

  const { clientData, inviteData } = getObjectsFromFormInput(getValues());

  const { data: duplicateClients = [], isLoading: isLoadingDuplicates } = useDuplicateClientsQuery(
    resolvingDuplicates,
    clientData,
    {
      onSuccess(result) {
        // eslint-disable-next-line no-console
        if (result.length === 0) {
          setResolvingDuplicates(false);
          createNewClient();
        }
      },
    }
  );

  const { mutate: doSendInvite, isLoading: isSendingInvite } = useSendInviteMutation();

  const { mutate: doCreateClient, isLoading: isCreatingClient } = useInviteClientMutation();

  const onError = () => {
    enqueueError(t("org:dashboard.clients.invite.error"));
  };

  const sendInvite = (clientId: number) => {
    doSendInvite(
      { clientId, variables: inviteData },
      {
        onSuccess() {
          enqueueSuccess(t("org:dashboard.clients.invite.success", { email: clientData.email }));
          onClose();
        },
        onError,
      }
    );
  };

  const useExistingClient = (client: DuplicateClient) => {
    sendInvite(client.id);
  };

  const createNewClient = () => {
    doCreateClient(
      { variables: clientData },
      {
        onSuccess(client: ClientInviteResponse) {
          if (client.id) {
            return sendInvite(client.id);
          }
        },
        onError,
      }
    );
  };

  const onSubmitDuplicate = () => {
    if (!mergeWithExisting) {
      return createNewClient();
    }
    return useExistingClient(mergeWithExisting);
  };

  const onClose = () => {
    reset();
    setResolvingDuplicates(false);
    setMergeWithExisting(undefined);
    // invalidate to ensure the check is not cached
    queryClient.invalidateQueries(useDuplicateClientsQuery.getKey());
    // invalidate to ensure the new invite is shown
    queryClient.invalidateQueries(usePendingInvitesQuery.getKey());
    closeModal();
  };

  const onSubmitInvited = handleSubmit(() => {
    if (formState.isValid) {
      setResolvingDuplicates(true);
    }
  });

  const getFormState = (): ModalState => {
    if (isLoadingDuplicates || isSendingInvite || isCreatingClient) {
      return "loading";
    }
    if (resolvingDuplicates) {
      return "duplicates";
    }
    return formState.isValid ? "form-valid" : "form-invalid";
  };

  return (
    <>
      {isOpen && (
        <InviteClientModal
          {...{
            isOpen,
            closeModal: onClose,
            onSubmitDuplicate,
            onSubmitInvited,
            state: getFormState(),
          }}
        >
          {{
            inviteClientForm: <InviteClientForm form={form} />,
            duplicateClientsForm: (
              <DuplicateClientsForm
                duplicateClients={duplicateClients}
                selectedClient={mergeWithExisting}
                selectExistingClient={(client: DuplicateClient | undefined) =>
                  setMergeWithExisting(client)
                }
              />
            ),
          }}
        </InviteClientModal>
      )}
    </>
  );
};
export default InviteClient;
