import { useI18n } from "~/hooks/use-i18n";

import { useToast } from "../../shared/components/alerts/alerts";
import Button from "../../shared/components/forms/button";
import { Group } from "../../shared/components/forms/group";
import { LoadingText } from "../../shared/components/loading/loading-text";
import { Box, Flex, Grid, Text } from "../../shared/components/primitives";
import { FancyTick } from "../../shared/icons";
import styled from "../../shared/theme";
import { usePackagesQuery } from "./api";

const PurchaseCardQualifier = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  word-spacing: -0.1em;
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;

const PurchaseCardList = styled(Box)`
  text-align: left;
  list-style-type: none;
`;

const PurchaseCardListItem = styled(Flex)``;

interface IPackageSelectProps {
  selectedPackageId: string;
  selectPackage: ({ packageId, packageName }: { packageId: string; packageName: string }) => void;
  localFeaturePackageId?: string;
}

const PackageSelect = ({ selectPackage, ...otherProps }: IPackageSelectProps) => {
  const { formatCurrency, t } = useI18n("admin-org");
  const { enqueueError } = useToast();

  const selectedPackageId = Number(otherProps.selectedPackageId);
  const localFeaturePackageId = Number(otherProps.localFeaturePackageId);
  const errorLoading = t("error_loading", { component: t("the_packages_list") });

  // const { data, loading, error } = usePackages();
  const { data, isLoading: loading } = usePackagesQuery(undefined, {
    onError: () => enqueueError(errorLoading),
  });

  return (
    <>
      {loading ? (
        <LoadingText text={t("loading")} />
      ) : data && data.featurePackages ? (
        <Box>
          <Grid
            gridTemplateColumns={"repeat(auto-fit, minmax(17rem, 1fr))"}
            gridGap="1.5rem"
            data-testid="package-select"
          >
            {data.featurePackages.map(featurePackage => {
              return (
                <Group
                  title={featurePackage.name}
                  data-testid={`purchase-card-${featurePackage.id}${
                    selectedPackageId === featurePackage.id ? "-active" : ""
                  }`}
                  key={featurePackage.id}
                  pb={0}
                >
                  <Flex flexDirection="column" flex="1 0 auto" justifyContent="space-between">
                    <Box>
                      <Text>{featurePackage.description}</Text>
                      <Text fontSize="6" fontWeight={600}>
                        {formatCurrency(featurePackage.price / 100)}
                      </Text>
                      <PurchaseCardQualifier>{t("users_per_month")}</PurchaseCardQualifier>

                      {featurePackage.packageFeatures && (
                        <PurchaseCardList as="ul" p="0" mx="0">
                          {featurePackage.packageFeatures
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map(packageFeature => (
                              <PurchaseCardListItem
                                mb="2"
                                alignItems="center"
                                key={packageFeature.id}
                              >
                                <Flex style={{ flexShrink: 0 }}>
                                  <FancyTick />
                                </Flex>
                                <span style={{ marginLeft: "0.5rem" }}>{packageFeature.name}</span>
                              </PurchaseCardListItem>
                            ))}
                        </PurchaseCardList>
                      )}
                    </Box>
                    <Button
                      width="100%"
                      variant={"primary"}
                      disabled={localFeaturePackageId === featurePackage.id}
                      data-testid={`package-select-${featurePackage.id}-button`}
                      mt="4"
                      onClick={() =>
                        localFeaturePackageId !== featurePackage.id &&
                        selectPackage({
                          packageId: String(featurePackage.id),
                          packageName: featurePackage.name,
                        })
                      }
                    >
                      {localFeaturePackageId !== featurePackage.id
                        ? `${t("select")} ${featurePackage.name}`
                        : t("current_plan")}
                    </Button>
                  </Flex>
                </Group>
              );
            })}
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default PackageSelect;
