import { css } from "@emotion/core";
import { NcBadge, NcHeading, NcLabel, NcPanel, NcTabs } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { Link } from "~/shared/components/navigation";
import { RightChevronIcon } from "~/shared/icons";
import { theme } from "~/shared/theme";

import { Invite, usePendingInvitesQuery, useReviewInvitesQuery } from "./dashboard-queries";
import { NoInvitesForReviewIcon, NoSentInvitesIcon } from "./icons";

const styles = {
  panel: {
    heading: css`
      font-weight: bold;
    `,
    empty: {
      container: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        gap: ${theme.space[2]};
        justify-content: center;
        padding: ${theme.space[6]};
      `,
      message: css`
        color: ${theme.colors.neutral.dark};
        font-style: italic;
      `,
    },
  },
  listItem: css`
    display: grid;

    .link {
      display: flex;
      gap: ${theme.space[2]};
      align-items: center;
      text-decoration: none;
      color: ${theme.colors.neutral.dark};
      padding: ${theme.space[2]} ${theme.space[1]} ${theme.space[2]} ${theme.space[3]};
      transition: background-color 150ms;

      .icon {
        color: ${theme.colors.neutral.dark};
      }

      &:hover,
      &:focus {
        background-color: ${theme.colors.neutral.light};

        .icon {
          color: ${theme.colors.primary.dark};
        }

        .name {
          text-decoration: underline;
        }
      }

      .email,
      .name {
        flex-grow: 1;
        max-width: 45%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .email {
        max-width: 55%;
      }
    }
  `,
  tabs: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  tabPanel: css`
    border-bottom: none;
    border-left: none;
    border-right: none;
  `,
};

const PanelPendingEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.panel.empty.container}>
      <NoSentInvitesIcon opacity="0.5" width="8rem" />
      <p css={styles.panel.empty.message}>{t("org:dashboard.invites.pending.empty")}</p>
    </div>
  );
};

const PanelReviewEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.panel.empty.container}>
      <NoInvitesForReviewIcon opacity="0.5" width="8rem" />
      <p css={styles.panel.empty.message}>{t("org:dashboard.invites.review.empty")}</p>
    </div>
  );
};

const InviteItem = ({ invite: { id, firstName, lastName, email } }: { invite: Invite }) => {
  return (
    <li css={styles.listItem} key={id}>
      <Link className="link" to={`/n/clients/${id}/edit`}>
        <span className="name">
          {firstName} {lastName}
        </span>
        <span className="email">{email}</span>
        <RightChevronIcon className="icon" />
      </Link>
    </li>
  );
};

const InvitesPanel = () => {
  const { t } = useI18n("org");

  const {
    data: reviewInvites,
    isLoading: isLoadingReview,
    error: errorReview,
  } = useReviewInvitesQuery();
  const {
    data: pendingInvites,
    isLoading: isLoadingPending,
    error: errorPending,
  } = usePendingInvitesQuery();

  const hasError = !!errorReview || !!errorPending;
  const isLoading = isLoadingPending || isLoadingReview;

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcHeading level={2} styleAs={4} css={styles.panel.heading}>
          {t("org:dashboard.invites.heading")}
        </NcHeading>
      </NcPanel.Header>
      {hasError ||
        (isLoading && (
          <NcPanel.Body
            isLoading={isLoading}
            error={
              hasError
                ? t("common:error_loading_refresh", {
                    component: t("org:dashboard.invites.name"),
                  })
                : undefined
            }
          >
            <></>
          </NcPanel.Body>
        ))}
      <NcTabs css={styles.tabs}>
        <NcTabs.TabList aria-label="">
          <NcTabs.Tab id="reviewInvites">
            <NcLabel>{t("org:dashboard.invites.review.heading")}</NcLabel>
            <NcBadge variant="successLight">{reviewInvites?.length}</NcBadge>
          </NcTabs.Tab>
          <NcTabs.Tab id="pendingInvites">
            <NcLabel>{t("org:dashboard.invites.pending.heading")}</NcLabel>
            <NcBadge variant="defaultLight">{pendingInvites?.length}</NcBadge>
          </NcTabs.Tab>
        </NcTabs.TabList>
        <NcTabs.TabPanel id="reviewInvites" css={styles.tabPanel}>
          {reviewInvites?.length ? (
            <ul>
              {reviewInvites.map((invite: Invite) => (
                <InviteItem invite={invite} key={invite.id} />
              ))}
            </ul>
          ) : (
            <PanelReviewEmpty />
          )}
        </NcTabs.TabPanel>
        <NcTabs.TabPanel id="pendingInvites" css={styles.tabPanel}>
          {pendingInvites?.length ? (
            <ul>
              {pendingInvites.map((invite: Invite) => (
                <InviteItem invite={invite} key={invite.id} />
              ))}
            </ul>
          ) : (
            <PanelPendingEmpty />
          )}
        </NcTabs.TabPanel>
      </NcTabs>
    </NcPanel>
  );
};

export default InvitesPanel;
