import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { SwitchProps as ReactAriaSwitchProps } from "react-aria-components";
import { Switch as ReactAriaSwitch } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { svgToDataUri } from "../../utils";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import { checkboxStyles } from "./nc-field-checkbox";

interface FieldSwitchProps extends FieldProps, Omit<ReactAriaSwitchProps, "name" | "onBlur"> {}

const svgs = {
  handle: svgToDataUri(`
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="-2 -2 28 28">
    <circle class="handle" cx="12" cy="12" r="11" fill="${ncTheme.colors.uiLight}" stroke="${ncTheme.colors.ui}"/>
  </svg>
`),
  tick: svgToDataUri(`
  <svg viewBox="0 0 16 16" fill="${ncTheme.colors.light}" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/>
  </svg>
`),
  cross: svgToDataUri(`
  <svg viewBox="0 0 17 16" height="16" width="16" xmlns="http://www.w3.org/2000/svg" fill="${ncTheme.colors.light}">
    <path d="M11.7384 4.26161C12.0872 4.61041 12.0872 5.17594 11.7384 5.52475L9.26314 7.99999L11.7384 10.4753C12.0872 10.8241 12.0872 11.3896 11.7384 11.7384C11.3896 12.0872 10.8241 12.0872 10.4753 11.7384L8 9.26314L5.52474 11.7384C5.17594 12.0872 4.61041 12.0872 4.2616 11.7384C3.9128 11.3896 3.9128 10.8241 4.2616 10.4753L6.73686 7.99999L4.26162 5.52475C3.91281 5.17594 3.91281 4.61041 4.26162 4.26161C4.61042 3.9128 5.17595 3.9128 5.52476 4.26161L8 6.73685L10.4752 4.26161C10.824 3.9128 11.3896 3.9128 11.7384 4.26161Z" />
  </svg>
`),
};

const styles = {
  wrap: css`
    ${checkboxStyles.wrap};
  `,
  switch: css`
    appearance: none;
    cursor: pointer;
    padding: 0;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    flex-shrink: 0;
    height: ${ncTheme.spacing(7)};
    aspect-ratio: 2/1;
    border-radius: ${ncTheme.borderRadius.rounded};
    color: ${ncTheme.colors.success};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    transition-property: box-shadow, background, border-color;
    transition-duration: ${ncTheme.transitionSpeed.fast};
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background-color: ${ncTheme.colors.main};
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:
      left,
      -200%,
      right;
    background-image: url("${svgs.handle}"), url("${svgs.tick}"), url("${svgs.cross}");

    &:hover,
    [data-hovered] > & {
      color: ${ncTheme.colors.active};
      background-color: ${ncTheme.colors.active};
    }

    [data-pressed] > &,
    [data-selected][data-pressed] > & {
      background-position:
        50%,
        -200%,
        200%;
    }

    [data-selected] > & {
      background-color: currentColor;
      background-position: right, left, 200%;
    }

    [data-disabled] > & {
      background-color: ${ncTheme.colors.disabled};
      position: relative;
      svg {
        fill: ${ncTheme.colors.disabled};
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${ncTheme.colors.disabledLight};
        border-radius: ${ncTheme.borderRadius.rounded};
        z-index: 1;
        opacity: 0.5;
      }
    }
  `,
  label: css`
    ${checkboxStyles.label};
  `,
};

interface SwitchProps
  extends Omit<ReactAriaSwitchProps, "defaultSelected" | "isSelected" | "validate" | "onBlur"> {
  children: ReactNode;
}

const NcSwitch = forwardRef<HTMLLabelElement, SwitchProps>(({ children, ...props }, ref) => {
  return (
    <ReactAriaSwitch data-nc="NcSwitch" ref={ref} css={styles.wrap} {...props}>
      <div css={styles.switch}></div>
      <div css={styles.label}>{children}</div>
    </ReactAriaSwitch>
  );
});

export const NcFieldSwitch = ({
  label,
  labelNode,
  name,
  description,
  ...props
}: FieldSwitchProps) => {
  const { register, setValue, watch } = useFormContext();
  const field = register(name);
  const watchedValue = watch(name);

  const handleChange = (isSelected: boolean) => {
    setValue(name, isSelected, { shouldValidate: true, shouldDirty: true });
    props?.onChange?.(isSelected);
  };

  return (
    <NcField data-nc="NcFieldSwitch" description={description}>
      <NcSwitch
        {...field}
        onChange={handleChange}
        name={name}
        defaultSelected={watchedValue}
        {...props}
      >
        {labelNode || label}
      </NcSwitch>
    </NcField>
  );
};
