import { css } from "@emotion/core";
import { NcBadge, NcHeading, NcPanel } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { theme } from "~/shared/theme";
import ListLayout from "~/shared/ui/list-layout";

import { UnsignedRecord, useUnsignedRecordsQuery } from "./dashboard-queries";
import { LetterIcon, NoRecordsIcon, NoteIcon, RecordCourseIcon, SessionIcon } from "./icons";

const styles = {
  panel: {
    heading: css`
      line-height: 1;
      margin-right: auto;
    `,
    empty: {
      container: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        gap: ${theme.space[2]};
        justify-content: center;
        padding: ${theme.space[6]};
      `,
      message: css`
        color: ${theme.colors.neutral.dark};
        font-style: italic;
      `,
    },
  },
};

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.panel.empty.container}>
      <NoRecordsIcon opacity="0.5" width="6rem" />
      <p css={styles.panel.empty.message}>{t("org:dashboard.unsigned_records.empty")}</p>
    </div>
  );
};

function getRecordLink(record: UnsignedRecord) {
  if (record.client) {
    return `/n/clients/${record.client.id}/records/consultation/${record.id}`;
  }
  if (record.group) {
    return `/n/groups/${record.group.id}/sessions/${record.id}`;
  }
  return "";
}

function getRecordIcon(recordType: string) {
  const iconProps = {
    width: "1.5rem",
    color: theme.colors.neutral.mediumDark,
  };
  switch (recordType) {
    case "NOTE":
      return <NoteIcon {...iconProps} />;
    case "LETTER":
      return <LetterIcon {...iconProps} />;
    case "SESSION":
      return <SessionIcon {...iconProps} />;
    default:
      return <RecordCourseIcon {...iconProps} />;
  }
}

const UnsignedRecordsPanel = () => {
  const { t } = useI18n("org");

  const { data: unsignedRecords, isLoading, error } = useUnsignedRecordsQuery();

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcHeading level={2} styleAs={4} css={styles.panel.heading}>
          {t("org:dashboard.unsigned_records.heading")}
        </NcHeading>
        {unsignedRecords && <NcBadge variant="dangerLight">{unsignedRecords.length}</NcBadge>}
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.unsigned_records.name"),
              })
            : undefined
        }
      >
        {unsignedRecords?.length ? (
          <ListLayout>
            {unsignedRecords.map((record: UnsignedRecord) => {
              const { id, client, group } = record;
              return (
                <ListLayout.ItemLink key={id} to={getRecordLink(record)}>
                  {getRecordIcon(record.type)}
                  <div data-testid="unsigned-record-name">
                    {client?.firstName ?? ""} {client?.lastName} {group?.name}
                  </div>
                </ListLayout.ItemLink>
              );
            })}
          </ListLayout>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default UnsignedRecordsPanel;
