import { css } from "@emotion/core";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcButton } from "./nc-button";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  list: css`
    display: flex;
    align-content: baseline;
    justify-content: space-between;

    li {
      .navShortcut {
        padding-inline: ${ncTheme.spacing(2)};
      }

      &:first-of-type .navShortcut {
        padding-left: 0;
      }

      &:last-of-type .navShortcut {
        padding-right: 0;
      }
    }
  `,
  loading: css`
    padding-block: ${ncTheme.spacing(2)};
  `,
  pageItem: css`
    display: none;
    margin-left: -${ncTheme.borderWidth.medium};

    ${ncTheme.mediaQueries.width.medium} {
      display: initial;
    }

    &[aria-current="page"] button {
      &,
      &:hover,
      &:focus,
      &:active,
      &[data-pressed="true"] {
        cursor: default;
        border-color: ${ncTheme.colors.main};
        background-color: ${ncTheme.colors.main};
        color: ${ncTheme.colors.light};
        z-index: 0;
      }
    }
  `,
  activePageItem: css`
    display: initial;
  `,
  firstLastNavItem: css`
    display: none;

    ${ncTheme.mediaQueries.width.medium} {
      display: initial;
    }
  `,
  pages: css`
    display: flex;
    margin-inline: ${ncTheme.spacing(2)};

    li {
      button {
        min-width: 2.25em;
        border-radius: 0;
      }
      &:first-of-type button {
        border-top-left-radius: ${ncTheme.borderRadius.medium};
        border-bottom-left-radius: ${ncTheme.borderRadius.medium};
      }

      &:last-of-type button {
        border-top-right-radius: ${ncTheme.borderRadius.medium};
        border-bottom-right-radius: ${ncTheme.borderRadius.medium};
      }
    }
  `,
  page: css`
    position: relative;
    padding-block: ${ncTheme.spacing(2)};
    padding-inline: ${ncTheme.spacing(2)};

    &:focus {
      z-index: 1;
    }
  `,
};

interface NcPaginatorProps {
  currentPage: number;
  totalPages: number;
  maxCurrentAdjacentPages?: number;
  onPageChange: (page: number) => void;
  isLoading?: boolean;
}

const NcPaginator = ({
  currentPage,
  totalPages,
  maxCurrentAdjacentPages = 2,
  onPageChange,
  isLoading = false,
}: NcPaginatorProps) => {
  const { t } = useI18n();
  const range = (num: number) => [...Array(num)].map((_, i) => i + 1);
  const FilterForMaxItems = (n: number) =>
    currentPage + maxCurrentAdjacentPages >= n && currentPage - maxCurrentAdjacentPages <= n;

  const pages = range(totalPages).filter(FilterForMaxItems);

  function getPageAriaLabel(page: number) {
    const isCurrentPage = page === currentPage;
    return isCurrentPage ? `${t("current page")}, ${page}` : `${t("go to page")} ${page}`;
  }

  return (
    <nav
      data-nc="NcPaginator"
      data-testid="pagination"
      role="navigation"
      aria-label={t("Pagination navigation")}
    >
      <ul css={styles.list}>
        {isLoading ? (
          <li css={styles.loading}>
            <NcLoadingIndicator />
          </li>
        ) : (
          <>
            <li css={styles.firstLastNavItem}>
              <NcButton
                variant="link"
                css={styles.page}
                isDisabled={currentPage <= 1}
                onPress={() => onPageChange(0)}
                className="visibleMediumPlus navShortcut"
              >
                {t("First")}
              </NcButton>
            </li>

            <li>
              <NcButton
                variant="link"
                css={styles.page}
                isDisabled={currentPage <= 1}
                onPress={() => onPageChange(currentPage - 1)}
                className="visibleMediumPlus navShortcut"
              >
                {t("Previous")}
              </NcButton>
            </li>

            <div css={styles.pages}>
              {pages.map(page => {
                const pageAriaLabel = getPageAriaLabel(page);
                return (
                  <li
                    key={page}
                    css={[styles.pageItem]}
                    aria-current={page === currentPage ? "page" : undefined}
                  >
                    <NcButton
                      css={[styles.page]}
                      onPress={() => onPageChange(page)}
                      aria-label={pageAriaLabel}
                      className="pageNumber"
                    >
                      {page}
                    </NcButton>
                  </li>
                );
              })}
            </div>

            <li>
              <NcButton
                variant="link"
                css={styles.page}
                isDisabled={currentPage >= totalPages}
                onPress={() => onPageChange(currentPage + 1)}
                className="visibleMediumPlus navShortcut"
              >
                {t("Next")}
              </NcButton>
            </li>

            <li css={styles.firstLastNavItem}>
              <NcButton
                variant="link"
                css={styles.page}
                isDisabled={currentPage >= totalPages}
                onPress={() => onPageChange(totalPages)}
                className="visibleMediumPlus navShortcut"
              >
                {t("Last")}
              </NcButton>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export { NcPaginator };
