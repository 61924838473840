import { css } from "@emotion/core";
import { ComponentProps, HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";

import { Button } from "~/shared/components/forms";
import { NavigableListItem } from "~/shared/ui/a11y/navigable-list";

import { theme } from "../theme";

type ListLayoutProps = HTMLAttributes<HTMLUListElement> & {
  className?: string;
  children: ReactNode;
};

type ListItemCommonProps = {
  children: ReactNode;
  className?: string;
};

const styles = {
  list: css``,
  highlight: css`
    &:hover,
    &:focus,
    &:active {
      background-color: ${theme.colors.neutral.light};
    }
  `,
  padding: css`
    padding: 0.5em ${theme.space[3]};
  `,
  item: css`
    display: flex;
    align-items: center;
    gap: ${theme.space[3]};
    padding: 0.5em ${theme.space[3]};
    transition:
      background-color 150ms,
      color 150ms;
    line-height: 1.5;
  `,
  link: css`
    a:visited,
    a {
      cursor: pointer;
      color: ${theme.colors.neutral.dark};

      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.info.lightest};
      }
    }
  `,
  button: css`
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    font-size: 1em;
    line-height: 1.5;

    &:hover,
    &:focus,
    &:active {
      border: 0;
    }
  `,
};

const ListItem = ({
  className = "",
  children,
  ...props
}: ComponentProps<"li"> & ListItemCommonProps) => {
  return (
    <NavigableListItem
      className={`list-item ${className}`}
      css={[styles.item, styles.highlight]}
      {...props}
    >
      {children}
    </NavigableListItem>
  );
};

const ListItemButton = ({
  className = "",
  children,
  ...props
}: ComponentProps<typeof Button> & ListItemCommonProps) => {
  return (
    <NavigableListItem className={className}>
      <Button className="list-item" {...props} css={[styles.item, styles.button]}>
        {children}
      </Button>
    </NavigableListItem>
  );
};

const ListItemLink = ({
  className = "",
  children,
  to,
  ...props
}: ListItemCommonProps & ComponentProps<typeof Link>) => {
  return (
    <NavigableListItem className={className} css={styles.link}>
      <Link to={to} className="list-item" {...props} css={[styles.item, styles.highlight]}>
        {children}
      </Link>
    </NavigableListItem>
  );
};

const ListItemLinkExternal = ({
  className = "",
  children,
  href,
  ...props
}: HTMLAttributes<HTMLLinkElement> & ComponentProps<"a">) => {
  return (
    <NavigableListItem className={className} css={styles.link}>
      <a href={href} className="list-item" css={[styles.item, styles.highlight]} {...props}>
        {children}
      </a>
    </NavigableListItem>
  );
};

const ListLayout = ({ className = "", children, ...props }: ListLayoutProps) => {
  return (
    <ul className={`list-layout ${className}`} css={styles.list} {...props}>
      {children}
    </ul>
  );
};

ListLayout.Item = ListItem;
ListLayout.ItemLink = ListItemLink;
ListLayout.ItemLinkExternal = ListItemLinkExternal;
ListLayout.ItemButton = ListItemButton;
export default ListLayout;
