import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcButton } from "./nc-button";
import {
  NcIconCross,
  NcIconExclamationCircle,
  NcIconExclamationTriangle,
  NcIconInformationCircle,
  NcIconTickCircle,
} from "./nc-icons";

const alertStyles = {
  alert: {
    base: css`
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: start;
      gap: ${ncTheme.spacing(2)};
      overflow: hidden;
      border-radius: ${ncTheme.borderRadius.medium};
      border: ${ncTheme.border(ncTheme.colors.ui)};
      background-color: ${ncTheme.colors.light};
      padding-block: ${ncTheme.spacing(3)};
      padding-left: ${ncTheme.spacing(5)};
      padding-right: ${ncTheme.spacing(3)};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: ${ncTheme.spacing(1.5)};
      }
    `,
    variant: {
      default: css`
        border-color: ${ncTheme.colors.main}75;

        &::before {
          background-color: ${ncTheme.colors.main};
        }
      `,
      success: css`
        border-color: ${ncTheme.colors.success}75;

        &::before {
          background-color: ${ncTheme.colors.success};
        }
      `,
      danger: css`
        border-color: ${ncTheme.colors.danger}75;

        &::before {
          background-color: ${ncTheme.colors.danger};
        }
      `,
    },
    heading: css`
      font-weight: ${ncTheme.fontWeight.bold};
      font-size: ${ncTheme.fontSizes[3]};
    `,
    icon: css`
      padding-top: ${ncTheme.spacing(0.5)};
      line-height: 1;
    `,
    colourVariant: {
      default: css`
        color: ${ncTheme.colors.main};
      `,
      success: css`
        color: ${ncTheme.colors.success};
      `,
      danger: css`
        color: ${ncTheme.colors.danger};
      `,
    },
  },
  content: css`
    display: grid;
    gap: ${ncTheme.spacing(1)};
    justify-items: start;
  `,
  close: css``,
};

interface AlertProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  variant?: keyof typeof alertStyles.alert.variant;
  title?: string | ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}

const getAlertIcon = (t: (value: string) => string, variant?: string) => {
  switch (variant) {
    case "success":
      return <NcIconTickCircle alt="" />;
    case "warning":
      return <NcIconExclamationTriangle alt={t("warning")} />;
    case "danger":
      return <NcIconExclamationCircle alt={t("alert")} />;
    default:
      return <NcIconInformationCircle alt="" />;
  }
};

const NcAlert = ({ variant = "default", icon, title, children, onClose, ...props }: AlertProps) => {
  const { t } = useI18n();
  return (
    <div
      css={css([alertStyles.alert.base, alertStyles.alert.variant[variant]])}
      {...props}
      role="alert"
      data-nc="NcAlert"
    >
      <div css={[alertStyles.alert.icon, alertStyles.alert.colourVariant[variant]]}>
        {icon ?? getAlertIcon(t, variant as string)}
      </div>
      <div css={css(alertStyles.content)}>
        {title && (
          <div css={[alertStyles.alert.heading, alertStyles.alert.colourVariant[variant]]}>
            {title}
          </div>
        )}
        {children}
      </div>
      {onClose && (
        <NcButton variant="icon" css={alertStyles.close} onPress={onClose}>
          <NcIconCross alt={t("close")} />
        </NcButton>
      )}
    </div>
  );
};

export { NcAlert };
