import { css } from "@emotion/core";
import { NcBadge, NcHeading, NcPanel } from "@noted/noted-components";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";
import { theme } from "~/shared/theme";

import { useAppointmentsQuery } from "../dashboard-queries";
import { CalendarIcon, NoAppointmentsIcon } from "../icons";
import { AppointmentActionsMenu } from "./appointment-actions-menu";
import { AccessLevel } from "./types";

const styles = {
  panel: {
    heading: css`
      font-weight: bold;
      margin-right: auto;
    `,
    empty: {
      container: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        gap: ${theme.space[2]};
        justify-content: center;
        padding: ${theme.space[6]};
      `,
      message: css`
        color: ${theme.colors.neutral.dark};
        font-style: italic;
      `,
    },
    link: css`
      align-items: center;
      display: flex;
      color: ${theme.colors.neutral.dark};
      margin: 0 ${theme.space[3]} 0 0;

      &:visited {
        color: ${theme.colors.neutral.dark};
      }

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.primary.dark};
      }
    `,
  },
  list: {
    item: {
      container: css`
        display: grid;
        padding: ${theme.space[3]};
        grid-template-columns: auto 5fr 3fr 1fr;
        gap: ${theme.space[4]};
        align-items: start;
      `,
      wrapper: css`
        display: grid;
        gap: ${theme.space[1]};
      `,
      notes: css`
        font-style: italic;
      `,
    },
  },
  details: {
    container: css`
      color: ${theme.colors.neutral.mediumDark};
    `,
    collapse: css`
      cursor: pointer;
      color: ${theme.colors.neutral.mediumDark} & [open] summary {
        margin-bottom: ${theme.space[2]};
      }
    `,
  },
};

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.panel.empty.container}>
      <NoAppointmentsIcon opacity="0.5" width="8rem" />
      <p css={styles.panel.empty.message}>{t("org:dashboard.appointments.empty")}</p>
    </div>
  );
};

const AppointmentsPanel = () => {
  const { t } = useI18n("org");

  const { data: appointments, isLoading, error } = useAppointmentsQuery();

  function getFromTime(from: number) {
    return DateTime.fromMillis(from).toFormat("h:mma");
  }

  function isRestricted(accessLevel: AccessLevel) {
    return accessLevel !== "FULL";
  }

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcHeading level={2} styleAs={4} css={styles.panel.heading}>
          {t("org:dashboard.appointments.heading")}
        </NcHeading>
        <Link
          css={styles.panel.link}
          to="/scheduling/booking"
          title={t("org:dashboard.appointments.link")}
        >
          <CalendarIcon role="presentation" width="1.4rem" />
        </Link>
        <NcBadge variant="defaultLight">{appointments?.length}</NcBadge>
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.appointments.name"),
              })
            : undefined
        }
      >
        {appointments?.length ? (
          <ul>
            {appointments.map(appointment => {
              const {
                id,
                notes,
                client,
                service,
                from,
                duration,
                confirmed,
                arrived,
                cancelled,
                completed,
                paid,
              } = appointment;
              return (
                <li css={styles.list.item.container} key={id}>
                  <div css={styles.list.item.wrapper}>
                    <div>{getFromTime(from)}</div>
                    <div css={styles.details.container}>{duration}m</div>
                  </div>
                  <div css={styles.list.item.wrapper}>
                    <div>{client.name}</div>
                    <div css={styles.details.container}>
                      <details css={styles.details.collapse}>
                        <summary>{service.name}</summary>
                        {notes ? (
                          notes
                        ) : (
                          <div css={styles.list.item.notes}>
                            {t("org:dashboard.appointments.no_notes")}
                          </div>
                        )}
                      </details>
                    </div>
                  </div>
                  <div>
                    {confirmed && !arrived && !cancelled && !completed ? (
                      <NcBadge variant="neutralLight">
                        {t("org:dashboard.appointments.status.confirmed")}
                      </NcBadge>
                    ) : null}
                    {cancelled ? (
                      <NcBadge variant="dangerLight">
                        {t("org:dashboard.appointments.status.cancelled")}
                      </NcBadge>
                    ) : null}
                    {arrived && !completed && !cancelled ? (
                      <NcBadge variant="defaultLight">
                        {t("org:dashboard.appointments.status.arrived")}
                      </NcBadge>
                    ) : null}
                    {completed ? (
                      <NcBadge variant="successLight">
                        {t("org:dashboard.appointments.status.completed")}
                      </NcBadge>
                    ) : null}
                    {paid ? (
                      <NcBadge variant="successLight">
                        {t("org:dashboard.appointments.status.paid")}
                      </NcBadge>
                    ) : null}
                  </div>
                  {!isRestricted(client.accessLevel) && (
                    <AppointmentActionsMenu
                      css={css`
                        margin-left: auto;
                      `}
                      appointment={appointment}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default AppointmentsPanel;
