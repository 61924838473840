import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";

import { config } from "../administration/users/config";
import { restPoster } from "../graphql-hooks/custom-fetcher";
import { useToast } from "../shared/components/alerts/alerts";
import { Button } from "../shared/components/forms";
import { PagePublic, Shell } from "../shared/components/layout";
import MessageBox from "../shared/components/message-box/message-box";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "../shared/components/overlay-message-box";
import { PageTitle, Text } from "../shared/components/primitives";
import { useIsMounted } from "../shared/hooks/use-is-mounted";
import { ExclamationCircleOutline, LogoIcon, TickCircleOutline } from "../shared/icons";
import { theme } from "../shared/theme";
import { Feedback, Form, FormRow, Input, Label } from "../shared/ui";

interface IResendVerificationForm {
  email: string;
}

const ORG_IS_VALID_ERROR = "Clinics email is already verified";

const Verify = () => {
  const location = useLocation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { t } = useI18n("account");
  const { enqueueError } = useToast();

  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResendVerificationForm>({ mode: "onChange" });

  const [isLoaded, setIsLoaded] = useState(false);
  const [resendIsSuccessful, setResendIsSuccessful] = useState(false);
  const [isResendFormTriggered, setIsResendFormTriggered] = useState(false);

  const { mutate: verifyAccount, error: verifyError } = useMutation<
    { token: string },
    { message?: string },
    unknown
  >(body => restPoster("/v1/signup/verifyEmail", body), {
    onSuccess() {
      setIsLoaded(true);
    },
  });

  const {
    mutate: resendEmail,
    error: resendError,
    isLoading: resendSaving,
  } = useMutation<{ email: string }, { message?: string }, unknown>(
    body => restPoster("/v1/signup/resendWelcomeEmail", body),
    {
      onSuccess() {
        if (isMounted()) {
          setResendIsSuccessful(true);
        }
      },
    }
  );

  const noToken = !token && !isResendFormTriggered;
  const verifySuccessful = token && !verifyError && isLoaded;
  const verifyFailure = verifyError && !isResendFormTriggered;
  const loading = token && !isLoaded && !verifyError;

  const onResendVerificationClicked = () => {
    setIsResendFormTriggered(true);
  };

  const onSendAnotherResetClicked = () => {
    setResendIsSuccessful(false);
  };

  const onSendVerificationPressed = async ({ email }: IResendVerificationForm) => {
    resendEmail({ email });
  };

  useEffect(() => {
    if (resendError && resendError.message !== ORG_IS_VALID_ERROR) {
      enqueueError(t("account:verify_account.resend_verification_failure"));
    }
  }, [resendError, enqueueError, t]);

  const onContinueClicked = () => {
    if (isMounted()) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) {
      const onVerify = async () => {
        verifyAccount({ token });
      };
      onVerify();
    }
  }, [token, verifyAccount]);

  return (
    <Shell>
      <PagePublic>
        {verifySuccessful && (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight bg={theme.colors.primary.dark}>
              <TickCircleOutline />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:verify_account.verification_success")}</PageTitle>
              <Text as="p">{t("account:verify_account.verification_success_description")}</Text>
              <Button mt="4" mb="2" onClick={onContinueClicked}>
                {t("continue")}
              </Button>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}

        {loading && (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight>
              <LogoIcon />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:verify_account.verifying")}</PageTitle>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}

        {(noToken || verifyFailure) && (
          <OverlayMessageBox flexBasis="40rem">
            <OverlayMessageBoxHighlight bg={theme.colors.warning.mediumDark}>
              <ExclamationCircleOutline />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:verify_account.verification_error")}</PageTitle>
              <Text as="p">{t("account:verify_account.verification_error_description")}</Text>
              <Button variant="default" mt="4" mb="2" onClick={onResendVerificationClicked}>
                {t("account:verify_account.resend_verification_email")}
              </Button>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}

        {isResendFormTriggered && !resendIsSuccessful && (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight>
              <LogoIcon />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:verify_account.resend_verification_email")}</PageTitle>
              <Form onSubmit={handleSubmit(onSendVerificationPressed)}>
                <FormRow>
                  <Label htmlFor="email">{t("account:verify_account.organisation_email")}</Label>
                  <Input
                    id="email"
                    {...register("email", {
                      required: t("account:signup.email_required"),
                      pattern: {
                        value: config.email.pattern,
                        message: t("form_validation.email_pattern"),
                      },
                    })}
                  />
                  {errors && errors.email && <Feedback>{errors.email.message}</Feedback>}
                  {resendError && resendError.message === ORG_IS_VALID_ERROR && (
                    <MessageBox my="4" variant="info">
                      {t("account:verify_account.already_verified")}
                    </MessageBox>
                  )}
                </FormRow>
                <Button my="2" variant="primary" type="submit" disabled={resendSaving}>
                  {t("account:verify_account.submit_verification_resend")}
                </Button>
              </Form>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}

        {isResendFormTriggered && resendIsSuccessful && (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight bg={theme.colors.primary.dark}>
              <TickCircleOutline />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:verify_account.resend_verification_success")}</PageTitle>
              <Text mb="2">
                {t("account:verify_account.resend_verification_success_description")}
              </Text>
              <Button my="2" onClick={onSendAnotherResetClicked}>
                {t("account:verify_account.send_another_button")}
              </Button>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}
      </PagePublic>
    </Shell>
  );
};

export default Verify;
