import { css } from "@emotion/core";
import { NcBadge, NcHeading, NcPanel } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { theme } from "~/shared/theme";
import ListLayout from "~/shared/ui/list-layout";

import { useUpdatedRecordsQuery } from "./dashboard-queries";
import { NoRecordsIcon } from "./icons";

const styles = {
  heading: css`
    font-weight: bold;
    margin-right: auto;
  `,
  emptyContainer: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    gap: ${theme.space[2]};
    justify-content: center;
    padding: ${theme.space[6]};

    .message {
      color: ${theme.colors.neutral.mediumDark};
      font-style: italic;
    }
  `,
  list: css`
    .space-between a {
      display: flex;
      justify-content: space-between;
    }
  `,
};

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.emptyContainer}>
      <NoRecordsIcon opacity="0.5" width="6rem" />
      <p className="message">{t("org:dashboard.updated_records.empty")}</p>
    </div>
  );
};

const UpdatedRecordsPanel = () => {
  const { t } = useI18n("org");

  const { data: updatedRecords, isLoading, error } = useUpdatedRecordsQuery();

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcHeading level={2} styleAs={4} css={styles.heading}>
          {t("org:dashboard.updated_records.heading")}
        </NcHeading>
        <NcBadge variant="defaultLight">{updatedRecords?.length}</NcBadge>
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.updated_records.name"),
              })
            : undefined
        }
      >
        {updatedRecords?.length ? (
          <ListLayout css={styles.list}>
            {updatedRecords.map(({ patientId, firstName, lastName, recordsAdded }) => (
              <ListLayout.ItemLink
                key={patientId}
                className="space-between"
                to={`/n/clients/${patientId}/records`}
              >
                <span>
                  {firstName} {lastName}
                </span>
                <NcBadge variant="defaultLight">{recordsAdded}</NcBadge>
              </ListLayout.ItemLink>
            ))}
          </ListLayout>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default UpdatedRecordsPanel;
