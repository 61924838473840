import { css } from "@emotion/core";
import { UseFormReturn } from "react-hook-form";

import { InviteClientFormData } from "~/dashboard/clients/client-invite-queries";
import { useI18n } from "~/hooks/use-i18n";
import { theme } from "~/shared/theme";
import { Form, FormRow, Input, Label, TextArea } from "~/shared/ui";

const styles = {
  form: css`
    margin: 0 auto;

    .label {
      margin-bottom: ${theme.space[2]};
    }
  `,
  expandingForm: css`
    background: ${theme.colors.neutral.light};
    border: ${theme.border(theme.colors.neutral.medium)};
    border-radius: ${theme.borderRadius.small};
    margin-top: ${theme.space[1]};
    width: 100%;

    .summary {
      cursor: pointer;
      padding: ${theme.space[2]};
      line-height: 1.3;
    }

    .details {
      display: grid;
      gap: ${theme.space[3]};
      padding: ${theme.space[2]};
    }
  `,
  errorMessage: css`
    &:empty {
      display: none;
    }

    color: ${theme.colors.danger.dark};
  `,
};

interface InviteClientFormProps {
  form: UseFormReturn<InviteClientFormData>;
}

const FormError = ({ id, message }: { id: string; message?: string }) => {
  const { t } = useI18n("org");
  return (
    <div id={id} css={styles.errorMessage} aria-live="polite">
      {message && <span>{t(message)}</span>}
    </div>
  );
};

const InviteClientForm = ({ form }: InviteClientFormProps) => {
  const { t } = useI18n("org");

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Form css={styles.form} id="invite-client-form">
      <FormRow>
        <Label>
          <span className="label">{t("org:dashboard.clients.invite.form.first_name")}*</span>
          <Input {...register("firstName")} required aria-describedby="firstNameMessage" />
        </Label>
        <FormError id="firstNameMessage" message={errors.firstName?.message} />
        <Label>
          <span className="label">{t("org:dashboard.clients.invite.form.last_name")}*</span>
          <Input {...register("lastName")} required aria-describedby="lastNameMessage" />
        </Label>
        <FormError id="lastNameMessage" message={errors.lastName?.message} />
      </FormRow>
      <FormRow>
        <Label>
          <span className="label">{t("org:dashboard.clients.invite.form.email")}*</span>
          <Input {...register("email")} type="email" required aria-describedby="emailMessage" />
        </Label>
        <FormError id="emailMessage" message={errors.email?.message} />
      </FormRow>
      <details css={styles.expandingForm}>
        <summary className="summary">{t("org:dashboard.clients.invite.form.customise")}</summary>
        <div className="details">
          <FormRow>
            <Label>
              <span className="label">{t("org:dashboard.clients.invite.form.subject_line")}*</span>
              <Input
                {...register("inviteSubject")}
                required
                aria-describedby="inviteSubjectMessage"
              />
            </Label>
            <FormError id="inviteSubjectMessage" message={errors.inviteSubject?.message} />
          </FormRow>
          <FormRow>
            <Label>
              <span className="label">
                {t("org:dashboard.clients.invite.form.message_heading")}*
              </span>
              <Input
                {...register("inviteHeading")}
                required
                aria-describedby="inviteHeadingMessage"
              />
            </Label>
            <FormError message={errors.inviteHeading?.message} id="inviteHeadingMessage" />
          </FormRow>
          <FormRow>
            <Label>
              <span className="label">{t("org:dashboard.clients.invite.form.message_body")}*</span>
              <TextArea
                {...register("inviteMessage")}
                required
                maxLength={3000}
                aria-describedby="inviteMessageMessage"
              />
            </Label>
            <FormError message={errors.inviteMessage?.message} id="inviteMessageMessage" />
          </FormRow>
          <FormRow>
            <Label>
              <span className="label">
                {t("org:dashboard.clients.invite.form.start_button_label")}*
              </span>
              <Input
                {...register("inviteButton")}
                required
                aria-describedby="inviteButtonMessage"
              />
            </Label>
            <FormError message={errors.inviteButton?.message} id="inviteButtonMessage" />
          </FormRow>
        </div>
      </details>
    </Form>
  );
};

export default InviteClientForm;
