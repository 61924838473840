import { config } from "@noted/configuration";
import { forwardRef, useCallback, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { Button } from "../../shared/components/forms";
import { Box } from "../../shared/components/primitives";
import { Form } from "../../shared/ui";
import { useAuthenticateMutation } from "../api";
import SignupForm from "./signup-form";

type SignupWorkflowProps = Omit<Parameters<typeof SignupForm>[0], "onSignupError" | "onLoginError">;
type AccountContext = Parameters<NonNullable<Parameters<typeof SignupForm>[0]["onCompleted"]>>[0];

const SignupWorkflow = forwardRef<HTMLFormElement, SignupWorkflowProps>((props, ref) => {
  const { onCompleted, maxTries, ...formProps } = props;
  const [signupError, setSignupError] = useState(false);
  const [loginError, setLoginError] = useState<[AccountContext, string]>();
  const [completed, setCompleted] = useState(false);

  const onWorkflowCompleted = useCallback(
    (ctx: AccountContext) => {
      setCompleted(true);
      onCompleted?.(ctx);
    },
    [onCompleted]
  );

  if (completed) {
    return null;
  }

  if (signupError) {
    return <SignupFail onRetry={() => setSignupError(false)} {...formProps} ref={ref} />;
  }
  if (loginError) {
    const [ctx, pwd] = loginError;
    return (
      <LoginFail
        {...formProps}
        ref={ref}
        context={ctx}
        password={pwd}
        onCompleted={() => onWorkflowCompleted(ctx)}
      />
    );
  }
  return (
    <SignupForm
      {...formProps}
      maxTries={maxTries}
      onCompleted={onWorkflowCompleted}
      onSignupError={() => setSignupError(true)}
      onLoginError={(ctx, pwd) => setLoginError([ctx, pwd])}
      ref={ref}
    />
  );
});
SignupWorkflow.displayName = "SignupWorkflow";

export default SignupWorkflow;

const SignupFail = forwardRef<
  HTMLFormElement,
  Parameters<typeof Form>[0] & { onRetry: () => void }
>((props, ref) => {
  const { onRetry, ...boxProps } = props;
  const { t } = useI18n(["account"]);
  return (
    <Form display="grid" gridGap="4" justifyItems="center" {...boxProps} ref={ref}>
      <Box
        mt="5"
        dangerouslySetInnerHTML={{
          __html: t("account:signup.signup_fail_component.message", {
            supportPage: config.supportPageUrl,
          }),
        }}
      />
      <Button onClick={() => onRetry()} width="max-content" type="submit" variant="primary">
        {t("account:signup.signup_fail_component.start_over")}
      </Button>
    </Form>
  );
});
SignupFail.displayName = "SignupFail";

const LoginFail = forwardRef<
  HTMLFormElement,
  Parameters<typeof Form>[0] & {
    onCompleted: () => void;
    context: AccountContext;
    password: string;
  }
>((props, ref) => {
  const { onCompleted, context, password, ...boxProps } = props;
  const { username } = context;
  const input = { username, password, eulaAgreed: false };
  const { t } = useI18n("account");

  const { isLoading, mutate: login } = useAuthenticateMutation({
    onSuccess: () => onCompleted(),
  });

  return (
    <Form display="grid" gridGap="4" justifyItems="center" {...boxProps} ref={ref}>
      <Box
        mt="5"
        dangerouslySetInnerHTML={{
          __html: t("account:signup.login_fail_component.message", {
            supportPage: config.supportPageUrl,
          }),
        }}
      />
      <Button
        onClick={() => login({ input })}
        width="max-content"
        variant="primary"
        disabled={isLoading}
        type="submit"
      >
        {t("account:signup.login_fail_component.retry")}
      </Button>
    </Form>
  );
});
LoginFail.displayName = "LoginFail";
