import { css } from "@emotion/core";
import { ReactNode } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { Button } from "~/shared/components/forms";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "~/shared/components/modal";
import { theme } from "~/shared/theme";

const styles = {
  self: css`
    width: 100%;
  `,
  footer: css`
    display: flex;
    gap: ${theme.space[3]};
    justify-content: flex-end;
    width: 100%;

    .cancel {
      order: -1;
    }
  `,
};

export type ModalState = "loading" | "form-invalid" | "form-valid" | "duplicates";

interface InviteClientModalProps {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  onSubmitInvited: () => void;
  onSubmitDuplicate: () => void;
  state: ModalState;
  children: {
    inviteClientForm: ReactNode;
    duplicateClientsForm: ReactNode;
  };
}

const InviteClientModal = ({
  className,
  closeModal,
  isOpen,
  state,
  children,
  onSubmitInvited,
  onSubmitDuplicate,
}: InviteClientModalProps) => {
  const { t } = useI18n("org");
  return (
    <div className={`invite-client-modal ${className}`} css={styles.self}>
      {isOpen && (
        <Modal
          handleExit={closeModal}
          style={{ maxWidth: "35rem", width: "calc(100% - 3rem)", minHeight: "20.25rem" }}
        >
          <ModalHeader>{t("org:dashboard.clients.invite.title")}</ModalHeader>
          <ModalBody>
            {state === "loading" && <LoadingLogo center />}
            {["form-invalid", "form-valid"].includes(state) && children.inviteClientForm}
            {state === "duplicates" && children.duplicateClientsForm}
          </ModalBody>
          {state !== "loading" && (
            <ModalFooter css={styles.footer}>
              {state === "duplicates" ? (
                <Button variant="primary" onClick={() => onSubmitDuplicate()}>
                  {t("org:dashboard.clients.invite.select")}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => onSubmitInvited()}
                  disabled={state !== "form-valid"}
                  type="submit"
                >
                  {t("org:dashboard.clients.invite.send")}
                </Button>
              )}
              <Button className="cancel" onClick={closeModal}>
                {t("org:dashboard.clients.invite.cancel")}
              </Button>
            </ModalFooter>
          )}
        </Modal>
      )}
    </div>
  );
};

export default InviteClientModal;
