import { css } from "@emotion/core";

import { DuplicateClient } from "~/dashboard/clients/client-invite-queries";
import { Client } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { getFullName } from "~/shared/functions/client-helpers";
import { theme } from "~/shared/theme";
import { Form, FormRow, InputRadio, Label, VisuallyHidden } from "~/shared/ui";

const styles = {
  self: css`
    width: 100%;

    .heading {
      font-size: 1.15em;
      font-weight: bold;
      margin-bottom: ${theme.space[2]};
    }

    .duplicate-client-input {
      background: ${theme.colors.neutral.light};
      border-radius: ${theme.borderRadius.small};
      border: ${theme.border()};
      padding: ${theme.space[1]} ${theme.space[2]} ${theme.space[2]};
      margin-top: ${theme.space[2]};
    }

    .duplicate-client__heading,
    .duplicate-client {
      display: grid;
      gap: ${theme.space[4]};
      gap: ${theme.space[4]};
      align-items: center;
      grid-template-columns: 1.5rem 1fr 1.25fr;
      margin-bottom: ${theme.space[1]};
      line-height: 1.5;

      .selected {
        display: flex;
        justify-content: flex-end;
      }
    }

    .new-client {
      display: grid;
      grid-template-columns: 1.5rem 1fr;
      gap: ${theme.space[4]};
      margin-top: ${theme.space[2]};
      line-height: 1.5;
    }
  `,
};

interface DuplicateClientsFormProps {
  duplicateClients: DuplicateClient[];
  selectedClient: DuplicateClient | undefined;
  selectExistingClient: (client: DuplicateClient | undefined) => void;
}

const DuplicateClientsForm = ({
  duplicateClients,
  selectedClient,
  selectExistingClient,
}: DuplicateClientsFormProps) => {
  const { t } = useI18n("org");

  return (
    <Form css={styles.self} id="duplicate-clients-form">
      <div>
        <h2 className="heading">{t("org:dashboard.clients.invite.duplicates.heading")}</h2>
        <p>{t("org:dashboard.clients.invite.duplicates.instruction")}</p>
      </div>
      <fieldset className="duplicate-client-input">
        <div role="presentation" className="duplicate-client__heading">
          <div className="selected" />
          <div className="name">{t("org:dashboard.clients.invite.duplicates.full_name")}</div>
          <div className="email">{t("org:dashboard.clients.invite.form.email")}</div>
        </div>
        {duplicateClients.map((client: DuplicateClient) => (
          <FormRow key={client.id}>
            <Label className="duplicate-client">
              <InputRadio
                name="existing-client"
                value={client.id}
                defaultChecked={selectedClient?.id === client.id}
                onChange={() => selectExistingClient(client)}
                aria-label={t("org:dashboard.clients.invite.duplicates.selected")}
              />
              <div className="name">
                <VisuallyHidden>
                  {t("org:dashboard.clients.invite.duplicates.full_name")}
                </VisuallyHidden>
                {getFullName(client as Client)}
                {client.currentStatus.active === false && (
                  <span className="inactive">{t("org:dashboard.clients.search.inactive")}</span>
                )}
              </div>
              <div className="email">
                <VisuallyHidden>{t("org:dashboard.clients.invite.form.email")}</VisuallyHidden>
                {client.email}
              </div>
            </Label>
          </FormRow>
        ))}
        <FormRow>
          <Label className="new-client">
            <InputRadio
              name="existing-client"
              value={undefined}
              defaultChecked={selectedClient === undefined}
              onChange={() => selectExistingClient(undefined)}
            />
            <span>{t("org:dashboard.clients.invite.duplicates.new_client")}</span>
          </Label>
        </FormRow>
      </fieldset>
    </Form>
  );
};

export default DuplicateClientsForm;
