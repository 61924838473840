import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  panel: css`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    border-radius: ${ncTheme.borderRadius.medium};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    background-color: ${ncTheme.colors.uiLight};
    overflow-y: auto;
  `,
  header: css`
    display: flex;
    flex-basis: ${ncTheme.spacing(6)};
    flex-shrink: 0;
    align-items: center;
    border-top-left-radius: ${ncTheme.borderRadius.medium};
    border-top-right-radius: ${ncTheme.borderRadius.medium};
    padding: ${ncTheme.spacing(3)};
    line-height: 1;
  `,
  body: css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    background-color: ${ncTheme.colors.light};
    padding: ${ncTheme.spacing(3)};
    :not(:only-child) {
      border-top: ${ncTheme.border(ncTheme.colors.ui)};
    }
  `,
  loading: css`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${ncTheme.colors.brand};
  `,
};

interface NcPanelProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const NcPanel = ({ children, ...props }: NcPanelProps) => {
  return (
    <div data-nc="NcPanel" css={css(styles.panel)} {...props}>
      {children}
    </div>
  );
};

const NcPanelHeader = ({ children, ...props }: { children: ReactNode }) => {
  return (
    <div data-nc="NcPanel.Header" css={styles.header} {...props}>
      {children}
    </div>
  );
};
NcPanel.Header = NcPanelHeader;

interface NcPanelBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  error?: true | string;
  isLoading?: boolean;
}

const NcPanelBody = ({ children, isLoading, error, ...props }: NcPanelBodyProps) => {
  const { t } = useI18n();
  return (
    <div data-nc="NcPanel.Body" css={styles.body} {...props}>
      {isLoading ? (
        <div css={styles.loading}>
          <NcLoadingIndicator />
        </div>
      ) : error ? (
        <NcFormattedMessage variant="danger">{error || t("error_default")}</NcFormattedMessage>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
NcPanel.Body = NcPanelBody;
