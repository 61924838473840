import { captureMessage } from "@sentry/react";
import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation, useNavigate } from "react-router-dom";

import { clearToken } from "~/global-token";

export const queryClient = new QueryClient();

interface WithStatus {
  status: number;
}

function hasStatus(obj: unknown): obj is WithStatus {
  return (obj as WithStatus)?.status !== undefined;
}

const NO_RETRY_RESPONSE_CODES = [401, 403, 409, 429, 451, 503];

export const NetworkLayer = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const retry =
    (maxTries = 0) =>
    (count: number, err: unknown = {}) => {
      if (hasStatus(err) && NO_RETRY_RESPONSE_CODES.includes(err.status)) {
        return false;
      }

      return count < Math.max(maxTries, 0);
    };

  const isNotAuthenticatedError = (err: unknown) => {
    const error = err as { status: Response["status"] };
    return error.status === 401;
  };

  const handleNotAuthenticatedError = (err: unknown) => {
    if (isNotAuthenticatedError(err)) {
      clearToken();

      const path = location.pathname;
      const navigationPath = path.startsWith("/login") ? path : `/login?redirectTo=${path}`;

      navigate(navigationPath);
    }
  };

  const isHTTPError = (err: unknown) => (err as { status?: Response["status"] }).status;

  const sentryCaptureErrorState = (error: unknown, query?: unknown) => {
    if (!isHTTPError(error)) {
      // intercept error and report to sentry
      captureMessage((error as Error).message || (error as { error?: string })?.error || "", {
        extra: {
          query,
          error,
        },
      });
    }
    return false; // false = return error as state
  };

  queryClient.setDefaultOptions({
    queries: {
      retry: retry(3),
      onError: handleNotAuthenticatedError,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error, query) => sentryCaptureErrorState(error, query),
    },
    mutations: {
      retry: retry(),
      onError: handleNotAuthenticatedError,
      useErrorBoundary: error => sentryCaptureErrorState(error),
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
};
