interface CommonConfig {
  supportPageUrl: string;
  termsPageUrl: string;
}

interface EnvConfig extends CommonConfig {
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    cookieDomain: string;
  };
  landingUrl: string;
  darkNotedUrl: string;
  appUrl: string;
  apiUrl: string;
  sentryEnvironment?: string;
  intercomId?: string;
  stripeKey: string;
}

const commonConfig: CommonConfig = {
  supportPageUrl: "https://support.noted.com/",
  termsPageUrl: "https://www.noted.com/standard-terms-of-use",
};

const developmentConfig: EnvConfig = {
  ...commonConfig,
  auth0: {
    domain: "noted-development.au.auth0.com",
    clientId: "Z1bX11oUrgrmCZWeyd4MaFJkhRJgOmZb",
    audience: "http://dev.api.noted.com",
    cookieDomain: "localhost",
  },
  landingUrl: "http://localhost:3000/landing",
  darkNotedUrl: "http://localhost:4000",
  appUrl: "http://localhost:3000",
  apiUrl: "http://localhost:8080",
  stripeKey: "pk_test_ephMi88gNOC0zjE1k4rJqTfT",
};

function makeUrl(prefix: string, protocol = window.location.protocol) {
  return (
    protocol +
    `//${prefix}` +
    window.location.hostname.replace(/^(app|admin)/, "")
  );
}

const stagingConfig: EnvConfig = {
  ...commonConfig,
  auth0: {
    domain: "noted-development.au.auth0.com",
    clientId: "Z1bX11oUrgrmCZWeyd4MaFJkhRJgOmZb",
    audience: "http://dev.api.noted.com",
    cookieDomain: window.location.hostname.substring(
      window.location.hostname.indexOf(".") + 1,
    ),
  },
  landingUrl: makeUrl("app") + "/landing",
  darkNotedUrl: makeUrl("admin"),
  appUrl: makeUrl("app"),
  apiUrl: makeUrl("api"),
  sentryEnvironment: "staging",
  intercomId: "i17ikl0l",
  stripeKey: "pk_test_ephMi88gNOC0zjE1k4rJqTfT",
};

const productionConfig: EnvConfig = {
  ...commonConfig,
  auth0: {
    domain: "notedauth.au.auth0.com",
    clientId: "Qv5NlqK59To1CrU7uMxkNBzcRpL8zS9G",
    audience: "https://api.noted.com",
    cookieDomain: window.location.hostname.substring(
      window.location.hostname.indexOf(".") + 1,
    ),
  },
  landingUrl: makeUrl("app") + "/landing",
  darkNotedUrl: makeUrl("admin"),
  appUrl: makeUrl("app"),
  apiUrl: makeUrl("api"),
  sentryEnvironment: "prod",
  intercomId: "vvy9vlc8",
  stripeKey: "pk_live_xQcYt91I9o80B2h8HTwT1EOU",
};

export const config = (window.location.hostname || "").includes("localhost")
  ? developmentConfig
  : (window.location.hostname || "").includes("staging")
  ? stagingConfig
  : productionConfig;
