import { config as configPackage } from "@noted/configuration";
import { uniq } from "lodash-es";
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { LoginResultType } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";

import { config } from "../../administration/users/config";
import { setToken } from "../../global-token";
import { Captcha } from "../../shared/captcha/captcha";
import { Button, Checkbox } from "../../shared/components/forms";
import { LoadingText } from "../../shared/components/loading/loading-text";
import { Text } from "../../shared/components/primitives";
import { theme } from "../../shared/theme";
import { Feedback, Form, FormRow, Input, Label } from "../../shared/ui";
import { useAuthenticateMutation } from "../api";
import { useSignupMutation } from "../use-signup-mutation";
import { CountriesSelect } from "./countries-select";
import { ModalitiesSelect } from "./modalitites-select";
import { useEmailState } from "./use-email-state";
import { usePasswordState } from "./use-password-state";
import { useUsernameState } from "./use-username-state";

type AccountContext = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  countryCode: string;
  legalName: string;
};

const useStateRef = () => {
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const legalName = useRef<HTMLInputElement>(null);
  const phone = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const countryCode = useRef<HTMLSelectElement>(null);
  const modalityId = useRef<HTMLSelectElement>(null);
  const username = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);
  const latestEulaAgreed = useRef<HTMLInputElement>(null);

  const stateRef = useMemo(
    () => ({
      firstName,
      lastName,
      legalName,
      phone,
      email,
      countryCode,
      modalityId,
      username,
      password,
      confirmPassword,
      latestEulaAgreed,
    }),
    []
  );

  const findStateEl = useCallback(
    (target: HTMLElement): HTMLInputElement | HTMLSelectElement | undefined | null => {
      return Object.values(stateRef)
        .map(v => v.current)
        .find(myRef => myRef === target);
    },
    [stateRef]
  );

  return useMemo(() => ({ stateRef, findStateEl }), [findStateEl, stateRef]);
};

type FormProps = Omit<
  Parameters<typeof Form>[0],
  "children" | "onBlur" | "onSubmit" | "onError"
> & {
  onCompleted?: (props: AccountContext) => void;
  onSignupError?: () => void;
  onLoginError?: (context: AccountContext, password: string) => void;
  maxTries?: 1 | 2 | 3;
};

export const SignupForm = forwardRef<HTMLFormElement, FormProps>((allProps, ref) => {
  const { onCompleted, onSignupError, onLoginError, maxTries = 1, ...props } = allProps;
  const { t } = useI18n(["account"]);
  const { stateRef, findStateEl } = useStateRef();
  const [captcha, setCaptcha] = useState<string>();
  const [required, setRequired] = useState<string[]>([]);
  const {
    username,
    setUsername,
    errors: usernameErrors,
    validating: validatingUsername,
  } = useUsernameState();
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errors: passwordErrors,
  } = usePasswordState();
  const { email, setEmail, errors: emailErrors } = useEmailState();
  const [duplicateEmails, setDuplicateEmails] = useState<string[]>([]);
  const [emailServerError, setEmailServerError] = useState(false);
  const [tries, setTries] = useState(0);

  const submitRef = useRef<HTMLButtonElement>(null);

  const isRequired = useCallback(
    (e: FocusEvent<HTMLFormElement>) => {
      if (findStateEl(e.target) && e.target.required) {
        const field = e.target.id;
        (e.target.type === "checkbox" ? !e.target.checked : !e.target.value)
          ? setRequired(r => uniq([...r, field]))
          : setRequired(r => r.filter(required => required !== field));
      }
    },
    [findStateEl]
  );

  const { mutate: login, isLoading: isLogingIn } = useAuthenticateMutation();

  const { mutate, isLoading: signingUp } = useSignupMutation({
    onError: (e, _, { attempts = 1 } = {}) => {
      if (e.message === "Clinic already exists with this email address") {
        setDuplicateEmails(t => uniq([...t, email]));
        return stateRef.email.current?.focus();
      }
      if (e.message === "customer[email] : wrong value") {
        setEmailServerError(true);
        return stateRef.email.current?.focus();
      }
      setTries(attempts);
      if (attempts >= maxTries) {
        onSignupError?.();
      }
    },
    onMutate: () => {
      setEmailServerError(false);
      return { attempts: tries + 1 };
    },
    onSuccess: (_, { input: { user, clinic } }) => {
      const context = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        username: user.username,
        countryCode: clinic.countryCode,
        legalName: clinic.legalName,
      };
      login(
        { input: { username: user.username, password: user.password, eulaAgreed: false } },
        {
          onSuccess: ({ authenticate } = {}) => {
            const { result, token } = authenticate ?? {};
            if (token && result === LoginResultType.Success) {
              setToken(token);
              onCompleted?.(context);
            } else {
              onLoginError?.(context, user.password);
            }
          },
          onError: () => onLoginError?.(context, user.password),
        }
      );
    },
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Run all isRequired checks
    Object.values(stateRef).forEach(ref =>
      isRequired({
        target: ref?.current,
      } as unknown as FocusEvent<HTMLFormElement>)
    );

    if (!captcha || validatingUsername) {
      return;
    }
    const firstName = stateRef.firstName.current?.value;
    if (!firstName) {
      return stateRef.firstName.current?.focus();
    }
    const lastName = stateRef.lastName.current?.value;
    if (!lastName) {
      return stateRef.lastName.current?.focus();
    }
    const legalName = stateRef.legalName.current?.value;
    if (!legalName) {
      return stateRef.legalName.current?.focus();
    }
    const phone = stateRef.phone.current?.value;
    if (!phone) {
      return stateRef.phone.current?.focus();
    }
    if (!email || emailErrors) {
      return stateRef.email.current?.focus();
    }
    const countryCode = stateRef.countryCode.current?.value;
    if (!countryCode) {
      return stateRef.countryCode.current?.focus();
    }
    const modalityId = stateRef.modalityId.current?.value;
    if (!modalityId) {
      return stateRef.modalityId.current?.focus();
    }
    if (!username || usernameErrors) {
      return stateRef.username.current?.focus();
    }
    if (!password || passwordErrors?.tooLong || passwordErrors?.tooShort) {
      return stateRef.password.current?.focus();
    }
    if (!confirmPassword || passwordErrors?.mismatch) {
      return stateRef.confirmPassword.current?.focus();
    }
    const latestEulaAgreed = stateRef.latestEulaAgreed.current?.checked;
    if (!latestEulaAgreed) {
      return stateRef.latestEulaAgreed.current?.focus();
    }
    const user = {
      firstName,
      lastName,
      email,
      phone,
      username,
      password,
      latestEulaAgreed,
      modalityId,
    };
    const clinic = {
      email,
      phone,
      countryCode,
      legalName,
    };
    mutate({ input: { user, clinic, captcha } });
    submitRef.current?.focus?.();
  };

  return (
    <Form onSubmit={onSubmit} onBlur={isRequired} {...props} ref={ref}>
      <FormRow py="6">
        <FormRow maxWidth="30rem" mx="auto" px="5" gridGap="4">
          <Text fontSize="3" textAlign="center" mb="6">
            {t("account:signup.details_description")}
          </Text>
          <FormRow variant="horizontal" width="100%" alignItems="start">
            <FormRow>
              <Label htmlFor="firstName">{t("account:signup.firstName")}</Label>
              <Input id="firstName" required={true} ref={stateRef.firstName} />
              {required.includes("firstName") && (
                <Feedback>{t("account:signup.first_name_required")}</Feedback>
              )}
            </FormRow>
            <FormRow>
              <Label htmlFor="lastName">{t("account:signup.lastName")}</Label>
              <Input id="lastName" required={true} ref={stateRef.lastName} />
              {required.includes("lastName") && (
                <Feedback>{t("account:signup.last_name_required")}</Feedback>
              )}
            </FormRow>
          </FormRow>
          <FormRow>
            <Label htmlFor="legalName">{t("account:signup.legalName")}</Label>
            <Input id="legalName" required={true} ref={stateRef.legalName} />
            {required.includes("legalName") && (
              <Feedback>{t("account:signup.legal_name_required")}</Feedback>
            )}
          </FormRow>
          <FormRow>
            <Label htmlFor="phone">{t("account:signup.phone")}</Label>
            <Input id="phone" required={true} ref={stateRef.phone} />
            {required.includes("phone") && (
              <Feedback>{t("account:signup.phone_required")}</Feedback>
            )}
          </FormRow>
          <FormRow>
            <Label htmlFor="email">{t("account:signup.email")}</Label>
            <Input
              id="email"
              type="email"
              required={true}
              pattern={config.email.pattern.toString()}
              ref={stateRef.email}
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
            {required.includes("email") ? (
              <Feedback>{t("account:signup.email_required")}</Feedback>
            ) : emailErrors?.badPattern ? (
              <Feedback>{t("form_validation.email_pattern")}</Feedback>
            ) : null}
            {duplicateEmails.includes(email) && (
              <Feedback>{t("account:signup.email_exists")}</Feedback>
            )}
            {emailServerError && <Feedback>{t("account:signup.email_wrong_value")}</Feedback>}
          </FormRow>
          <FormRow>
            <Label htmlFor="countryCode">{t("account:signup.countrycode")}</Label>
            <CountriesSelect
              id="countryCode"
              required={true}
              ref={stateRef.countryCode}
              defaultValue=""
            />
            {required.includes("countryCode") && (
              <Feedback>{t("account:signup.countrycode_required")}</Feedback>
            )}
          </FormRow>
        </FormRow>
      </FormRow>
      <FormRow py="6" bg={theme.colors.neutral.light}>
        <FormRow maxWidth="40rem" mx="auto" px="5">
          <Text textAlign="center" fontSize="3" mb="6">
            {t("account:signup.formset_description")}
          </Text>
        </FormRow>
        <FormRow maxWidth="30rem" mx="auto" px="5">
          <Label htmlFor="modalityId">{t("account:signup.formset_select")}</Label>
          <ModalitiesSelect
            id="modalityId"
            required={true}
            ref={stateRef.modalityId}
            defaultValue=""
          />
          {required.includes("modalityId") && (
            <Feedback>{t("account:signup.formset_required")}</Feedback>
          )}
          <Text fontSize="2" mt="1">
            {t("account:signup.formset_select_note")}
          </Text>
        </FormRow>
      </FormRow>
      <FormRow py="6">
        <FormRow maxWidth="32rem" mx="auto" px="5">
          <Text fontSize="3" textAlign="center" mb="6">
            {t("account:signup.user_details")}
          </Text>
        </FormRow>
        <FormRow maxWidth="27rem" mx="auto" px="5" gridGap="4">
          <FormRow>
            <Label htmlFor="username">{t("account:signup.username")}</Label>
            <Input
              id="username"
              autoComplete="new-password"
              required={true}
              ref={stateRef.username}
              value={username}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
            />
            {required.includes("username") ? (
              <Feedback>{t("account:signup.username_required")}</Feedback>
            ) : usernameErrors?.notUnique ? (
              <Feedback>{t("account:signup.username_unique")}</Feedback>
            ) : usernameErrors?.tooLong ? (
              <Feedback>{t("account:signup.username_max_length")}</Feedback>
            ) : usernameErrors?.tooShort ? (
              <Feedback>{t("account:signup.username_min_length")}</Feedback>
            ) : usernameErrors?.badPattern ? (
              <Feedback>{t("account:signup.username_pattern")}</Feedback>
            ) : null}
          </FormRow>
          <FormRow>
            <Label htmlFor="password">{t("account:signup.password")}</Label>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              required={true}
              ref={stateRef.password}
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            {required.includes("password") ? (
              <Feedback>{t("account:password.required")}</Feedback>
            ) : passwordErrors?.tooShort ? (
              <Feedback>{t("account:password.min_length", config.password)}</Feedback>
            ) : passwordErrors?.tooLong ? (
              <Feedback>{t("account:password.max_length", config.password)}</Feedback>
            ) : null}
          </FormRow>
          <FormRow>
            <Label htmlFor="confirmPassword">{t("account:signup.confirm_password")}</Label>
            <Input
              id="confirmPassword"
              type="password"
              autoComplete="new-password"
              required={true}
              ref={stateRef.confirmPassword}
              value={confirmPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            />
            {required.includes("confirmPassword") ? (
              <Feedback>{t("account:password.required")}</Feedback>
            ) : passwordErrors?.mismatch ? (
              <Feedback>{t("account:password.mismatch")}</Feedback>
            ) : null}
          </FormRow>
          <FormRow>
            <FormRow variant="horizontal">
              <Checkbox
                id="latestEulaAgreed"
                name="latestEulaAgreed"
                required={true}
                ref={stateRef.latestEulaAgreed}
              />
              <Label
                htmlFor="latestEulaAgreed"
                dangerouslySetInnerHTML={{
                  __html: t("account:signup.eula_agreed", {
                    termsPageUrl: configPackage.termsPageUrl,
                  }),
                }}
              />
            </FormRow>
            {required.includes("latestEulaAgreed") && (
              <Feedback>{t("account:signup.eula_required")}</Feedback>
            )}
          </FormRow>
          <FormRow justifyContent="center" mt="2">
            <Captcha
              data-testid={"captcha"}
              onVerify={token => setCaptcha(token)}
              onError={() => setCaptcha(undefined)}
              onExpire={() => setCaptcha(undefined)}
            />
          </FormRow>
          <FormRow justifyItems="center">
            {signingUp || isLogingIn ? (
              <LoadingText text={t("loading")} />
            ) : (
              <>
                <Button
                  data-cy="signUp"
                  fontSize="3"
                  px="5"
                  py="3"
                  mt="4"
                  variant="primary"
                  type="submit"
                  ref={submitRef}
                  disabled={!captcha || validatingUsername}
                  width="max-content"
                >
                  {tries === 0 ? t("account:signup.signup_button") : t("account:signup.retry")}
                </Button>
                {tries > 0 && <Feedback>{t("account:signup.signup_fail")}</Feedback>}
              </>
            )}
          </FormRow>
        </FormRow>
      </FormRow>
    </Form>
  );
});
SignupForm.displayName = "SignupForm";

export default SignupForm;
