import { css } from "@emotion/core";
import { ReactElement, useEffect, useRef, useState } from "react";

import { useAuth } from "~/auth-provider";

const styles = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
`;

const UserInteractionDetection = ({ children }: { children: ReactElement }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { getToken, hasPassedExpiryThreshold } = useAuth();
  const [sessionCheckTimeout, setSessionCheckTimeout] = useState<number | null>(null);

  useEffect(() => {
    const checkSession = async () => {
      setSessionCheckTimeout(null);
      const isExpiring = await hasPassedExpiryThreshold(50);
      if (isExpiring) {
        getToken({ cacheMode: "off" });
      }
    };

    const onUserInteracted = () => {
      if (!sessionCheckTimeout) {
        setSessionCheckTimeout(() => window.setTimeout(checkSession, 60000));
      }
    };

    document.addEventListener("click", onUserInteracted);
    document.addEventListener("input", onUserInteracted);

    return () => {
      document.removeEventListener("click", onUserInteracted);
      document.removeEventListener("input", onUserInteracted);
      if (sessionCheckTimeout) {
        window.clearTimeout(sessionCheckTimeout);
      }
    };
  }, [sessionCheckTimeout]);
  return (
    <div ref={elementRef} css={styles}>
      {children}
    </div>
  );
};

export default UserInteractionDetection;
