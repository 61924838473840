import { useRef } from "react";

import { useMeQuery, useUserSettingsSetter } from "~/account/use-me-query";
import { AccountSettingsJson } from "~/shared/types";

const getAccountSettingsValue = (
  settingsJson: AccountSettingsJson,
  userPersonalisationSettings: { [key: string]: unknown }
) => ({
  ...settingsJson,
  personalisation: {
    ...userPersonalisationSettings,
  },
});

export const useUserPersonalisationSettings = () => {
  const { data } = useMeQuery();
  const { mutate, isLoading } = useUserSettingsSetter();
  const queuedMutations = useRef<AccountSettingsJson>();

  const settingsJson = data?.user?.settingsJson || {};
  const userPersonalisationSettings = settingsJson?.personalisation || {};

  const runMutate = (updatedValue: AccountSettingsJson) => {
    mutate(updatedValue, {
      onSettled: () => {
        if (queuedMutations.current) {
          runMutate(
            getAccountSettingsValue(settingsJson, {
              ...userPersonalisationSettings,
              ...queuedMutations.current,
            })
          );
          queuedMutations.current = undefined;
        }
      },
    });
  };

  const setUserPersonalisationSettings = (key: string, value: unknown) => {
    // running multiple mutations in parallel causes 500 errors from the server, so we will queue them
    if (isLoading) {
      queuedMutations.current = {
        ...userPersonalisationSettings,
        ...queuedMutations.current,
        [key]: value,
      };
      return;
    }
    runMutate(
      getAccountSettingsValue(settingsJson, {
        ...userPersonalisationSettings,
        [key]: value,
      })
    );
  };

  return { userPersonalisationSettings, setUserPersonalisationSettings };
};
