import { css } from "@emotion/core";
import { DateTime } from "luxon";
import { HTMLProps } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";
import { getFullName } from "~/shared/functions/client-helpers";
import { getKeyboardHandler } from "~/shared/functions/eventHelpers";
import { theme } from "~/shared/theme";
import { NavigableListItem } from "~/shared/ui/a11y/navigable-list";

import { ClientSearchResult } from "../dashboard-queries";

const styles = {
  message: css`
    padding: ${theme.space[4]};
  `,
  result: css`
    width: 100%;

    & + .search-result {
      border-top: ${theme.border()};
    }

    .search-result {
      &__detail {
        align-items: baseline;
        display: flex;
        grid-column-start: 2;
        justify-content: end;
        max-width: 100%;
        line-height: 1;

        &.name,
        &.contact,
        &.address {
          flex-wrap: wrap;
          grid-column-start: 1;
          justify-content: start;
        }

        &.name {
          font-weight: bold;
          transition: color 300ms ease;

          strong {
            transition: text-decoration 300ms ease;
            margin-right: ${theme.space[2]};
          }
        }

        &.contact {
          span:not(:empty) {
            margin-right: ${theme.space[2]};
          }
        }

        &.address {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .label {
          display: inline-block;
          font-style: italic;
          font-size: ${theme.fontSizes[0]};
          padding-right: calc(${theme.space[1]} * 1.5);
        }
      }

      &__link {
        display: grid;
        grid-template-columns: 4fr 1fr;
        gap: 0.35rem ${theme.space[3]};
        justify-content: space-between;
        color: ${theme.colors.neutral.dark};
        padding: ${theme.space[3]} ${theme.space[4]};

        &:hover,
        &:focus,
        &:active {
          outline: none;
          background: ${theme.colors.primary.lightest};

          .name {
            color: ${theme.colors.primary.darkest};

            strong {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .inactive-badge {
      color: ${theme.colors.neutral.mediumDark};
      font-style: italic;
      font-weight: normal;
    }

    .records-link {
      cursor: pointer;
      color: ${theme.colors.primary.dark};
      line-height: 1;
      text-decoration: underline ${theme.colors.primary.medium};

      &:hover,
      &:focus,
      &:active {
        outline: none;
        color: ${theme.colors.neutral.darkest};
        text-decoration: underline;
      }
    }
  `,
};

const Message = ({ children }: HTMLProps<HTMLLIElement>) => (
  <li css={styles.message}>{children}</li>
);

const SearchResult = ({ searchResult }: { searchResult: ClientSearchResult }) => {
  const { t } = useI18n("org");
  const navigate = useNavigate();
  const clientLink = `/n/clients/${searchResult.id}`;

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const keyboardNavigateTo = (path: string) =>
    getKeyboardHandler(["Enter"], () => navigateTo(path));

  return (
    <NavigableListItem role="presentation" className="search-result" css={styles.result}>
      <Link role="option" className="search-result__link" to={clientLink}>
        <div className="search-result__detail name">
          <strong>{getFullName(searchResult)}</strong>
          {!searchResult.currentStatus.active && (
            <span className="inactive-badge">({t("org:dashboard.clients.search.inactive")})</span>
          )}
        </div>
        <div className="search-result__detail">
          <span className="label">{t("org:dashboard.clients.search.nhi")}</span>
          {searchResult.medicalServiceId ? searchResult.medicalServiceId : "-"}
        </div>
        {(!searchResult.phone || searchResult.email) && (
          <div className="search-result__detail contact">
            <span>{searchResult.phone}</span>
            <span>{searchResult.email}</span>
          </div>
        )}
        <div className="search-result__detail">
          <span className="label">{t("org:dashboard.clients.search.dob")}</span>
          {searchResult.dateOfBirth
            ? `${searchResult.dateOfBirth.toLocaleString(DateTime.DATE_SHORT)}`
            : "-"}
        </div>
        {searchResult.address && (
          <div className="search-result__detail address" title={searchResult.address}>
            {searchResult.address}
          </div>
        )}
        <div className="search-result__detail">
          <span
            className="records-link"
            role="link"
            tabIndex={0}
            onClick={e => {
              e.preventDefault();
              navigateTo(`${clientLink}/records`);
            }}
            onKeyUp={keyboardNavigateTo(`${clientLink}/records`)}
          >
            {t("org:dashboard.clients.search.records")}
          </span>
        </div>
      </Link>
    </NavigableListItem>
  );
};

interface SearchResultsProps {
  loading: boolean;
  error: boolean;
  searchResults?: ClientSearchResult[];
}

const SearchResults = ({ error, loading, searchResults }: SearchResultsProps) => {
  const { t } = useI18n("org");

  if (loading) {
    return (
      <Message>
        <LoadingLogo center />
      </Message>
    );
  }

  if (error) {
    return <Message>{t("org:dashboard.clients.search.error")}</Message>;
  }
  if (searchResults && searchResults.length > 0) {
    return (
      <>
        {searchResults.map(result => (
          <SearchResult key={result.id} searchResult={result} />
        ))}
      </>
    );
  }
  return <Message>{t("org:dashboard.clients.search.no_results")}</Message>;
};

export default SearchResults;
