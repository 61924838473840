import { css } from "@emotion/core";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";
import { useUserPersonalisationSettings } from "~/hooks/use-user-personalisation-settings";
import { Checkbox } from "~/shared/components/forms";
import { BREAKPOINT_MID_QUERY, BREAKPOINT_XXLARGE_QUERY, theme } from "~/shared/theme";
import { Label } from "~/shared/ui";

import { useClientSearchQuery } from "../dashboard-queries";
import SearchBox from "./search-box";
import SearchPopoverLayout from "./search-popover-layout";
import SearchResults from "./search-results";

const DEFAULT_OPTIONS = {
  size: 6,
  activeonly: false,
  searchAddresses: false,
  sortattr: "first_name,last_name",
  sortdir: "ASC",
};

const KEY_INCLUDE_INACTIVE = "dashboard_search_include_inactive";
const KEY_SEARCH_ADDRESSES = "dashboard_search_search_addresses";

const styles = css`
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${BREAKPOINT_XXLARGE_QUERY} {
    display: flex;
    align-items: center;
    gap: ${theme.space[4]};
  }

  .client-search {
    &__title {
      font-weight: bold;
      margin-bottom: ${theme.space[1]};

      @media ${BREAKPOINT_XXLARGE_QUERY} {
        margin-bottom: 0;
      }
    }

    &__search {
      flex-grow: 1;
      @media ${BREAKPOINT_XXLARGE_QUERY} {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: ${theme.space[5]};
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;
      padding-top: ${theme.space[2]};
      gap: ${theme.space[2]} ${theme.space[4]};
      z-index: 0;

      @media ${BREAKPOINT_MID_QUERY} {
        font-size: 0.85em;
      }
    }

    &__option {
      display: inline-flex;
      align-items: center;
      gap: ${theme.space[2]};
      width: auto;
      font-weight: normal;
      line-height: 1.2;
    }

    &__results {
      width: 100%;
      z-index: 1;
    }
  }
`;

const ClientSearch = () => {
  const { userPersonalisationSettings, setUserPersonalisationSettings } =
    useUserPersonalisationSettings();
  const { t } = useI18n("org");
  const [searchTerm, setSearchTerm] = useState("");
  const [includeInactive, setIncludeInactive] = useState<boolean>(
    userPersonalisationSettings[KEY_INCLUDE_INACTIVE] === true
  );
  const [searchAddresses, setSearchAddresses] = useState<boolean>(
    userPersonalisationSettings[KEY_SEARCH_ADDRESSES] === true
  );
  const resultsRef = useRef<HTMLUListElement>(null);

  const updateIncludeInactive = (value: boolean) => {
    setIncludeInactive(value);
    setUserPersonalisationSettings(KEY_INCLUDE_INACTIVE, value);
  };

  const updateSearchAddresses = (value: boolean) => {
    setSearchAddresses(value);
    setUserPersonalisationSettings(KEY_SEARCH_ADDRESSES, value);
  };

  const {
    data: searchResults,
    isLoading,
    error,
  } = useClientSearchQuery({
    searchTerm: searchTerm,
    page: 0,
    options: {
      ...DEFAULT_OPTIONS,
      activeonly: !includeInactive,
      searchAddresses: searchAddresses,
    },
  });

  const popoverOpen = isLoading || !!error || !!searchResults;

  const handleKeyboardNavOut = (event: KeyboardEvent) => {
    if (popoverOpen && resultsRef.current) {
      event.stopPropagation();
      event.preventDefault();
      const firstItem = resultsRef?.current?.firstChild as HTMLElement;
      (firstItem?.firstChild as HTMLElement).focus();
    }
  };

  return (
    <div className="client-search" css={styles}>
      <h2 className="client-search__title">{t("org:dashboard.clients.label")}</h2>
      <SearchPopoverLayout
        className="client-search__search"
        popoverOpen={popoverOpen}
        closeAction={() => setSearchTerm("")}
      >
        {{
          search: (
            <SearchBox
              id="clientSearch"
              labelKey="org:dashboard.clients.search.label"
              onChange={setSearchTerm}
              onKeyboardNavOut={handleKeyboardNavOut}
              aria-haspopup
              aria-expaned={popoverOpen}
              aria-controls="dashboard-search-results"
              defaultValue={searchTerm}
            />
          ),
          controls: (
            <div className="client-search__controls">
              <Label className="client-search__option">
                <Checkbox
                  id="includeInactive"
                  type="checkbox"
                  name="includeInactive"
                  small
                  defaultChecked={includeInactive}
                  onChange={() => {
                    updateIncludeInactive(!includeInactive);
                  }}
                />
                {t("org:dashboard.clients.search.include_inactive")}
              </Label>
              <Label className="client-search__option">
                <Checkbox
                  id="includeAddresses"
                  type="checkbox"
                  name="includeAddresses"
                  small
                  defaultChecked={searchAddresses}
                  onChange={() => {
                    updateSearchAddresses(!searchAddresses);
                  }}
                />
                {t("org:dashboard.clients.search.include_addresses")}
              </Label>

              <Link to="/n/clients">{t("org:dashboard.clients.search.advanced")}</Link>
            </div>
          ),
          results: (
            <ul
              role="listbox"
              aria-label={t("org:dashboard.clients.search.label")}
              className="client-search__results"
              id="dashboard-search-results"
              data-testid="dashboard-search-results"
              ref={resultsRef}
            >
              <SearchResults
                error={!!error}
                loading={isLoading}
                searchResults={searchResults?.results}
              />
            </ul>
          ),
        }}
      </SearchPopoverLayout>
    </div>
  );
};

export default ClientSearch;
