import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

const styles = {
  base: css``,
  variant: {
    legal: css``,
    full: css``,
    preferred: css``,
    short: css``,
  },
  bracketedName: css`
    font-style: italic;
  `,
  pronouns: css`
    opacity: 0.75;
  `,
};

type FormattedNameVariant = keyof typeof styles.variant;

interface obj {
  [key: string]: string | undefined | null;
}
interface Name extends obj {
  title?: string | undefined | null;
  firstName?: string | undefined | null;
  lastName?: string | undefined | null;
  middleName?: string | undefined | null;
  preferredName?: string | undefined | null;
  preferredPronouns?: string | undefined | null;
}

interface FormattedNameProps extends HTMLAttributes<HTMLDivElement> {
  variant?: FormattedNameVariant;
  name: Name;
}

const VARIANTS = {
  title: ["legal", "full"],
  firstName: ["legal"],
  firstNameBracketed: ["full", "preferred"],
  preferredName: ["full", "preferred", "short"],
  middleName: ["legal", "full"],
  lastName: ["legal", "full", "preferred", "short"],
  preferredPronouns: ["full", "preferred", "short"],
} as { [key: string]: Array<FormattedNameVariant> };

type namePart = {
  key: string;
  string: string;
  element: ReactNode;
};

const getNameParts = (name: Name, variant: FormattedNameVariant): Array<namePart> =>
  [
    name.title &&
      VARIANTS.title.includes(variant) && {
        key: "title",
        string: name.title,
        element: <>{name.title}</>,
      },
    name.firstName &&
      VARIANTS.firstName.includes(variant) && {
        key: "firstName",
        string: name.firstName,
        element: <>{name.firstName}</>,
      },
    name.preferredName &&
      VARIANTS.preferredName.includes(variant) && {
        key: "preferredName",
        string: name.preferredName,
        element: <>{name.preferredName}</>,
      },
    name.firstName &&
      VARIANTS.firstNameBracketed.includes(variant) && {
        key: "firstNameBracketed",
        string: name.preferredName ? `(${name.firstName})` : name.firstName,
        element: name.preferredName ? (
          <span css={styles.bracketedName}>{`(${name.firstName})`}</span>
        ) : (
          <>{name.firstName}</>
        ),
      },
    name.middleName &&
      VARIANTS.middleName.includes(variant) && {
        key: "middleName",
        string: name.middleName,
        element: <>{name.middleName}</>,
      },
    name.lastName &&
      VARIANTS.lastName.includes(variant) && {
        key: "lastName",
        string: name.lastName,
        element: <>{name.lastName}</>,
      },
    name.preferredPronouns &&
      VARIANTS.preferredPronouns.includes(variant) && {
        key: "preferredPronouns",
        string: `(${name.preferredPronouns})`,
        element: <span css={styles.pronouns}>{`(${name.preferredPronouns})`}</span>,
      },
  ].filter(Boolean) as Array<namePart>;

export const getName = (name: Name, variant: FormattedNameVariant): string =>
  getNameParts(name, variant)
    .map(({ string }) => string)
    .filter(Boolean)
    .join(" ");

export const NcFormattedName = ({ name, variant = "preferred", ...props }: FormattedNameProps) => {
  const parts = getNameParts(name, variant);

  return (
    <span data-nc="NcFormattedName" css={[styles.base, styles.variant]} {...props}>
      {parts.map(({ key, element }: { key: string; element: ReactNode }) => (
        <span key={key}>{element}&nbsp;</span>
      ))}
    </span>
  );
};
