import { css } from "@emotion/core";
import type { HTMLAttributes, LegacyRef, ReactNode } from "react";
import { forwardRef } from "react";
import { useObjectRef } from "react-aria";

import { ncTheme } from "../nc-theme";

const styles = {
  common: css`
    font-weight: ${ncTheme.fontWeight.heavy};
    line-height: 1.2;
  `,
  level: {
    1: css`
      font-size: ${ncTheme.fontSizes[8]};
    `,
    2: css`
      font-size: ${ncTheme.fontSizes[6]};
    `,
    3: css`
      font-size: ${ncTheme.fontSizes[5]};
    `,
    4: css`
      font-size: ${ncTheme.fontSizes[4]};
    `,
    5: css`
      font-size: ${ncTheme.fontSizes[3]};
    `,
    6: css`
      text-transform: uppercase;
      font-size: ${ncTheme.fontSizes[2]};
    `,
  },
};

interface NcHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  styleAs?: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
}

export const NcHeading = forwardRef<HTMLAttributes<HTMLHeadingElement>, NcHeadingProps>(
  ({ level, styleAs, children, ...props }, ref) => {
    const objRef = useObjectRef(ref);
    const HeadingTag = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

    return (
      <HeadingTag
        data-nc="NcHeading"
        css={[styles.common, styles.level[level], styleAs && styles.level[styleAs]]}
        ref={objRef as LegacyRef<HTMLHeadingElement> | undefined}
        {...props}
      >
        {children}
      </HeadingTag>
    );
  }
);
