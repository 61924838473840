import { css } from "@emotion/core";
import type { HelpTextProps } from "@react-types/shared/src/inputs.d";
import type { ReactNode } from "react";
import type { FieldErrorProps, LabelProps } from "react-aria-components";
import { FieldError, Text } from "react-aria-components";

import { ncTheme } from "../../nc-theme";
import { NcFormattedMessage } from "../nc-formatted-message";
import { NcLabel } from "../nc-label";

type FieldVariants = "horizontal" | "vertical";

const styles = {
  base: css`
    gap: ${ncTheme.spacing(2)};
    line-height: 1.25;
    justify-items: start;
  `,
  variant: {
    vertical: css`
      display: grid;
    `,
    horizontal: css`
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: baseline;
      column-gap: ${ncTheme.spacing(3)};
    `,
  },
  wrap: css`
    display: grid;
    gap: ${ncTheme.spacing(1)};
    align-items: start;
    width: 100%;
  `,
  message: css`
    font-size: ${ncTheme.fontSizes[1]};
    line-height: 1.25;
  `,
  error: css`
    color: ${ncTheme.colors.dangerActive};
  `,
  multi: css`
    ul {
      margin-block: 0;
    }
  `,
};

export const errorMessageStyles = [styles.error, styles.message];

export interface FieldLabelProps {
  label: string;
  labelNode?: ReactNode;
}
export interface FieldProps extends HelpTextProps, FieldLabelProps {
  name: string;
  variant?: FieldVariants;
}

type NcFieldProps = {
  label?: ReactNode | string;
  description?: ReactNode | string;
  children: ReactNode | ReactNode[];
  variant?: FieldVariants;
};

const FieldDescription = ({ children }: { children: ReactNode }) => {
  return children ? (
    <Text data-nc="NcField.Description" slot="description" css={styles.message}>
      {children}
    </Text>
  ) : (
    <></>
  );
};

const FieldErrorMessage = ({ ...props }: FieldErrorProps) => (
  <FieldError data-nc="NcField.ErrorMessage" {...props}>
    {({ validationErrors }) => {
      const firstError = validationErrors[0];
      if (!firstError) {
        return <></>;
      }
      return validationErrors.length === 1 ? (
        <NcFormattedMessage variant="danger" css={[styles.message]}>
          {firstError}
        </NcFormattedMessage>
      ) : (
        <NcFormattedMessage variant="danger" css={[styles.message, styles.multi]}>
          <ul>
            {validationErrors.map((error, index) => (
              <>
                <li key={index}>{error}</li>
                <li key={`a${index}`}>{error}</li>
              </>
            ))}
          </ul>
        </NcFormattedMessage>
      );
    }}
  </FieldError>
);

const FieldLabel = ({ ...props }: LabelProps) => {
  return <NcLabel data-nc="NcField.Label" {...props} />;
};

export const NcField = ({ label, description, children, variant = "vertical" }: NcFieldProps) => {
  return (
    <div data-nc="NcField" css={[styles.base, styles.variant[variant]]}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <div css={styles.wrap}>
        {children}
        {description && <FieldDescription>{description}</FieldDescription>}
        <FieldErrorMessage />
      </div>
    </div>
  );
};
NcField.Description = FieldDescription;
