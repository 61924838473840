import { css } from "@emotion/core";
import {
  Tab as ReactAriaTab,
  TabList as ReactAriaTabList,
  TabPanel as ReactAriaTabPanel,
  Tabs as ReactAriaTabs,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const styles = {
  tabs: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${ncTheme.borderRadius.medium};

    &[data-orientation="vertical"] {
      flex-direction: row;
    }
  `,
  list: css`
    display: flex;

    [data-orientation="horizontal"] & {
      gap: ${ncTheme.spacing(2)};
      padding-inline: ${ncTheme.spacing(2)};
    }

    &[data-orientation="vertical"] {
      flex-direction: column;
      gap: ${ncTheme.spacing(2)};
      margin-right: -${ncTheme.borderWidth.fine};
    }
  `,
  panel: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${ncTheme.spacing(4)};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    border-radius: ${ncTheme.borderRadius.medium};
    background-color: ${ncTheme.colors.light};
    outline-style: none;

    &[data-focus-visible] {
      outline-style: auto;
      ${ncTheme.utilities.outlineStyles}
      z-index: 1;
    }

    [data-orientation="vertical"] & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
  tab: css`
    overflow: hidden;
    display: flex;
    background-color: ${ncTheme.colors.light};
    position: relative;
    cursor: pointer;
    font-weight: bold;
    border-radius: ${ncTheme.borderRadius.small};
    outline-style: none;
    border: ${ncTheme.border(ncTheme.colors.ui)};
    justify-content: space-between;
    white-space: nowrap;
    color: inherit;
    text-decoration: none;
    white-space: normal;

    &[data-selected] {
      color: ${ncTheme.colors.main};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: ${ncTheme.colors.active};
      }
    }

    &[data-hovered]:not([data-selected], [data-disabled]) {
      background-color: ${ncTheme.colors.main};
      color: ${ncTheme.colors.light};
    }

    &[data-disabled] {
      color: ${ncTheme.colors.disabled};
      font-weight: ${ncTheme.fontWeight.standard};
      background-color: ${ncTheme.colors.uiLight};
    }

    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles}
    }

    [data-orientation="horizontal"] & {
      padding: ${ncTheme.spacing(3)};
      flex-basis: 100%;
      margin-bottom: -${ncTheme.borderWidth.fine};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &[data-selected] {
        border-bottom: ${ncTheme.border(ncTheme.colors.light)};

        &::before {
          left: 0;
          right: 0;
          height: ${ncTheme.spacing(1)};
        }
      }
    }

    [data-orientation="vertical"] & {
      padding: ${ncTheme.spacing(4)};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &[data-selected] {
        border-right: none;

        &::before {
          left: 0;
          bottom: 0;
          width: ${ncTheme.spacing(1)};
        }
      }
    }
  `,
};

const NcTabs = ({ ...props }) => <ReactAriaTabs data-nc="NcTabs" css={styles.tabs} {...props} />;

const TabList = ({ ...props }) => (
  <ReactAriaTabList data-nc="NcTabs.TabList" {...props} css={styles.list} />
);

const Tab = ({ ...props }) => <ReactAriaTab data-nc="NcTabs.Tab" css={styles.tab} {...props} />;

const TabPanel = ({ ...props }) => (
  <ReactAriaTabPanel data-nc="NcTabs.TabPanel" {...props} css={styles.panel} />
);

NcTabs.TabList = TabList;
NcTabs.Tab = Tab;
NcTabs.TabPanel = TabPanel;

export { NcTabs };
