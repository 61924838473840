import { useQuery, UseQueryOptions } from "react-query";

import { restFetcher } from "../graphql-hooks/custom-fetcher";

export const usePublicUsernameFormValidation = (
  username?: string,
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn">
) => {
  return useQuery(
    ["username-validator", username],
    () =>
      restFetcher("/v1/signup/validateUsername", {
        body: JSON.stringify({ username }),
        method: "POST",
      }),
    options
  );
};
