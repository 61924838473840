import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";

const styles = {
  list: css`
    display: grid;
    gap: ${ncTheme.spacing(2.5)};
  `,
  item: css`
    align-items: baseline;
    display: grid;
    gap: ${ncTheme.spacing(1.5)};
    grid-template-columns: 1fr 2fr;
  `,
  itemValue: css`
    font-weight: ${ncTheme.fontWeight.bold};
  `,
};

interface NcDataListItemProps {
  label: string | ReactNode;
  children: ReactNode;
}

const NcDataListItem = ({ label, children, ...props }: NcDataListItemProps) => {
  return (
    <div data-nc="NcDataList.Item" css={styles.item} {...props}>
      <dt>{label}</dt>
      <dd css={styles.itemValue}>{children}</dd>
    </div>
  );
};

interface NcDataListProps extends HTMLAttributes<HTMLDListElement> {
  children: ReactNode;
}

export const NcDataList = ({ children, ...props }: NcDataListProps) => {
  return (
    <dl data-nc="NcDataList" css={styles.list} {...props}>
      {children}
    </dl>
  );
};

NcDataList.Item = NcDataListItem;
