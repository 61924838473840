import { useMutation, useQueryClient } from "react-query";

import { useMeQuery } from "~/account/use-me-query";
import {
  useEditableFeaturesQuery,
  useGetUserQuery,
  UserPrivilegesQuery,
  useSecurityRolesQuery,
  useUserPrivilegesQuery,
} from "~/administration/users/api";
import { restPoster, restPutter } from "~/graphql-hooks/custom-fetcher";
import { IUser } from "~/shared/types";

export * from "../../users/api/users.generated";

export const useUserDetails = (id: number) => {
  const { data } = useGetUserQuery({ id }, { suspense: true });
  // TODO: Removing the IUser type here would be ideal
  return data?.user as IUser;
};
useUserDetails.getKey = (id: number) => ["getUser", { id }];

export const useUserPrivileges = () => {
  const { data } = useUserPrivilegesQuery(undefined, { staleTime: Infinity });
  return data?.userPrivileges ?? ([] as UserPrivilegesQuery["userPrivileges"]);
};

type CreateNotedIdUser = {
  title?: string;
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string;
  registrationNumber?: string;
  shortCode?: string;
  organisationId: number;
  securityRoles: { key: string }[];
  addToDefaultTeam?: boolean;
  privileges: { key: string }[];
};

export type NotedIdUser = {
  id: number;
  securityRoles: {
    id: number;
    key: string;
    name: string;
    system: boolean;
  }[];
} & Omit<CreateNotedIdUser, "securityRoles">;

export function useCreateNotedIdUserMutation() {
  return useMutation<NotedIdUser, unknown, CreateNotedIdUser>({
    mutationFn: values => restPoster<CreateNotedIdUser>("/v2/users", values),
  });
}

type UpdateNotedIdUser = {
  id: number;
  title?: string;
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string;
  registrationNumber?: string;
  shortCode?: string;
  organisationId: number;
  securityRoles: { key: string }[];
  privileges: { key: string }[];
  isSuspended?: boolean;
};

export function useUpdateNotedIdUserMutation() {
  const queryClient = useQueryClient();
  const { data: { user: me } = {} } = useMeQuery();

  return useMutation<NotedIdUser, unknown, UpdateNotedIdUser>({
    mutationFn: values => restPutter<UpdateNotedIdUser>(`/v2/users/${values.id}`, values),
    onSuccess: data => {
      queryClient.invalidateQueries(useUserDetails.getKey(data.id));
      if (me?.id === data.id) {
        queryClient.invalidateQueries(useMeQuery.getKey());
      }
    },
  });
}

export const useEditableFeatures = () => {
  const { data, ...rest } = useEditableFeaturesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.editableFeatures.features,
    ...rest,
  };
};

export const useSecurityRoles = () => {
  const { data, ...rest } = useSecurityRolesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.userSecurityRoles,
    ...rest,
  };
};
