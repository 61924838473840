import { css } from "@emotion/core";

import { useFeatures } from "~/account/features.context";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { Page, PageContent, PageContentWrapper } from "~/shared/components/layout";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";
import { PageTitle } from "~/shared/components/primitives";
import { BREAKPOINT_MID_QUERY, theme } from "~/shared/theme";
import { VisuallyHidden } from "~/shared/ui";

import AppointmentsPanel from "./appointments/appointments-panel";
import ClientActions from "./clients/client-actions";
import InvitesPanel from "./invitations-panel";
import RemindersPanel from "./reminders/reminders-panel";
import ClientSearch from "./search/client-search";
import UnsignedRecordsPanel from "./unsigned-records-panel";
import UpdatedRecordsPanel from "./updated-records-panel";

const styles = {
  pageWrapper: css`
    flex-basis: ${theme.container.wide};
  `,
  search: css`
    background: ${theme.colors.neutral.light};
    border: ${theme.border(theme.colors.neutral.medium)};
    border-radius: ${theme.borderRadius.small};
    margin-bottom: ${theme.space[5]};
    padding: ${theme.space[3]};

    @media ${BREAKPOINT_MID_QUERY} {
      display: flex;
      font-size: 1.1em;
    }
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    grid-auto-rows: minmax(24rem, 28vh);
    gap: ${theme.space[6]};
    width: 100%;
  `,
  clients: css`
    display: flex;
    gap: ${theme.space[2]};
  `,
};

export const Dashboard = () => {
  const { t } = useI18n("org");
  const { featureEnabled, featuresLoaded } = useFeatures();

  const showAppointments = featureEnabled(FeaturePermission.BookingSystem);

  return (
    <Page>
      <PageContentWrapper css={styles.pageWrapper}>
        <VisuallyHidden>
          <PageTitle>{t("org:dashboard.page_title")}</PageTitle>
        </VisuallyHidden>
        <PageContent flexDirection="column">
          <div css={styles.search}>
            <ClientSearch />
            <ClientActions />
          </div>
          <div css={styles.grid}>
            {!featuresLoaded ? (
              <LoadingLogo center />
            ) : (
              <>
                <UnsignedRecordsPanel />
                <RemindersPanel />
                {showAppointments && <AppointmentsPanel />}
                <UpdatedRecordsPanel />
                <InvitesPanel />
              </>
            )}
          </div>
        </PageContent>
      </PageContentWrapper>
    </Page>
  );
};

export default Dashboard;
