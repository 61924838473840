import { forwardRef, ReactNode } from "react";

import { Ellipsis } from "../../icons";
import { VisuallyHidden } from "../../ui";
import { MenuList } from "./actions-menu";
import { ButtonMenu } from "./button-menu";
import MenuItem from "./menu-item";

interface PopupActionsMenuProps {
  label: string;
  children: ReactNode;
}

export const PopupActionsMenu = forwardRef<HTMLButtonElement, PopupActionsMenuProps>(
  ({ label, children }, ref) => (
    <ButtonMenu className="menu-toggle" ref={ref} menu={<MenuList>{children}</MenuList>}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <Ellipsis fill="currentColor" width="10px" height="10px" />
    </ButtonMenu>
  )
);
PopupActionsMenu.displayName = "PopupActionsMenu";

export const PopupMenuItem = ({
  id,
  label,
  danger,
  ...rest
}: {
  id: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  danger?: boolean;
}) => (
  <MenuItem key={id} variant={danger ? "danger" : "default"} {...rest}>
    {label}
  </MenuItem>
);
