import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef, useMemo } from "react";
import { useObjectRef } from "react-aria";
import {
  Popover as ReactAriaPopover,
  Select as ReactAriaSelect,
  SelectValue as ReactAriaSelectValue,
  type Key,
  type SelectProps as ReactAriaSelectProps,
} from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { NcButton } from "../nc-button";
import { NcIconChevronDown } from "../nc-icons";
import { NcListBox } from "../nc-list-box";
import { popoverStyles } from "../nc-popover";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import type { PresetInputWidths } from "./nc-input";
import { inputStyles, inputWidthStyles } from "./nc-input";
import { useValidation } from "./use-validation";

const styles = {
  select: css`
    position: relative;
  `,
  button: css`
    color: ${ncTheme.colors.dark};
    justify-content: space-between;

    [data-placeholder="true"] {
      background-color: transparent;
    }

    &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
      &:hover,
      &[data-hovered] {
        color: ${ncTheme.colors.light};
        background-color: ${ncTheme.colors.active};

        [data-placeholder="true"] {
          color: ${ncTheme.colors.light};
        }
      }

      &:focus,
      &[data-pressed="true"] {
        color: ${ncTheme.colors.dark};
        background-color: ${ncTheme.colors.light};

        [data-placeholder="true"] {
          color: ${ncTheme.colors.dark};
        }
      }
    }
  `,
  selected: css`
    > svg {
      display: none;
    }
  `,
  popover: css`
    padding: 0;
    min-width: var(--trigger-width);
  `,
  item: css`
    &[data-focus-visible] {
      outline: none;
    }
  `,
};

export type NcSelectItem = {
  id: Key;
  label: string;
} & Record<string, Key>;

interface NcFieldSelectProps
  extends FieldProps,
    PresetInputWidths,
    Omit<
      ReactAriaSelectProps<NcSelectItem>,
      "name" | "validate" | "onSelectionChange" | "autoFocus"
    > {
  items?: Iterable<NcSelectItem>;
  renderEmptyState?: () => ReactNode;
  onSelectionChange?: (item: NcSelectItem | undefined) => void;
  storeItemAsValue?: boolean;
  labelValue?: string;
}

const findItemById = (items: NcSelectItem[], id: Key) => items.find(item => item.id === id);

const findItemByValue = (items: NcSelectItem[], property: Key, value: Key) =>
  items.find(item => item[property] === value);

const getSelectedItem = (items: NcSelectItem[], property: Key, value: Key | { id: Key }) => {
  if (value && typeof value === "object") {
    return {
      defaultSelectedKey: value?.id,
    };
  }
  const found = findItemByValue(items, property, value);
  return found
    ? {
        defaultSelectedKey: found?.id,
      }
    : {};
};

export const NcFieldSelect = forwardRef(
  (
    {
      label,
      labelNode,
      description,
      items,
      name,
      renderEmptyState,
      inputWidth = "medium",
      storeItemAsValue,
      labelValue = "label",
      variant,
      ...props
    }: NcFieldSelectProps,
    ref
  ) => {
    const objRef = useObjectRef(ref);
    const itemsArray = useMemo(() => Array.from(items as unknown as NcSelectItem[]), [items]);
    const {
      register,
      formState: { defaultValues },
      setValue,
    } = useFormContext();

    const { validationHandler } = useValidation({
      label: label,
      rules: {
        required: props?.isRequired ? {} : undefined,
      },
    });

    const field = register(name);

    const handleChange = (id: Key) => {
      const item = findItemById(itemsArray, id);
      const value = item?.[labelValue];
      setValue(name, storeItemAsValue ? item : value, {
        shouldValidate: true,
        shouldDirty: true,
      });
      props?.onSelectionChange?.(item);
    };

    return (
      <ReactAriaSelect
        data-nc="NcFieldSelect"
        css={styles.select}
        {...getSelectedItem(itemsArray, labelValue, defaultValues?.[name])}
        {...({
          ...props,
          ref: objRef,
        } as unknown as ReactAriaSelectProps<NcSelectItem>)}
        {...field}
        validate={validationHandler}
        onSelectionChange={handleChange}
      >
        <NcField {...{ description, label: labelNode || label, variant }}>
          <NcButton css={[inputStyles, styles.button, inputWidthStyles[inputWidth]]} name={name}>
            <ReactAriaSelectValue css={styles.selected} />
            <NcIconChevronDown aria-hidden="true" />
          </NcButton>
          <ReactAriaPopover css={[popoverStyles.popover, styles.popover]}>
            <NcListBox items={items} renderEmptyState={renderEmptyState}>
              {(item: NcSelectItem) => (
                <NcListBox.Item id={item.id} textValue={item.label} css={styles.item}>
                  {item.label}
                </NcListBox.Item>
              )}
            </NcListBox>
          </ReactAriaPopover>
        </NcField>
      </ReactAriaSelect>
    );
  }
);
