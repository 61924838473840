import { config } from "@noted/configuration";

const baseURL = config.apiUrl + "/api";
const graphqlEndpoint = `${baseURL}/v1/graphql`;

let tokenRetriever: () => Promise<string>;
let session: string | undefined;

export function configureFetchers(
  tokenRetrieverFn: () => Promise<string>,
  sessionValue: string | undefined
) {
  tokenRetriever = tokenRetrieverFn;
  session = sessionValue;
}

const defaultHeaders = {
  accept: "application/json, text/plain, */*",
  "content-type": "application/json",
};

async function getHeaders(initial?: HeadersInit): Promise<HeadersInit> {
  if (!tokenRetriever) {
    throw new Error(
      "Custom fetcher has not been configured.  Did you forget to call 'configureFetchers'?"
    );
  }

  const token = await tokenRetriever();
  return session
    ? {
        ...initial,
        Authorization: `Bearer ${token}`,
        "X-Context-Session": session,
      }
    : { ...initial, "X-Auth-Token": token };
}

const responseHandler = async (res: Response) => {
  if (!res.ok) {
    let error = { status: res.status };
    try {
      const json = await res.json();
      error = { ...error, ...json };
    } catch {
      // empty
    }
    throw error;
  }
  const text = await res.text();
  return text ? JSON.parse(text) : {};
};

// IF THIS FILE IS TO BE RENAMED / RELOCATED, OR THIS FUNCTION IS TO
// BE UPDATED, MAKE SURE GRAPHQL.SH POINTS IT TO THE NEWER REFERENCE
export const customFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const headers = await getHeaders(defaultHeaders);

    const res = await fetch(graphqlEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await responseHandler(res);

    if (json.errors) {
      const { message } = json.errors[0];
      throw new Error(message);
    }

    return json.data;
  };
};

export const imageToBlobUrlFetcher = async (path: string) => {
  const headers = await getHeaders();

  const response = await fetch(`${baseURL}${path}`, {
    headers,
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const restPostFormData = async (path: string, formData: FormData) => {
  // special implementation that posts FormData
  // this method sets the correct method and headers
  const headers = await getHeaders();

  const res = await fetch(`${baseURL}${path}`, { headers, method: "POST", body: formData });

  return responseHandler(res);
};

type RequestInitWithHeaders = Omit<RequestInit, "headers">;

export const restFetcher = async (path: string, requestInfo: RequestInitWithHeaders = {}) => {
  const headers = await getHeaders(defaultHeaders);

  const res = await fetch(`${baseURL}${path}`, { headers, ...requestInfo });

  return responseHandler(res);
};

export const restPoster = <T = unknown>(path: string, body?: T) => {
  return restFetcher(path, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const restPutter = <T = unknown>(path: string, body?: T) => {
  return restFetcher(path, {
    method: "PUT",
    body: JSON.stringify(body),
  });
};

export const restDeleter = (path: string) => {
  return restFetcher(path, {
    method: "DELETE",
  });
};

export const removeNullKeys = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== null));
