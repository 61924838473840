import { useForkedRef } from "@reach/utils";
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FocusLock from "react-focus-lock";
import { Instance } from "tippy.js";

import { useI18n } from "~/hooks/use-i18n";

import { ActionsMenu } from "./actions-menu";
import Button from "./button";

type Tooltip = Omit<ComponentProps<typeof ActionsMenu>, "children" | "content">;
type ButtonProps = ComponentProps<typeof Button>;

type ButtonMenuProps = {
  tooltip?: Tooltip;
  menu: ReactElement | Array<ReactElement | string | number>;
} & ButtonProps;

export const ButtonMenu = forwardRef<HTMLButtonElement, ButtonMenuProps>(
  ({ tooltip = {}, menu, ...props }, forwardedRef) => {
    const { t } = useI18n();
    const id = useMemo(() => Math.random(), []);
    const [instance, setInstance] = useState<Instance>();
    const [shown, setShown] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const ref = useForkedRef(forwardedRef, buttonRef);

    const onActioned = () => setShown(prev => !prev);

    useEffect(() => {
      if (shown && instance) {
        const menu = document.querySelectorAll(`[data-menuid='${id}']`)[0] as
          | HTMLElement
          | undefined;
        const first = menu?.querySelectorAll("a, button")[0] as HTMLElement | undefined;
        if (typeof first?.focus === "function") {
          first.focus();
        }
      }

      const handleKeyDown = (e: KeyboardEvent) => {
        if (!shown || !instance) {
          return;
        }
        if (e.key === "Escape") {
          instance.hide();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target = e.target as any;
        if (e.key === "ArrowDown") {
          const sibling = target.nextElementSibling;
          if (sibling && typeof sibling.focus === "function") {
            sibling.focus();
          }
        }
        if (e.key === "ArrowUp") {
          const sibling = target.previousElementSibling;
          if (sibling && typeof sibling.focus === "function") {
            sibling.focus();
          }
        }
        e.stopPropagation();
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [shown, instance, id]);

    const { onShow, onHide, onCreate, onMount, hideOnClick, ...otherTooltipProps } = tooltip;

    return (
      <FocusLock disabled={!shown}>
        <ActionsMenu
          onShow={e => {
            onActioned();
            if (onShow) {
              onShow(e);
            }
          }}
          onHide={e => {
            onActioned();
            buttonRef?.current?.focus();
            if (onHide) {
              onHide(e);
            }
          }}
          onCreate={e => {
            setInstance(e);
            if (onCreate) {
              onCreate(e);
            }
          }}
          onMount={e => {
            setInstance(e);
            if (onMount) {
              onMount(e);
            }
          }}
          hideOnClick={hideOnClick ?? true}
          {...otherTooltipProps}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          content={Children.map(menu, (child: any, i) =>
            cloneElement(child, {
              "data-menuid": i === 0 ? id : undefined,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick: (e: any) => {
                const prevOnClick = child?.props?.onClick;
                instance?.hide();
                if (typeof prevOnClick === "function") {
                  prevOnClick(e);
                }
              },
            })
          )}
        >
          <Button aria-label={t("menu_open")} ref={ref} {...props} />
        </ActionsMenu>
      </FocusLock>
    );
  }
);
ButtonMenu.displayName = "ButtonMenu";
