import { css } from "@emotion/core";
import { KeyboardEventHandler, MouseEventHandler, ReactNode, useEffect } from "react";

import { theme } from "~/shared/theme";

interface SearchPopoverLayoutProps {
  className?: string;
  popoverOpen: boolean;
  closeAction: () => void;
  children: {
    search: ReactNode;
    controls?: ReactNode;
    results: ReactNode;
  };
}

const styles = css`
  display: flex;
  flex-direction: column;
  position: relative;

  .search-popover-layout {
    &__search-wrapper {
      position: relative;
    }

    &__results {
      background: ${theme.colors.neutral.lightest};
      border: ${theme.border(theme.colors.neutral.medium)};
      border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
      box-shadow: ${theme.shadows.popOver};
      display: flex;
      justify-content: center;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 2.2rem;
      z-index: 1;
    }
  }
`;

const SearchPopoverLayout = ({
  className,
  closeAction,
  popoverOpen,
  children,
}: SearchPopoverLayoutProps) => {
  const { controls, search, results } = children;

  const handleEsc = ((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeAction();
    }
  }) as unknown as KeyboardEventHandler<HTMLDivElement>;

  const handleClick = ((event: MouseEvent) => {
    // prevent clicks inside from bubbling up to body
    event.stopPropagation();
  }) as unknown as MouseEventHandler<HTMLDivElement>;

  useEffect(() => {
    const hasParent = window.parent;
    if (popoverOpen) {
      if (hasParent) {
        window.parent.document.body.addEventListener("click", closeAction);
      }
      document.body.addEventListener("click", closeAction);
      return;
    }
    if (hasParent) {
      document.body.removeEventListener("click", closeAction);
    }
    document.body.removeEventListener("click", closeAction);
  }, [popoverOpen]);

  return (
    <div
      className={`search-popover-layout ${className}`}
      css={styles}
      tabIndex={0}
      onKeyDown={handleEsc}
      onClick={handleClick}
    >
      <div className="search-popover-layout__search-wrapper">
        {search}
        {popoverOpen && <div className="search-popover-layout__results">{results}</div>}
      </div>
      {controls && controls}
    </div>
  );
};

export default SearchPopoverLayout;
