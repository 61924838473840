import "@reach/dialog/styles.css";

import { css } from "@emotion/core";
import { DateTime } from "luxon";
import { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import { useI18n } from "~/hooks/use-i18n";

import { theme } from "../theme";
import { DatePicker } from "./index";

const FORMAT_DATE = "dd/MM/yyyy";
const FORMAT_DATETIME = `${FORMAT_DATE} HH:mm`;
const DATE_SEPARATOR = "/";
const TIME_SEPARATOR = ":";

interface DatePickerProps extends Pick<InputHTMLAttributes<HTMLInputElement>, "id" | "className"> {
  value: Date | undefined;
  onDateSelect: (date?: Date) => void;
  onError?: (message: string) => void;
  clearError?: () => void;
  showTime?: boolean;
}

const validateDate = (
  dateString: string,
  format: string
): {
  isComplete: boolean;
  validatedDate: DateTime | undefined;
} => {
  const isComplete = dateString.length >= format.length;
  if (!isComplete) {
    return {
      isComplete,
      validatedDate: undefined,
    };
  }
  const validatedDate = DateTime.fromFormat(dateString, format);
  return { isComplete, validatedDate };
};

const formatValueWithSeparator = (value: string, separator: string = DATE_SEPARATOR) =>
  value.length === 2 ? `${value}${separator}` : value;

const coercedValidUserInput = (inputString: string, showTime: boolean) => {
  const digits = inputString.replaceAll(/[^0-9]/g, "");
  const parts = {
    day: digits.slice(0, 2),
    month: digits.slice(2, 4),
    year: digits.slice(4, 8),
    hour: digits.slice(8, 10),
    minute: digits.slice(10, 12),
  };
  const date = `${formatValueWithSeparator(parts.day)}${formatValueWithSeparator(parts.month)}${
    parts.year
  }`;
  const time = `${formatValueWithSeparator(parts.hour, TIME_SEPARATOR)}${parts.minute}`;
  return showTime ? `${date} ${time}`.trim() : date;
};

const toDateString = (date: Date | undefined, format: string) =>
  date ? DateTime.fromJSDate(date).toFormat(format) : "" || "";

const DateInput = ({
  className = "",
  clearError,
  onError,
  onDateSelect,
  showTime = true,
  value,
  ...rest
}: DatePickerProps) => {
  const { t } = useI18n();
  const format = showTime ? FORMAT_DATETIME : FORMAT_DATE;

  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<string | null>(
    value ? toDateString(value, format) : null
  );
  const [cursorPos, setCursorPos] = useState<number | undefined>(undefined);
  const [currentValueDebounced] = useDebounce(currentValue, 300);

  const handleTextDate = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target?.value ?? "");
  };

  const handleSelectDate = (newDate?: Date) => {
    setCurrentValue(toDateString(newDate, format));
  };

  useEffect(() => {
    if (cursorPos && inputRef.current) {
      // put the cursor back where it was after coercing input
      inputRef.current.setSelectionRange(cursorPos, cursorPos);
      setCursorPos(undefined);
    }
  }, [cursorPos]);

  useEffect(() => {
    if (currentValueDebounced) {
      const coerced = coercedValidUserInput(currentValueDebounced, showTime);

      if (coerced !== currentValue) {
        const stringDiffLength = coerced.length - currentValueDebounced.length;
        setCursorPos(
          inputRef?.current?.selectionStart
            ? inputRef?.current?.selectionStart + stringDiffLength
            : undefined
        );
        setCurrentValue(coerced);
      }

      const { validatedDate, isComplete } = validateDate(coerced, format);

      if (validatedDate && validatedDate.isValid) {
        clearError?.();
        onDateSelect(validatedDate.toJSDate());
        return;
      }

      if (isComplete && onError && validatedDate?.invalidReason) {
        onError(`"${coerced}" ${t("date_input.invalid_date")}`);
      }
    }
  }, [currentValueDebounced]);

  return (
    <div css={style} className={`date-input ${className}`}>
      <input
        className="date-input__input"
        {...rest}
        ref={inputRef}
        value={currentValue || ""}
        onChange={handleTextDate}
        placeholder={showTime ? t("date_input.placeholder_time") : t("date_input.placeholder")}
        maxLength={showTime ? FORMAT_DATETIME.length : FORMAT_DATE.length}
      />
      <DatePicker
        ref={inputRef}
        buttonOnly
        date={value}
        showTime={showTime}
        onDateSelect={handleSelectDate}
        interval={1}
        noRounding
      />
    </div>
  );
};
DateInput.displayName = "DateInput";

const style = css`
  &.date-input {
    align-items: stretch;
    display: flex;
    max-width: 100%;

    .datepicker {
      display: flex;
      align-items: stretch;

      &__toggle {
        border-radius: 0 ${theme.space[1]} ${theme.space[1]} 0;
      }
    }

    &:focus-within {
      border-radius: ${theme.space[1]};
      outline: 0.1rem solid ${theme.colors.neutral.mediumDark};

      .datepicker__toggle {
        border-color: ${theme.colors.neutral.mediumDark};
      }
    }
  }

  .date-input {
    &__input {
      border: 1px solid ${theme.colors.neutral.medium};
      border-right: 0;
      border-radius: ${theme.space[1]} 0 0 ${theme.space[1]};
      padding: ${theme.space[2]};
      margin-right: -0.2rem;
      flex-grow: 1;
      max-width: calc(100% - 3.2rem);
      width: 100%;

      &:focus,
      &:active {
        outline: none;
        border-color: ${theme.colors.neutral.mediumDark};
      }
    }
  }
`;

export default DateInput;
