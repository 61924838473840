import { IUser } from "~/shared/types";

import {
  useEditableFeaturesQuery,
  useGetUserQuery,
  useSecurityRolesQuery,
  useUserPrivilegesQuery,
  type UserPrivilegesQuery,
} from "./users.generated";

export * from "./users.generated";

export const useUserDetails = (id: number) => {
  const { data } = useGetUserQuery({ id }, { suspense: true });
  // TODO: Removing the IUser type here would be ideal
  return data?.user as IUser;
};
useUserDetails.getKey = (id: number) => ["getUser", { id }];

export const useUserPrivileges = () => {
  const { data } = useUserPrivilegesQuery(undefined, { staleTime: Infinity });
  return data?.userPrivileges ?? ([] as UserPrivilegesQuery["userPrivileges"]);
};

export const useSecurityRoles = () => {
  const { data, ...rest } = useSecurityRolesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.userSecurityRoles,
    ...rest,
  };
};

export const useEditableFeatures = () => {
  const { data, ...rest } = useEditableFeaturesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.editableFeatures.features,
    ...rest,
  };
};
