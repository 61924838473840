import { css } from "@emotion/core";
import type { HTMLAttributes } from "react";

import { ncTheme } from "../nc-theme";

interface NcFormattedAddressProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  address: {
    street1: string;
    street2?: string;
    suburb?: string;
    city?: string;
    region?: string;
    postcode?: string;
    country?: string;
  };
}

const styles = {
  address: css`
    display: flex;
    flex-direction: column;
    gap: ${ncTheme.spacing(0.5)};
  `,
  addressLabel: css`
    margin-bottom: ${ncTheme.spacing(0.5)};
  `,
};

export const NcFormattedAddress = ({ address, label, ...props }: NcFormattedAddressProps) => (
  <address data-nc="NcFormattedAddress" css={styles.address} {...props}>
    {label && <strong css={styles.addressLabel}>{label}</strong>}
    {address.street1 && <div>{address.street1}</div>}
    {address.street2 && <div>{address.street2}</div>}
    {address.suburb && <div>{address.suburb}</div>}
    {(address.city || address.postcode) && (
      <div>
        <span>{address.city}</span>
        &nbsp;
        <span>{address.postcode}</span>
      </div>
    )}
    {address.country && <div>{address.country}</div>}
  </address>
);
