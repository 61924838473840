import { css, Global } from "@emotion/core";
import type { ReactNode } from "react";
import { createContext } from "react";
import type { NavigateFunction } from "react-router-dom";

import { NcRouterProvider } from "./nc-router-provider";
import { ncTheme } from "./nc-theme";
import { NcUtilityCss } from "./nc-utility-css";

const defaultValue = {
  t: (value: string) => value,
  locale: "en-NZ",
  navigate: () => {},
};

type NcGlobalContextValue = {
  t: (value: string, params?: { [key: string]: string }) => string;
  locale: string;
  navigate: NavigateFunction;
};

const globalThemeStyles = css`
  /* Reset */
  *,
  ::before,
  ::after {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  ol,
  ul {
    list-style: none;
  }

  a:not([class]) {
    color: inherit;
  }

  button {
    background: transparent;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* App defaults */
  html {
    font-size: 0.875rem;
    font-family:
      "Lato",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    color: ${ncTheme.colors.dark};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${ncTheme.colors.light};
    outline-color: ${ncTheme.colors.brandActive};

    *:focus {
      outline: none;
    }

    *[data-focused="true"][data-focus-visible="true"],
    *:focus-visible {
      ${ncTheme.utilities.outlineStyles};
    }
  }

  strong {
    font-weight: bold;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  @media print {
    .ReactQueryDevtools {
      display: none !important;
    }

    html,
    body,
    #root,
    main {
      overflow: visible !important;
      max-height: unset !important;
      min-height: 100% !important;
      height: -webkit-fill-available !important;
    }

    body * {
      overflow: visible !important;
      max-height: unset !important;
    }
  }
`;

export const NcGlobalContext = createContext<NcGlobalContextValue>(defaultValue);

export const NcGlobalProvider = ({
  value,
  children,
}: {
  value: NcGlobalContextValue;
  children: ReactNode;
}) => (
  <NcGlobalContext.Provider value={value || defaultValue}>
    <Global styles={globalThemeStyles} />
    <NcUtilityCss />
    <NcRouterProvider>{children}</NcRouterProvider>
  </NcGlobalContext.Provider>
);
