import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { linkStyles } from "./nc-button";

const styles = {
  common: css`
    font-weight: ${ncTheme.fontWeight.standard};
    font-size: ${ncTheme.fontSizes[3]};
    line-height: 1.3;
    max-width: ${ncTheme.containers.text};

    a {
      ${linkStyles}
    }

    p {
      white-space: pre-wrap;
    }

    p,
    ul,
    ol {
      margin-bottom: ${ncTheme.spacing(2.5)};
    }

    ul,
    ol {
      margin-top: ${ncTheme.spacing(2.5)};
      margin-left: ${ncTheme.spacing(6)};
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;

      li {
        padding-left: ${ncTheme.spacing(0.5)};
      }
    }
  `,
  variant: {
    default: css``,
    danger: css`
      color: ${ncTheme.colors.dangerActive};
    `,
    secondary: css`
      font-style: italic;
    `,
  },
};

interface NcFormattedMessageProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.variant;
  children: ReactNode;
}

export const NcFormattedMessage = ({
  variant = "default",
  children,
  ...props
}: NcFormattedMessageProps) => {
  return (
    <div data-nc="NcFormattedMessage" css={[styles.common, styles.variant[variant]]} {...props}>
      {children}
    </div>
  );
};
