import { css } from "@emotion/core";
import type { CalendarDateTime } from "@internationalized/date";
import { fromDate, parseDateTime, toCalendarDateTime } from "@internationalized/date";
import type { ClipboardEventHandler, ReactNode } from "react";
import { useCallback, useContext } from "react";
import type { DateValue, DatePickerProps as ReactAriaDateFieldProps } from "react-aria-components";
import {
  Calendar,
  CalendarCell,
  CalendarGrid,
  DatePickerStateContext,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  DateInput as ReactAriaDateInput,
  DatePicker as ReactAriaDatePicker,
} from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { NcButton } from "../nc-button";
import { NcIconCalendar, NcIconChevronLeft, NcIconChevronRight } from "../nc-icons";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import type { PresetInputWidths } from "./nc-input";
import { inputStyles, inputWidthStyles } from "./nc-input";
import { useValidation } from "./use-validation";

interface DateValidationProps {
  minValue?: Date | undefined;
  maxValue?: Date | undefined;
  value?: Date | undefined;
  defaultValue?: Date | undefined;
  isDateUnavailable?: (date: Date) => boolean;
}

interface FieldDateProps
  extends FieldProps,
    PresetInputWidths,
    Omit<
      ReactAriaDateFieldProps<DateValue>,
      | "name"
      | "defaultValue"
      | "value"
      | "onChange"
      | "minValue"
      | "maxValue"
      | "validate"
      | "placeholderValue"
      | "isDateUnavailable"
      | "autoFocus"
    >,
    DateValidationProps {
  onChange?: (value: Date | undefined) => void;
  includeTime?: boolean;
  picker?: boolean;
}

export const fieldDateStyles = {
  group: css`
    ${inputStyles};
    display: flex;
    align-items: center;

    &:focus-within {
      border-color: ${ncTheme.colors.active};
    }
  `,
  inputs: css`
    display: flex;
    gap: ${ncTheme.spacing(1)};
    flex-grow: 1;
  `,
  segment: css`
    outline-color: ${ncTheme.colors.focused};
  `,
  pickerButton: css`
    justify-self: flex-end;

    & > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  `,
  calendar: {
    popover: css`
      background: ${ncTheme.colors.light};
      border: ${ncTheme.border()};
      border-radius: ${ncTheme.borderRadius.medium};
      padding: ${ncTheme.spacing(4)} ${ncTheme.spacing(5)};
      box-shadow: ${ncTheme.shadows.medium};

      &:focus-visible {
        outline-color: ${ncTheme.colors.active};
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${ncTheme.spacing(2)};
      padding-bottom: ${ncTheme.spacing(2)};
      border-bottom: ${ncTheme.border()};
    `,
    calendar: css`
      min-height: 15rem;

      button,
      input {
        outline-color: ${ncTheme.colors.active};
      }

      th {
        padding-bottom: ${ncTheme.spacing(2)};
        font-weight: ${ncTheme.fontWeight.bold};
      }

      &:focus-visible {
        outline-color: ${ncTheme.colors.active};
      }
    `,
    cell: css`
      border-radius: ${ncTheme.borderRadius.small};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: ${ncTheme.spacing(2)};
      color: ${ncTheme.colors.main};
      font-weight: ${ncTheme.fontWeight.bold};
      outline-color: ${ncTheme.colors.active};

      &[data-hovered="true"],
      &[data-active="true"] {
        outline-color: ${ncTheme.colors.focused};
        background-color: ${ncTheme.colors.main};
        color: ${ncTheme.colors.light};
      }

      &[aria-disabled="true"],
      &[aria-disabled="true"]:hover,
      &[aria-disabled="true"]:active {
        cursor: not-allowed;
        color: ${ncTheme.colors.disabled};
        font-weight: ${ncTheme.fontWeight.standard};
      }

      &[data-selected="true"] {
        background-color: ${ncTheme.colors.active};
        color: ${ncTheme.colors.light};
      }

      &[data-selection-start="true"] {
        border-radius: ${ncTheme.borderRadius.small} 0 0 ${ncTheme.borderRadius.small};
      }

      &[data-selection-end="true"] {
        border-radius: 0 ${ncTheme.borderRadius.small} ${ncTheme.borderRadius.small} 0;
      }
    `,
  },
};

// Change all Date props to DateValue for ReactAriaDatePicker
export const castDatePropTypes = (dateProps: DateValidationProps) => {
  const { isDateUnavailable, ...remainingProps } = dateProps;

  const mapped = Object.entries(remainingProps).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value ? { [key]: toCalendarDateTime(fromDate(value, tz)) } : {}),
    }),
    {}
  ) as { [p: string]: CalendarDateTime };

  const isDateUnavailableHandler = isDateUnavailable
    ? { isDateUnavailable: (value: DateValue) => isDateUnavailable(value.toDate(tz)) }
    : {};

  return {
    ...mapped,
    ...isDateUnavailableHandler,
  };
};

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const dateValueToDate = (dateValue: DateValue | undefined) =>
  dateValue ? dateValue.toDate(tz) : undefined;

const PasteHandler = ({ children }: { name: string; children: ReactNode }) => {
  const { setValue } = useContext(DatePickerStateContext) as {
    setValue: (value: DateValue) => void;
  };

  const onPaste = (e: ClipboardEvent) => {
    const pastedData = e.clipboardData?.getData("text");
    if (pastedData) {
      const normaliseString = pastedData.replaceAll(/[^0-9]/g, "");
      const parts = {
        day: normaliseString.slice(0, 2),
        month: normaliseString.slice(2, 4),
        year: normaliseString.slice(4, 8),
        hour: normaliseString.slice(8, 10),
        minute: normaliseString.slice(10, 12),
      };
      const dateString = `${parts.year}-${parts.month}-${parts.day}`;
      setValue(
        parts.hour && parts.minute
          ? (parseDateTime(`${dateString}T${parts.hour}:${parts.minute}`) as unknown as DateValue)
          : (parseDateTime(dateString) as unknown as DateValue)
      );
    }
  };
  return (
    <div tabIndex={-1} onPaste={onPaste as unknown as ClipboardEventHandler<HTMLDivElement>}>
      {children}
    </div>
  );
};

const PickerCalendar = () => {
  return (
    <Popover css={fieldDateStyles.calendar.popover}>
      <Dialog>
        <Calendar>
          <header css={fieldDateStyles.calendar.header}>
            <NcButton variant="icon" slot="previous">
              <NcIconChevronLeft />
            </NcButton>
            <Heading />
            <NcButton variant="icon" slot="next">
              <NcIconChevronRight />
            </NcButton>
          </header>
          <CalendarGrid css={fieldDateStyles.calendar.calendar}>
            {date => <CalendarCell date={date} css={fieldDateStyles.calendar.cell} />}
          </CalendarGrid>
        </Calendar>
      </Dialog>
    </Popover>
  );
};

export const NcFieldDate = ({
  label,
  labelNode,
  name,
  description,
  minValue,
  maxValue,
  value,
  defaultValue,
  isDateUnavailable,
  includeTime,
  picker,
  inputWidth = "medium",
  shouldForceLeadingZeros = true,
  hourCycle = 24,
  variant,
  ...props
}: FieldDateProps) => {
  const {
    register,
    formState: { defaultValues },
    setValue,
  } = useFormContext();

  const { validationHandler } = useValidation({
    label: label,
    rules: {
      required: props?.isRequired ? {} : undefined,
      minDate: minValue
        ? {
            message: `{{label}} must be at after {{minDate}}`,
            value: includeTime ? minValue.toLocaleString() : minValue.toLocaleDateString(),
            valueDate: minValue,
          }
        : undefined,
      maxDate: maxValue
        ? {
            message: `{{label}} must be before {{maxDate}}`,
            value: includeTime ? maxValue.toLocaleString() : maxValue.toLocaleDateString(),
            valueDate: maxValue,
          }
        : undefined,
      isDateUnavailable: isDateUnavailable
        ? {
            check: isDateUnavailable,
          }
        : undefined,
    },
  });

  const dateValidationHandler = useCallback(
    (date: DateValue) => validationHandler(dateValueToDate(date)),
    [validationHandler]
  );

  const { ref } = register(name);

  const onChange = (value: DateValue) => {
    const dateValue = value ? value.toDate(tz) : undefined;
    setValue(name, dateValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props?.onChange?.(dateValue);
  };

  return (
    <ReactAriaDatePicker
      data-nc="NcFieldDate"
      {...{
        ...castDatePropTypes({
          minValue,
          maxValue,
          value,
          defaultValue,
          ...(defaultValues?.[name] ? { defaultValue: defaultValues?.[name] } : {}),
          isDateUnavailable,
        }),
        ...props,
        validate: dateValidationHandler,
        name,
        ref,
        hourCycle,
        shouldForceLeadingZeros,
        granularity: includeTime ? "minute" : "day",
        onChange,
      }}
    >
      <PasteHandler name={name}>
        <NcField {...{ description, label: label || labelNode, variant }}>
          <Group css={[fieldDateStyles.group, inputWidthStyles[inputWidth]]}>
            <ReactAriaDateInput css={fieldDateStyles.inputs}>
              {segment => <DateSegment css={fieldDateStyles.segment} segment={segment} />}
            </ReactAriaDateInput>
            {picker && (
              <NcButton variant="icon" css={fieldDateStyles.pickerButton}>
                <NcIconCalendar />
              </NcButton>
            )}
          </Group>
          {picker && <PickerCalendar />}
        </NcField>
      </PasteHandler>
    </ReactAriaDatePicker>
  );
};
