import { css } from "@emotion/core";
import { Fragment, useContext } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "../../account/account.context";
import { useToast } from "../../shared/components/alerts/alerts";
import { ErrorLoadingText } from "../../shared/components/errors/error-loading-text";
import { Button, Checkbox } from "../../shared/components/forms";
import { LoadingText } from "../../shared/components/loading/loading-text";
import { Box } from "../../shared/components/primitives";
import Flex from "../../shared/components/primitives/flex";
import Text from "../../shared/components/primitives/text";
import { theme } from "../../shared/theme";
import { useSubscriptionQuery } from "./api";
import { Addon } from "./use-addons";

const styles = {
  largeColumn: css`
    flex-basis: 70%;
  `,

  smallColumn: css`
    flex-basis: 20%;
  `,

  costColumn: css`
    flex-basis: 10%;
    text-align: right;
  `,
};

export const PaymentInformationWithAddons = ({
  packageId,
  packageName,
  addons,
  addonsLoading,
  addonsError,
  refetchAddons,
  toggleAddon,
}: {
  packageId: string;
  packageName: string;
  addons: Addon[];
  addonsLoading: boolean;
  addonsError: boolean;
  toggleAddon: (id: number) => void;
  refetchAddons: () => void;
}) => {
  const gstRate = 15;
  const { t, formatCurrency } = useI18n("admin-org");
  const { organisation } = useContext(AccountContext);

  const { enqueueError } = useToast();

  const errorLoading = t("error_loading", { component: t("subscription_details") });

  const { data, isLoading: loading } = useSubscriptionQuery(
    {
      estimateInput: {
        packageId,
        addOnIds: addons.filter(a => a.enabled).map(a => a.id),
      },
    },
    {
      onError: () => enqueueError(errorLoading),
    }
  );

  const packageInformation = data?.packageChangeEstimate;

  const border = `1px solid ${theme.colors.neutral.medium}`;

  return (
    <Box data-testid="payment-information-with-addons">
      <Flex borderTop={border} borderBottom={border} py="2">
        <div css={styles.largeColumn}>
          {t("admin-org:subscriptions.plan.plan_users", { packageName })}
        </div>
        {packageInformation ? (
          <>
            <div css={styles.smallColumn}>
              {t("admin-org:subscriptions.plan.user_cost", {
                users: packageInformation.totalBillableUsers,
                monthlyCost: formatCurrency(
                  packageInformation.planPerUnitCosts / 100,
                  packageInformation.currencyCode ?? undefined
                ),
              })}
            </div>
            <div css={styles.costColumn}>
              <Text fontWeight="bold">
                {formatCurrency(
                  (packageInformation.totalBillableUsers * packageInformation.planPerUnitCosts) /
                    100,
                  packageInformation.currencyCode
                )}
              </Text>
            </div>
          </>
        ) : (
          <Fragment>
            <div css={styles.smallColumn} />
            <div css={styles.costColumn}>
              <Text data-testid="package-cost-calculating">
                {t("admin-org:subscriptions.calculating")}
              </Text>
            </div>
          </Fragment>
        )}
      </Flex>
      <Flex py="2">
        <Text fontWeight="bold" fontSize="3">
          {t("admin-org:subscriptions.plan.addons_title")}
        </Text>
      </Flex>
      <Box minHeight="10rem" borderBottom={border}>
        {addonsLoading ? (
          <LoadingText text={t("admin-org:subscriptions.plan.addons_loading")} />
        ) : addonsError ? (
          <Box>
            <ErrorLoadingText text={t("admin-org:subscriptions.plan.addons_loading_error")} />
            <Button mt="2" onClick={() => refetchAddons()}>
              {t("admin-org:subscriptions.plan.addons_refetch_button")}
            </Button>
          </Box>
        ) : addons.length === 0 ? (
          <Text>{t("admin-org:subscriptions.plan.addons_empty")}</Text>
        ) : addons.length !== 0 ? (
          <Fragment>
            {addons.map(addonFeature => (
              <Flex key={addonFeature.id} data-testid={`addon-row-${addonFeature.id}`}>
                <div css={styles.largeColumn}>
                  <Flex width="100%">
                    <Checkbox
                      data-testid={`addon_${addonFeature.id}`}
                      name={`addon_${addonFeature.id}`}
                      id={`addon_${addonFeature.id}`}
                      checked={Boolean(addonFeature.enabled)}
                      onChange={() => toggleAddon(addonFeature.id)}
                    />
                    <Box ml="4">
                      <Text fontWeight="bold">{addonFeature.name}</Text>
                      <Text>{addonFeature.description}</Text>
                    </Box>
                  </Flex>
                </div>
                <div css={styles.smallColumn}>
                  {t("admin-org:subscriptions.plan.addon_cost", {
                    users: addonFeature.billableUsers,
                    monthlyCost: formatCurrency(
                      addonFeature.unitPrice / 100,
                      packageInformation?.currencyCode
                    ),
                  })}
                </div>
                <div css={styles.costColumn}>
                  <Text fontWeight="bold" data-testid={`addon-${addonFeature.id}-total`}>
                    {formatCurrency(
                      addonFeature.totalPrice / 100,
                      packageInformation?.currencyCode
                    )}
                  </Text>
                </div>
              </Flex>
            ))}
          </Fragment>
        ) : null}
      </Box>
      {organisation.countryCode === "NZ" && (
        <Flex py="2" data-testid="gst-row">
          <div css={styles.largeColumn} />
          <div css={styles.smallColumn}>
            {t("admin-org:subscriptions.plan.gst_label", { gstRate })}
          </div>
          <div css={styles.costColumn}>
            {loading ? (
              <Text data-testid="package-gst-calculating">
                {t("admin-org:subscriptions.calculating")}
              </Text>
            ) : packageInformation ? (
              <Text fontWeight="bold">
                {formatCurrency(
                  (packageInformation.monthlyCost / 10000) * gstRate,
                  packageInformation.currencyCode
                )}
              </Text>
            ) : null}
          </div>
        </Flex>
      )}
      <Flex py="1">
        <div css={styles.largeColumn} />
        <div css={styles.smallColumn}>{t("admin-org:subscriptions.plan.monthly_total_label")}</div>
        <div css={styles.costColumn}>
          {loading ? (
            <Text data-testid="package-total-calculating">
              {t("admin-org:subscriptions.calculating")}
            </Text>
          ) : packageInformation ? (
            <Text fontWeight="bold" data-testid="package-total">
              {formatCurrency(
                packageInformation.monthlyCost / 100 +
                  (organisation.countryCode === "NZ"
                    ? (packageInformation.monthlyCost / 10000) * gstRate
                    : 0),
                packageInformation.currencyCode
              )}
            </Text>
          ) : null}
        </div>
      </Flex>
    </Box>
  );
};
