import { sortBy } from "lodash-es";
import { forwardRef } from "react";
import { useQuery } from "react-query";

import { useI18n } from "~/hooks/use-i18n";

import { restFetcher } from "../../graphql-hooks/custom-fetcher";
import { Select } from "../../shared/ui";

export const ModalitiesSelect = forwardRef<
  HTMLSelectElement,
  Omit<Parameters<typeof Select>[0], "children">
>((props, ref) => {
  const { t } = useI18n("account");
  const { data } = useQuery<{ id: number; name: string }[]>("/modality/getAll", () =>
    restFetcher("/v1/modality/getAll")
  );
  return (
    <Select {...props} ref={ref}>
      <option disabled={true} value="">
        {t("account:signup.formset_select_placeholder")}
      </option>
      {sortBy(data ?? [], "name").map(m => (
        <option key={m.id} value={m.id}>
          {m.name}
        </option>
      ))}
    </Select>
  );
});
ModalitiesSelect.displayName = "ModalitiesSelect";

export default ModalitiesSelect;
