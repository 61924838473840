import "tippy.js/dist/tippy.css";

import Tippy from "@tippy.js/react";

import styled, { theme } from "../../theme";
import { Box } from "../primitives";

export const ActionsMenu = styled(Tippy)`
  .tippy-content {
    background: ${theme.colors.neutral.lightest};
    padding: 6px 0;
    border-radius: 0.15rem;
  }

  background: ${theme.colors.neutral.lightest};
  border: 1px solid ${theme.colors.neutral.medium};
  min-width: 6.25rem;
  max-width: 100% !important;
`;

ActionsMenu.defaultProps = {
  trigger: "click",
  zIndex: 2,
  placement: "bottom-end",
  appendTo: "parent",
  arrow: false,
  interactive: true,
};

export const MenuList = styled(Box)`
  max-height: 12.5rem;
  overflow: auto;
`;
