import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";

import config from "../../routes/config";
import { useToast } from "../../shared/components/alerts/toast-provider";
import { PopupActionsMenu, PopupMenuItem } from "../../shared/components/forms/popup-actions-menu";
import type { Appointment } from "./types";

interface AppointmentActions {
  appointment: Appointment;
}

export const AppointmentActionsMenu = forwardRef<HTMLDivElement, AppointmentActions>(
  ({ appointment, ...props }, forwardedRef) => {
    const { t } = useI18n("org");
    const { enqueueSuccess, enqueueError } = useToast();
    const { telehealthRoom, client } = appointment;
    const clientRoute = `/n/clients/${client.id}`;
    const navigate = useNavigate();

    return (
      <>
        <div {...props} ref={forwardedRef}>
          <PopupActionsMenu label={t("org:dashboard.appointments.actions.label")}>
            {telehealthRoom && (
              <>
                <PopupMenuItem
                  id={`${appointment.id}-start-call`}
                  label={t("org:dashboard.appointments.actions.start_call")}
                  onClick={() =>
                    window.open(`${config.telehealthAppRoute}/${telehealthRoom.token}`, "_blank")
                  }
                />
                <PopupMenuItem
                  id={`${appointment.id}-copy-link`}
                  label={t("org:dashboard.appointments.actions.copy_link.label")}
                  onClick={async () => {
                    if (!navigator.clipboard) {
                      return enqueueError(t("org:dashboard.appointments.actions.copy_link.error"));
                    }
                    const callUrl = `${config.telehealthAppRoute}${telehealthRoom.token}`;
                    await navigator.clipboard.writeText(callUrl);
                    enqueueSuccess(t("org:dashboard.appointments.actions.copy_link.success"));
                  }}
                />
              </>
            )}
            <PopupMenuItem
              id={`${appointment.id}-open-client`}
              onClick={() => navigate(clientRoute)}
              label={t("org:dashboard.appointments.actions.open_client")}
            />
            <PopupMenuItem
              id={`${appointment.id}-open-calendar`}
              label={t("org:dashboard.appointments.actions.open_calendar")}
              onClick={() => navigate(`/scheduling`)}
            />
          </PopupActionsMenu>
        </div>
      </>
    );
  }
);

AppointmentActionsMenu.displayName = "AppointmentActionsMenu";
