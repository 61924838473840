import { css } from "@emotion/core";
import type { InputHTMLAttributes, ReactNode } from "react";
import { createContext, useContext } from "react";
import { useFocusWithin, useId } from "react-aria";
import {
  GridList as ReactAriaGridList,
  GridListItem as ReactAriaGridListItem,
  type GridListItemProps as ReactAriaGridListItemProps,
} from "react-aria-components";
import type { FieldValues, UseFormReturn } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { NcFieldSelect, NcFieldText, NcForm } from "./forms";
import { NcFlexLayout } from "./nc-flex-layout";

export type GridListTableBreakpoint = Omit<
  keyof typeof ncTheme.mediaQueries.width,
  "xsmallOnly" | "smallOnly" | "mediumOnly" | "largeOnly"
>;

const defaultBreakpoint = "large";

const GridListTableContext = createContext<{
  tableLayoutBreakpoint: GridListTableBreakpoint;
}>({ tableLayoutBreakpoint: defaultBreakpoint });

const FilterSortContext = createContext<{
  gridListId: string | null;
}>({ gridListId: null });

const getBreakpointQuery = (breakpoint: GridListTableBreakpoint) => {
  const queries = ncTheme.mediaQueries.width;
  return queries[breakpoint as keyof typeof queries] || undefined;
};

const getGridListTableStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: grid;
    width: 100%;

    ${getBreakpointQuery(breakpoint)} {
      display: table;
    }
  `;
};

interface GridListTableProps extends InputHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  tableLayoutBreakpoint?: GridListTableBreakpoint;
}

export const NcGridListTable = ({
  children,
  tableLayoutBreakpoint = defaultBreakpoint,
  ...props
}: GridListTableProps) => {
  return (
    <GridListTableContext.Provider value={{ tableLayoutBreakpoint }}>
      <div data-nc="NcGridListTable" css={getGridListTableStyles(tableLayoutBreakpoint)} {...props}>
        {children}
      </div>
    </GridListTableContext.Provider>
  );
};

const getHeaderStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: none;
    ${getBreakpointQuery(breakpoint)} {
      display: table-row;
    }
  `;
};

const Header = ({ children, ...props }: { children: ReactNode }) => {
  const contextBreakpoint = useContext(GridListTableContext);
  return (
    <div
      data-nc="NcGridListTable.Header"
      role="presentation"
      css={getHeaderStyles(contextBreakpoint.tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </div>
  );
};

const getHeaderCellStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    font-weight: bold;
    color: ${ncTheme.colors.main};

    ${getBreakpointQuery(breakpoint)} {
      display: table-cell;
      border-bottom: 1px solid ${ncTheme.colors.uiLight};
      padding: ${ncTheme.spacing(2)};
    }
  `;
};

const HeaderCell = ({ children, ...props }: { children: ReactNode }) => {
  const contextBreakpoint = useContext(GridListTableContext);
  return (
    <div
      data-nc="NcGridListTable.HeaderCell"
      css={getHeaderCellStyles(contextBreakpoint.tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </div>
  );
};

const getBodyRowStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: grid;
    gap: ${ncTheme.spacing(2)};
    transition: background-color ${ncTheme.transitionSpeed.fast};
    padding: ${ncTheme.spacing(2)};
    outline-style: none;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${ncTheme.colors.uiLight};
    }

    &[data-href] {
      cursor: pointer;
      &[data-hovered="true"],
      &[data-focused="true"] {
        background-color: ${ncTheme.colors.focused};
      }
    }
    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles}
    }

    ${getBreakpointQuery("small")} {
      grid-template-columns: 1fr 1fr;
    }

    ${getBreakpointQuery(breakpoint)} {
      display: table-row;
      &:not(:last-of-type) {
        > * {
          border-bottom: 1px solid ${ncTheme.colors.uiLight};
        }
      }
    }
  `;
};

const Body = ({ ...props }) => {
  const { gridListId } = useContext(FilterSortContext);
  const contents = (
    <ReactAriaGridList
      css={css`
        display: contents;
      `}
      {...{
        ...(gridListId ? { id: gridListId } : {}),
        ...props,
      }}
    />
  );

  return gridListId ? (
    <div
      css={css`
        display: contents;
      `}
      aria-live="polite"
    >
      {contents}
    </div>
  ) : (
    <>{contents}</>
  );
};

const BodyRow = ({ children, ...props }: ReactAriaGridListItemProps) => {
  const contextBreakpoint = useContext(GridListTableContext);
  return (
    <ReactAriaGridListItem
      data-nc="NcGridListTable.BodyRow"
      css={getBodyRowStyles(contextBreakpoint.tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </ReactAriaGridListItem>
  );
};

const getBodyCellStyles = (breakpoint: GridListTableBreakpoint) => {
  const breakpointQuery = getBreakpointQuery(breakpoint);
  return {
    itemCell: css`
      padding: ${ncTheme.spacing(2)};

      ${breakpointQuery} {
        display: table-cell;
        vertical-align: middle;
      }
    `,
    heading: css`
      font-weight: bold;
      color: ${ncTheme.colors.main};
      margin-block-end: ${ncTheme.spacing(1)};

      ${breakpointQuery} {
        ${ncTheme.utilities.visuallyHiddenStyles}
      }
    `,
  };
};

interface BodyCellProps extends InputHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  heading: string;
}

const BodyCell = ({ children, heading, ...props }: BodyCellProps) => {
  const contextBreakpoint = useContext(GridListTableContext);
  const styles = getBodyCellStyles(contextBreakpoint.tableLayoutBreakpoint);
  return (
    <div data-nc="NcGridListTable.BodyCell" css={styles.itemCell} {...props}>
      <div css={styles.heading}>{heading}</div>
      {children}
    </div>
  );
};

export type SortAttributes = { id: string; label: string }[];

const FilterSortProvider = ({ children }: { children: ReactNode }) => {
  const gridListId = useId();
  return <FilterSortContext.Provider value={{ gridListId }}>{children}</FilterSortContext.Provider>;
};

export type FilterSortFormValues = {
  sort: string;
  order: string;
  filter: string;
};

interface FilterSortProps<T extends FieldValues> {
  form: UseFormReturn<T, unknown>;
  sortAttributes: SortAttributes;
}

function FilterSort<T extends object>({ sortAttributes, form }: FilterSortProps<T>) {
  const { gridListId } = useContext(FilterSortContext);
  const {
    focusWithinProps: { onFocus, onBlur },
  } = useFocusWithin({
    onFocusWithinChange: isFocusWithin => {
      if (!isFocusWithin && gridListId) {
        const firstChild = document.getElementById(gridListId)?.firstChild as HTMLElement;
        firstChild?.focus();
      }
    },
  });

  if (!gridListId) {
    console.error(
      "NcGridListTable.FilterSort must be used within a NcGridListTable.FilterSortGroup"
    );
    return <></>;
  }

  return (
    <NcForm
      data-nc="NcGridListTable.FilterSort"
      form={form as UseFormReturn<FieldValues, unknown>}
      css={css`
        max-width: initial;
      `}
      aria-controls={gridListId}
      {...{
        onFocus,
        onBlur,
      }}
    >
      <NcFlexLayout
        css={css`
          justify-content: space-between;
          flex-grow: 1;
        `}
      >
        <NcFlexLayout>
          <NcFieldText label="Filter" name="filter" type="search" variant="horizontal" />
        </NcFlexLayout>
        <NcFlexLayout>
          <NcFlexLayout>
            <NcFieldSelect
              label="Sort by"
              name="sort"
              inputWidth="small"
              variant="horizontal"
              labelValue="id"
              items={sortAttributes}
            />
            <NcFieldSelect
              label="Order by"
              name="order"
              inputWidth="small"
              variant="horizontal"
              labelValue="id"
              items={[
                { label: "Ascending", id: "asc" },
                { label: "Descending", id: "desc" },
              ]}
            />
          </NcFlexLayout>
        </NcFlexLayout>
      </NcFlexLayout>
    </NcForm>
  );
}

NcGridListTable.Header = Header;
NcGridListTable.HeaderCell = HeaderCell;
NcGridListTable.Body = Body;
NcGridListTable.BodyRow = BodyRow;
NcGridListTable.BodyCell = BodyCell;
NcGridListTable.FilterSortGroup = FilterSortProvider;
NcGridListTable.FilterSort = FilterSort;
