import { css, Global, keyframes } from "@emotion/core";
import type { ReactNode } from "react";
import type { ModalOverlayProps as ReactAriaModalOverlayProps } from "react-aria-components";
import { Dialog as ReactAriaDialog, Modal as ReactAriaModal } from "react-aria-components";

import { ncTheme } from "../nc-theme";

const animations = {
  modalSlideUp: keyframes`
        from {
            transform: translateY(3rem);
        }

        to {
            transform: translateX(0);
        }
    `,

  modalFade: keyframes`
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    `,
};

const styles = {
  global: css`
    .react-aria-ModalOverlay {
      position: fixed;
      inset: 0;
      z-index: 1;
      display: grid;
      place-items: center;
      background-color: ${ncTheme.colors.dark}80;
      &[data-entering] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }
  `,
  dialog: css`
    position: fixed;
    left: 0;
    right: 0;
    outline: none;
    max-width: 40rem;
    margin: ${ncTheme.spacing(4)};
    border-radius: ${ncTheme.borderRadius.medium};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    background-color: ${ncTheme.colors.light};
    color: ${ncTheme.colors.dark};
    box-shadow: ${ncTheme.shadows.xlarge};

    ${ncTheme.mediaQueries.width.small} {
      margin-inline: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      &[data-entering] {
        animation:
          ${animations.modalSlideUp} ${ncTheme.transitionSpeed.fast},
          ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation:
          ${animations.modalSlideUp} ${ncTheme.transitionSpeed.fast} reverse ease-in,
          ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      &[data-entering] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }

    section {
      outline: none;
    }
  `,
  header: css`
    padding-inline: ${ncTheme.spacing(4)};
    padding-top: ${ncTheme.spacing(4)};
  `,
  body: css`
    padding: ${ncTheme.spacing(4)};
  `,
  footer: css`
    display: flex;
    gap: ${ncTheme.spacing(2)};
    justify-content: end;
    padding: ${ncTheme.spacing(4)};
  `,
};

interface NcModalProps extends ReactAriaModalOverlayProps {
  children: ReactNode;
  ariaLabel: string;
}

const NcModal = ({ children, ariaLabel, isDismissable = true, ...props }: NcModalProps) => (
  <>
    <Global styles={styles.global} />
    <ReactAriaModal data-nc="NcModal" css={styles.dialog} {...props} isDismissable={isDismissable}>
      <ReactAriaDialog aria-label={ariaLabel}>{children}</ReactAriaDialog>
    </ReactAriaModal>
  </>
);

const Header = ({ ...props }) => <div data-nc="NcModal.Header" css={styles.header} {...props} />;
const Body = ({ ...props }) => <div data-nc="NcModal.Body" css={styles.body} {...props} />;
const Footer = ({ ...props }) => <div data-nc="NcModal.Footer" css={styles.footer} {...props} />;

NcModal.Header = Header;
NcModal.Body = Body;
NcModal.Footer = Footer;

export { NcModal };
