import { css } from "@emotion/core";
import type { ReactNode, Ref } from "react";
import { forwardRef, useMemo } from "react";
import { useFilter } from "react-aria";
import { ComboBox as ReactAriaComboBox, Popover as ReactAriaPopover } from "react-aria-components";
import type { Key, ComboBoxProps as ReactAriaComboBoxProps } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { useI18n } from "../../use-i18n";
import { NcButton } from "../nc-button";
import { NcFormattedMessage } from "../nc-formatted-message";
import { NcIconChevronDown, NcIconMagnify } from "../nc-icons";
import { NcListBox } from "../nc-list-box";
import { NcLoadingIndicator } from "../nc-loading-indicator";
import { popoverStyles } from "../nc-popover";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import type { PresetInputWidths } from "./nc-input";
import { inputWidthStyles, NcInput } from "./nc-input";
import { useValidation } from "./use-validation";

const styles = {
  comboBox: css`
    position: relative;
  `,
  inputWrapper: css`
    position: relative;
    display: flex;
  `,
  action: css`
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: ${ncTheme.spacing(2.5)};
    border-radius: ${ncTheme.borderRadius.small};
  `,
  loading: (isLoading: boolean | undefined) => css`
    background-color: ${ncTheme.colors.light};
    pointer-events: none;
    opacity: ${isLoading ? 1 : 0};
    transition: opacity ${ncTheme.transitionSpeed.fast} ease-in-out;
    padding-inline: ${ncTheme.spacing(2)};
  `,
  popover: css`
    padding: 0;
    min-width: var(--trigger-width);
    overflow-y: auto;
  `,
  item: css`
    &[data-focus-visible] {
      outline: none;
    }
  `,
};

const findItemById = (items: NcComboBoxItem[], id: Key): NcComboBoxItem | undefined =>
  items.find(item => item.id === id);

const findItemByValue = (items: NcComboBoxItem[], property: Key, value: Key) =>
  items.find(item => item[property] === value);

const getSelectedItem = (items: NcComboBoxItem[], property: Key, value: Key | { id: Key }) => {
  if (value && typeof value === "object") {
    return value?.id;
  }
  const found = findItemByValue(items, property, value as Key);
  return found?.id;
};

const EmptyState = () => {
  const { t } = useI18n();
  return <NcFormattedMessage variant="secondary">{t("No items found")}</NcFormattedMessage>;
};

export type NcComboBoxItem = {
  id: number | string;
} & Record<string, Key>;

export interface NcComboBoxProps
  extends FieldProps,
    PresetInputWidths,
    Omit<
      ReactAriaComboBoxProps<NcComboBoxItem>,
      | "className"
      | "style"
      | "name"
      | "defaultSelectedKey"
      | "selectedKey"
      | "items"
      | "defaultItems"
      | "defaultInputValue"
      | "onSelectionChange"
      | "autoFocus"
    > {
  defaultItems?: Iterable<NcComboBoxItem>;
  placeholder?: string;
  isLoading?: boolean;
  renderEmptyState?: () => ReactNode;
  storeItemAsValue?: boolean;
  labelValue?: Key;
  iconType?: "chevron" | "search";
}

const NcFieldComboBox = forwardRef(
  (
    {
      label,
      description,
      name,
      defaultItems,
      placeholder,
      renderEmptyState,
      isLoading,
      children,
      storeItemAsValue,
      labelValue = "label",
      inputWidth = "large",
      iconType = "chevron",
      variant,
      ...props
    }: NcComboBoxProps,
    ref
  ) => {
    const itemsArray = useMemo(
      () => Array.from(defaultItems as unknown as NcComboBoxItem[]),
      [defaultItems]
    );
    const {
      register,
      formState: { defaultValues },
      setValue,
    } = useFormContext();

    const { validationHandler } = useValidation({
      label: label,
      rules: {
        required: props?.isRequired ? {} : undefined,
      },
    });

    const field = register(name);

    const handleChange = (id: Key | null) => {
      if (!id) {
        setValue(name, null, {
          shouldValidate: true,
          shouldDirty: true,
        });
        return;
      }
      const item = findItemById(itemsArray, id);
      const value = item?.[labelValue];
      setValue(name, storeItemAsValue ? item : value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    const renderEmptyOrLoadingState = () => {
      if (isLoading) {
        return <NcLoadingIndicator />;
      }
      return renderEmptyState ? renderEmptyState() : <EmptyState />;
    };

    return (
      <ReactAriaComboBox
        data-nc="NcFieldComboBox"
        css={styles.comboBox}
        defaultItems={defaultItems}
        defaultSelectedKey={getSelectedItem(itemsArray, labelValue, defaultValues?.[name])}
        {...({
          ...props,
          innerRef: ref,
        } as unknown as ReactAriaComboBoxProps<NcComboBoxItem>)}
        validate={validationHandler}
        onBlur={field.onBlur}
        ref={field.ref}
        onSelectionChange={handleChange}
      >
        <NcField
          {...{
            variant,
            label,
            description,
          }}
        >
          <div css={[styles.inputWrapper, inputWidthStyles[inputWidth]]}>
            <NcInput placeholder={placeholder} aria-busy={isLoading} inputWidth={inputWidth} />
            <NcButton variant="icon" css={styles.action}>
              {iconType === "chevron" ? (
                <NcIconChevronDown aria-hidden="true" />
              ) : (
                <NcIconMagnify aria-hidden="true" />
              )}
            </NcButton>
            <NcLoadingIndicator
              css={[styles.action, styles.loading(isLoading)]}
              aria-hidden="true"
            />
          </div>
          <ReactAriaPopover css={[popoverStyles.popover, styles.popover]}>
            {children ? (
              <>{children}</>
            ) : (
              <NcListBox renderEmptyState={renderEmptyOrLoadingState}>
                {(item: NcComboBoxItem) => (
                  <NcListBox.Item id={item.id} css={styles.item}>
                    {item[labelValue]}
                  </NcListBox.Item>
                )}
              </NcListBox>
            )}
          </ReactAriaPopover>
        </NcField>
      </ReactAriaComboBox>
    );
  }
);

export interface NcControlledComboBoxProps
  extends FieldProps,
    PresetInputWidths,
    Omit<
      ReactAriaComboBoxProps<NcComboBoxItem>,
      "className" | "style" | "name" | "defaultSelectedKey" | "defaultInputValue" | "autoFocus"
    > {
  defaultItems?: Iterable<NcComboBoxItem>;
  items?: Iterable<NcComboBoxItem>;
  placeholder?: string;
  isLoading?: boolean;
  renderEmptyState?: () => ReactNode;
  labelValue?: Key;
  iconType?: "chevron" | "search";
  isOpen?: boolean;
}

const NcFieldControlledComboBox = forwardRef(
  (
    {
      label,
      description,
      items,
      defaultItems,
      defaultFilter,
      placeholder,
      renderEmptyState,
      isLoading,
      children,
      labelValue = "label",
      inputWidth = "large",
      iconType = "chevron",
      variant,
      isOpen,
      ...props
    }: NcControlledComboBoxProps,
    ref
  ) => {
    const { contains } = useFilter({ sensitivity: "base" });
    const itemFilter = (textValue: string, inputValue: string) => contains(textValue, inputValue);
    return (
      <ReactAriaComboBox
        data-nc="NcFieldControlledComboBox"
        css={styles.comboBox}
        items={items}
        defaultItems={defaultItems}
        defaultFilter={defaultFilter ?? itemFilter}
        allowsEmptyCollection
        {...({
          ...props,
        } as unknown as ReactAriaComboBoxProps<NcComboBoxItem>)}
      >
        <NcField
          {...{
            variant,
            label,
            description,
          }}
        >
          <div css={[styles.inputWrapper, inputWidthStyles[inputWidth]]}>
            <NcInput
              placeholder={placeholder}
              aria-busy={isLoading}
              inputWidth={inputWidth}
              ref={ref as Ref<HTMLInputElement>}
            />
            <NcButton variant="icon" css={styles.action}>
              {iconType === "chevron" ? (
                <NcIconChevronDown aria-hidden="true" />
              ) : (
                <NcIconMagnify aria-hidden="true" />
              )}
            </NcButton>
            <NcLoadingIndicator
              css={[styles.action, styles.loading(isLoading)]}
              aria-hidden="true"
            />
          </div>
          <ReactAriaPopover css={[popoverStyles.popover, styles.popover]} isOpen={isOpen}>
            {children ? (
              <>{children}</>
            ) : (
              <NcListBox
                renderEmptyState={renderEmptyState ? renderEmptyState : () => <EmptyState />}
              >
                {(item: NcComboBoxItem) => (
                  <NcListBox.Item id={item.id} textValue={`${item[labelValue]}`}>
                    {item[labelValue]}
                  </NcListBox.Item>
                )}
              </NcListBox>
            )}
          </ReactAriaPopover>
        </NcField>
      </ReactAriaComboBox>
    );
  }
);

export { NcFieldComboBox, NcFieldControlledComboBox };
