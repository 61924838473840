import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { canDeleteClientRecords, useAccount } from "~/account/account.context";
import { useI18n } from "~/hooks/use-i18n";
import { PopupActionsMenu, PopupMenuItem } from "~/shared/components/forms/popup-actions-menu";

import { Reminder } from "./types";

interface ReminderActions {
  reminder: Reminder;
  onReminderEdit: (reminder: Reminder) => void;
  onReminderComplete: (reminder: Reminder) => void;
  onReminderDelete: (reminder: Reminder) => void;
}

export const ReminderActionsMenu = forwardRef<HTMLDivElement, ReminderActions>(
  ({ reminder, onReminderEdit, onReminderComplete, onReminderDelete, ...props }, forwardedRef) => {
    const { t } = useI18n("org");
    const { account, organisation } = useAccount();
    const navigate = useNavigate();

    return (
      <>
        <div {...props} ref={forwardedRef}>
          <PopupActionsMenu label={t("org:dashboard.reminders.actions.label")}>
            <PopupMenuItem
              id={`${reminder.id}-open-client`}
              onClick={() => navigate(`/n/clients/${reminder.patientId}`)}
              label={t("org:dashboard.reminders.actions.open_client")}
            />
            {!organisation.readOnlyMode && (
              <>
                <PopupMenuItem
                  id={`${reminder.id}-complete`}
                  onClick={() => onReminderComplete(reminder)}
                  label={t("org:dashboard.reminders.actions.complete.label")}
                />
                <PopupMenuItem
                  id={`${reminder.id}-edit`}
                  onClick={() => onReminderEdit(reminder)}
                  label={t("org:dashboard.reminders.actions.edit")}
                />
                {canDeleteClientRecords(account) && (
                  <PopupMenuItem
                    id={`${reminder.id}-delete`}
                    onClick={() => onReminderDelete(reminder)}
                    label={t("org:dashboard.reminders.actions.delete")}
                    danger
                  />
                )}
              </>
            )}
          </PopupActionsMenu>
        </div>
      </>
    );
  }
);

ReminderActionsMenu.displayName = "ReminderActionsMenu";
