import { useState } from "react";

import { useAddonsQuery, type AddonsQuery } from "./api";

export type Addon = AddonsQuery["addonFeatures"]["features"][0];

export const useAddons = () => {
  const [addons, setAddons] = useState<Addon[]>([]);

  const {
    isError: error,
    isLoading: loading,
    refetch,
  } = useAddonsQuery(undefined, {
    onSuccess: data => setAddons(data.addonFeatures.features),
  });

  const toggleAddon = (id: number) => {
    const addon = addons.find(a => a.id === id);
    if (addon) {
      setAddons(addonState =>
        addonState.map(a => {
          if (a.id !== addon.id) {
            return a;
          }
          return { ...a, enabled: !a.enabled };
        })
      );
    }
  };

  return {
    loading,
    error,
    addons,
    refetch,
    toggleAddon,
    empty: addons.length === 0,
  };
};
