import { useMutation, UseMutationOptions } from "react-query";

import { type Exact } from "~/graphql-hooks/types";

import { restFetcher } from "../graphql-hooks/custom-fetcher";

type AddOrganisationMutationVariables = Exact<{
  input: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      username: string;
      password: string;
      latestEulaAgreed: true;
      modalityId: string;
    };
    clinic: {
      email: string;
      phone: string;
      countryCode: string;
      legalName: string;
    };
    captcha: string;
  };
}>;

type TSuccess = unknown;
type TError = { message: string };
type TContext = { attempts?: number };

export const useSignupMutation = (
  options?: UseMutationOptions<TSuccess, TError, AddOrganisationMutationVariables, TContext>
) => {
  return useMutation<TSuccess, TError, AddOrganisationMutationVariables, TContext>(
    (variables?: AddOrganisationMutationVariables) =>
      (() =>
        restFetcher("/v1/signup/add", {
          method: "POST",
          body: JSON.stringify(variables?.input),
        }))(),
    options
  );
};
