import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { z } from "zod";

import { restPoster } from "~/graphql-hooks/custom-fetcher";
import { zDateAsString } from "~/shared/functions/zodInputHelpers";

const CLIENTS_ENDPOINT = "clients";
const DUPLICATE_CLIENT_ENDPOINT = "clients/duplicates";

export const inviteClientFormSchema = z.object({
  firstName: z.string().min(1, { message: "org:dashboard.clients.invite.form.errors.first_name" }),
  lastName: z.string().min(1, { message: "org:dashboard.clients.invite.form.errors.last_name" }),
  email: z
    .string()
    .min(1, { message: "org:dashboard.clients.invite.form.errors.email" })
    .email({ message: "org:dashboard.clients.invite.form.errors.email_invalid" }),
  inviteSubject: z
    .string()
    .min(1, { message: "org:dashboard.clients.invite.form.errors.subject_line" }),
  inviteMessage: z
    .string()
    .min(1, { message: "org:dashboard.clients.invite.form.errors.message_body" }),
  inviteHeading: z
    .string()
    .min(1, { message: "org:dashboard.clients.invite.form.errors.message_heading" }),
  inviteButton: z
    .string()
    .min(1, { message: "org:dashboard.clients.invite.form.errors.start_button_label" }),
});

export type InviteClientFormData = z.infer<typeof inviteClientFormSchema>;

export const clientPayloadSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
});

export type ClientPayload = z.infer<typeof clientPayloadSchema>;

const duplicateClientsObjectSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().email().optional(),
  currentStatus: z.object({
    id: z.number(),
    active: z.boolean(),
    patientId: z.number(),
    clinicId: z.number(),
    created: zDateAsString(),
  }),
  clinicId: z.number(),
  selfUpdatedLast: z.boolean(),
  created: zDateAsString(),
  accessControl: z.array(z.unknown()),
  restricted: z.boolean(),
  clientId: z.string(),
  accessLevel: z.string(),
  addresses: z.array(z.unknown()),
  sid: z.string(),
  securePatientId: z.number(),
  groupingKey: z.number(),
});
export type DuplicateClient = z.infer<typeof duplicateClientsObjectSchema>;

export const duplicateClientsResponse = z.array(duplicateClientsObjectSchema);

export type DuplicateClientsResponse = z.infer<typeof duplicateClientsResponse>;

//This endpoint is not very restful, so were using a post request inside a useQuery hook
export function useDuplicateClientsQuery(
  enabled: boolean,
  variables: ClientPayload,
  options: UseQueryOptions<unknown, unknown, DuplicateClientsResponse> = {}
) {
  return useQuery<unknown, unknown, DuplicateClientsResponse>(
    [DUPLICATE_CLIENT_ENDPOINT, variables],
    async () => {
      clientPayloadSchema.parse(variables);
      const resp = await restPoster(`/v1/${DUPLICATE_CLIENT_ENDPOINT}`, variables);
      return duplicateClientsResponse.parse(resp);
    },
    { enabled, staleTime: Infinity, ...options }
  );
}

useDuplicateClientsQuery.getKey = () => [DUPLICATE_CLIENT_ENDPOINT];

const clientInviteResponseSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  selfUpdatedLast: z.boolean(),
  medicalChecklistId: z.number(),
  clinicId: z.number(),
  keyWorkerIds: z.array(z.number()),
  status: z.object({
    id: z.number(),
    active: z.boolean(),
    clinicId: z.number().optional(),
    created: zDateAsString(),
  }),
  statuses: z.array(
    z.object({
      id: z.number(),
      active: z.boolean(),
      created: zDateAsString(),
    })
  ),
  created: zDateAsString(),
  patientDementiaSundry: z.array(z.unknown()),
  patientRelationshipsA: z.array(z.unknown()),
  patientRelationshipsB: z.array(z.unknown()),
  patientTags: z.array(z.unknown()),
  patientsReferrals: z.array(z.unknown()),
  addresses: z.array(z.unknown()),
  teams: z.array(z.unknown()).optional(),
  restricted: z.boolean(),
  accessLevel: z.string(),
  sid: z.string(),
  securePatientId: z.number(),
  groupingKey: z.number(),
});

export type ClientInviteResponse = z.infer<typeof clientInviteResponseSchema>;

export const useInviteClientMutation = () =>
  useMutation<ClientInviteResponse, unknown, { variables: ClientPayload }>(
    async ({ variables }) => {
      clientPayloadSchema.parse(variables);
      const resp = await restPoster(`/v1/${CLIENTS_ENDPOINT}`, variables);
      return clientInviteResponseSchema.parse(resp);
    }
  );

const sendInvitePayloadSchema = z.object({
  inviteSubject: z.string(),
  inviteMessage: z.string(),
  inviteHeading: z.string(),
  inviteButton: z.string(),
});

export type SendInvitePayload = z.infer<typeof sendInvitePayloadSchema>;

export const useSendInviteMutation = () =>
  useMutation<unknown, unknown, { clientId: number; variables: SendInvitePayload }>(
    async ({ clientId, variables }) => {
      sendInvitePayloadSchema.parse(variables);
      return restPoster(`/v1/client-invites/${clientId}/sendLink`, variables);
    }
  );
