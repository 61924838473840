import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type {
  CheckboxGroupProps as ReactAriaCheckboxGroupProps,
  CheckboxProps as ReactAriaCheckboxProps,
} from "react-aria-components";
import {
  Checkbox as ReactAriaCheckbox,
  CheckboxGroup as ReactAriaCheckboxGroup,
} from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { svgToDataUri } from "../../utils";
import { labelStyles, NcLabel } from "../nc-label";
import type { FieldLabelProps, FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import { inputStyles } from "./nc-input";
import { useValidation } from "./use-validation";

interface FieldCheckboxProps
  extends FieldProps,
    Omit<ReactAriaCheckboxGroupProps, "name" | "validate" | "onBlur" | "onChange"> {
  onChange?: (value: unknown) => void;
  options: {
    label: string;
    value: string;
  }[];
}

interface NcCheckboxProps extends ReactAriaCheckboxProps {
  children: ReactNode;
}

interface NcCheckboxGroupProps extends ReactAriaCheckboxGroupProps, FieldLabelProps {
  children: ReactNode;
}

const svgs = {
  checked: svgToDataUri(
    `<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>`
  ),
  indeterminate: svgToDataUri(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h8"/></svg>`
  ),
};

export const checkboxStyles = {
  wrap: css`
    align-items: center;
    display: flex;
    gap: ${ncTheme.spacing(2)};
    transition: transform ${ncTheme.transition("color text-decoration border-color")};

    &[data-disabled] {
      cursor: not-allowed;
      color: ${ncTheme.colors.disabled};
    }

    &[data-hovered],
    &[data-focus-visible] {
      color: ${ncTheme.colors.main};
      text-decoration: underline;
      border-radius: ${ncTheme.borderRadius.medium};
    }

    &[data-focus-visible] {
      outline: solid;
      ${ncTheme.utilities.outlineStyles}
      border-radius: ${ncTheme.borderRadius.medium};
    }
  `,
  checkbox: css`
    ${inputStyles};

    min-width: unset;
    width: 1.5em;
    height: 1.5em;
    padding: 0;

    [data-disabled] & {
      background-color: ${ncTheme.colors.disabledLight};
    }

    [data-selected] & {
      background-color: ${ncTheme.colors.active};
      background-image: url("${svgs.checked}");
    }

    [data-disabled][data-selected] & {
      background-color: ${ncTheme.colors.disabled};
    }

    [data-indeterminate] & {
      background-color: ${ncTheme.colors.active};
      background-image: url("${svgs.indeterminate}");
    }

    :focus &,
    :active &,
    :hover &,
    :focus-within & {
      background-color: ${ncTheme.colors.active};
    }
  `,
  label: css`
    ${labelStyles};
    line-height: 1.5;
  `,
  group: css`
    display: grid;
    gap: ${ncTheme.spacing(3)};
  `,
};

const Checkbox = forwardRef<HTMLLabelElement, NcCheckboxProps>(({ children, ...props }, ref) => {
  return (
    <ReactAriaCheckbox data-nc="NcCheckbox" ref={ref} css={checkboxStyles.wrap} {...props}>
      <div css={checkboxStyles.checkbox}></div>
      <div css={checkboxStyles.label}>{children}</div>
    </ReactAriaCheckbox>
  );
});

const CheckboxGroup = forwardRef<HTMLDivElement, NcCheckboxGroupProps>(
  ({ label, labelNode, children, ...props }, ref) => {
    return (
      <ReactAriaCheckboxGroup
        data-nc="NcCheckboxGroup"
        ref={ref}
        css={checkboxStyles.group}
        {...props}
      >
        <NcLabel>{labelNode || label}</NcLabel>
        {children}
      </ReactAriaCheckboxGroup>
    );
  }
);

export const NcFieldCheckbox = ({ name, description, options, ...props }: FieldCheckboxProps) => {
  const { register, setValue, formState } = useFormContext();

  const field = register(name);

  const { defaultValues } = formState;

  const { validationHandler } = useValidation({
    label: props.label,
    rules: {
      required: props?.isRequired ? {} : undefined,
    },
  });

  const handleChange = (value: unknown) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props.onChange?.(value);
  };

  return (
    <CheckboxGroup
      data-nc="NcFieldCheckbox"
      {...field}
      {...props}
      name={name}
      defaultValue={defaultValues?.[name] || []}
      validate={validationHandler}
      onChange={handleChange}
    >
      <NcField description={description}>
        {options.map((option, index) => (
          <Checkbox name={`${name}.${index}`} key={option.value} value={option.value}>
            {option.label}
          </Checkbox>
        ))}
      </NcField>
    </CheckboxGroup>
  );
};
