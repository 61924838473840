import { css, Global } from "@emotion/core";

import { ncTheme } from "./nc-theme";

type GetSpacingRuleArguments = {
  rootCssSelector: string;
  cssClassPrefix: string;
  cssClassPostfix?: string;
  cssClassUnit?: string;
  ruleUnit: string;
  rulePrefix: string;
  rulePostfix?: string;
};

function getSpacingRule({
  rootCssSelector,
  cssClassPrefix,
  cssClassPostfix = "",
  cssClassUnit = "",
  ruleUnit,
  rulePrefix,
  rulePostfix,
}: GetSpacingRuleArguments) {
  return `
    ${rootCssSelector} .${cssClassPrefix}${cssClassPostfix}${
      cssClassUnit ? `-${cssClassUnit}` : ""
    } {
        ${rulePrefix}${rulePostfix ? `-${rulePostfix}` : ""}: ${ruleUnit};
    }
    `;
}

export const spacingSizes = [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, "auto"];

function getSpacingRules(rootCssSelector: string, rulePrefix: string) {
  return spacingSizes.map(value => {
    if (rulePrefix === "padding" && value === "auto") return "";
    const cssClassUnit = String(value).replace(".", "\\.");
    const cssClassPrefix = rulePrefix[0];
    const ruleUnit = typeof value === "number" ? ncTheme.spacing(value) : value;
    return `
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          ruleUnit,
          rulePrefix,
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "x",
          ruleUnit,
          rulePrefix,
          rulePostfix: "inline",
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "y",
          ruleUnit,
          rulePrefix,
          rulePostfix: "block",
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "l",
          ruleUnit,
          rulePrefix,
          rulePostfix: "inline-start",
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "r",
          ruleUnit,
          rulePrefix,
          rulePostfix: "inline-end",
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "t",
          ruleUnit,
          rulePrefix,
          rulePostfix: "block-start",
        })}
        ${getSpacingRule({
          rootCssSelector,
          cssClassUnit,
          cssClassPrefix,
          cssClassPostfix: "b",
          ruleUnit,
          rulePrefix,
          rulePostfix: "block-end",
        })}
    `;
  });
}

function getGapRules(rootCssSelector: string) {
  return spacingSizes.map(value => {
    if (value === "auto") return "";
    const ruleUnit = typeof value === "number" ? ncTheme.spacing(value) : value;
    const cssClassUnit = String(value).replace(".", "\\.");
    return `${rootCssSelector} .gap-${cssClassUnit} {
          gap: ${ruleUnit};
        }
        ${rootCssSelector} .row-gap-${cssClassUnit} {
          row-gap: ${ruleUnit};
        }
        ${rootCssSelector} .column-gap-${cssClassUnit} {
          column-gap: ${ruleUnit};
        }
    `;
  });
}

export const NcUtilityCss = ({ rootCssSelector = "body" }: { rootCssSelector?: string }) => {
  const utilityStyles = css`
    ${getSpacingRules(rootCssSelector, "padding")}
    ${getSpacingRules(rootCssSelector, "margin")}
      ${getGapRules(rootCssSelector)}
  `;
  return <Global styles={utilityStyles} />;
};
