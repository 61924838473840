import { css } from "@emotion/core";
import { config } from "@noted/configuration";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { AccountContext } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";
import { FeaturePermission } from "~/graphql-hooks/types";
import { UnescapedTFunction, useI18n } from "~/hooks/use-i18n";
import { GROUPS_URL } from "~/org/groups/group-urls";
import { WHANAU_URL } from "~/org/whanau/whanau-urls";
import {
  OnboardingStateType,
  useAgendaOnboarding,
} from "~/scheduling/agenda/use-agenda-onboarding";
import { NavLink, NavLinkAnchor } from "~/shared/components/navigation";
import {
  AccessOverrides,
  Agenda,
  Auditing,
  Availability,
  Clients,
  Clipboard,
  Dashboard,
  GraphFile,
  Group,
  Invoicing,
  Logout,
  Organisation,
  PieChart,
  PurchaseOrders,
  ReportFile,
  Reporting,
  Roles,
  Subscription,
  Support,
  TeamAdmin,
  Teams,
  Users,
  Whanau,
} from "~/shared/icons";
import { IOrganisation } from "~/shared/types";
import { NavigableListItem } from "~/shared/ui/a11y/navigable-list";

import { ApplicationsSwitcher } from "./app-switcher";
import { NavigationContext } from "./navigation.context";
import { Sidebar, SidebarExpandButton, SidebarOverlay } from "./sidebar";

const getActiveNavDefinition = (
  t: UnescapedTFunction,
  pathname: string,
  isUpgradable: boolean,
  state: OnboardingStateType,
  featureEnabled: (key: FeaturePermission) => boolean,
  organisation: IOrganisation
) => {
  if (pathname.startsWith("/admin")) {
    return {
      key: "admin",
      label: t("administration_app"),
      links: [
        {
          icon: Clients,
          to: "/admin/clients",
          title: t("clients_admin"),
        },
        {
          icon: Users,
          to: "/admin/users",
          title: t("navigation_labels.users"),
        },
        {
          icon: Roles,
          to: "/admin/roles",
          title: t("navigation_labels.roles"),
        },
        { icon: Teams, to: "/admin/teams", title: t("navigation_labels.teams") },
        {
          icon: Organisation,
          to: "/admin/organisation-settings",
          title: t("organisation_settings"),
        },
        { icon: Clipboard, to: "/admin/client-settings", title: t("client_settings") },
        {
          icon: Subscription,
          to: "/admin/subscription",
          title: t("navigation_labels.subscription"),
          condition: !isUpgradable,
        },
      ],
    };
  }
  if (pathname.startsWith("/scheduling")) {
    return {
      key: "scheduling",
      label: t("scheduling_app"),
      links: [
        ...(state === "ready"
          ? [
              {
                icon: Agenda,
                to: "/scheduling/booking",
                title: t("scheduling_agenda"),
              },
            ]
          : []),
        {
          icon: Availability,
          to: "/scheduling/availability",
          title: t("scheduling_availability"),
        },
      ],
    };
  }
  if (pathname.startsWith("/primhd")) {
    return {
      key: "primhd",
      label: t("primhd_app"),
      links: [
        {
          icon: Clipboard,
          to: "/primhd/reporting",
          title: t("navigation_labels.primhd-reporting"),
        },
      ],
    };
  }
  if (pathname.startsWith("/auditing")) {
    return {
      key: "auditing",
      label: t("navigation_labels.auditing"),
      links: [
        {
          icon: Auditing,
          to: "/auditing/log",
          title: t("navigation_labels.auditing"),
        },
      ],
    };
  }
  if (pathname.startsWith("/extract")) {
    return {
      key: "extract",
      label: t("extract_app"),
      links: [
        {
          icon: Reporting,
          to: "/extract",
          title: t("navigation_labels.extract"),
        },
      ],
    };
  }
  if (pathname.startsWith("/n/acc")) {
    return {
      key: "fragor-acc",
      label: "ACC billing",
      links: [
        {
          icon: Invoicing,
          to: "/n/acc/invoicing",
          title: t("nav_title.invoicing"),
        },
        {
          icon: PurchaseOrders,
          to: "/n/acc/purchase-orders",
          title: t("nav_title.purchase_orders"),
          condition:
            featureEnabled(FeaturePermission.AccPurchaseOrders) &&
            organisation?.accDetails?.purchaseOrdersEnabled,
        },
      ],
    };
  }
  if (
    pathname.startsWith("/n/insights") ||
    pathname.startsWith("/n/reports") ||
    pathname.startsWith("/n/custom-reports")
  ) {
    return {
      key: "fragor-reporting",
      label: "Reporting",
      links: [
        { icon: PieChart, to: "/n/insights", title: t("nav_title.insights") },
        {
          icon: GraphFile,
          to: "/n/reports",
          title: t("nav_title.reports"),
          condition: featureEnabled(FeaturePermission.Reports),
        },
        {
          icon: ReportFile,
          to: "/n/custom-reports",
          title: t("nav_title.custom_reports"),
          condition: featureEnabled(FeaturePermission.CustomReports),
        },
      ],
    };
  }
  if (
    pathname.startsWith("/n") ||
    pathname.startsWith("/dashboard") ||
    pathname.startsWith("/user-settings")
  ) {
    return {
      key: "clients",
      label: "Client records",
      links: [
        { icon: Dashboard, to: "/dashboard", title: t("nav_title.dashboard") },
        { icon: Clients, to: "/n/clients", title: t("nav_title.clients") },
        {
          icon: Group,
          to: GROUPS_URL,
          title: t("nav_title.groups"),
          condition: featureEnabled(FeaturePermission.Groups),
        },
        {
          icon: Whanau,
          to: WHANAU_URL,
          title: t("nav_title.whanau"),
          condition: featureEnabled(FeaturePermission.Whanau),
        },
        {
          icon: AccessOverrides,
          to: "/n/overrides",
          title: t("nav_title.overrides"),
          condition: featureEnabled(FeaturePermission.UserAccessControl),
        },
        {
          icon: TeamAdmin,
          to: "/n/team-admin",
          title: t("nav_title.team_admin"),
          condition: featureEnabled(FeaturePermission.TeamAdministration),
        },
      ],
    };
  }
  return null;
};

const Navigation = () => {
  const { pathname } = useLocation();
  const { t } = useI18n();
  const { open, setOpen } = useContext(NavigationContext);
  const { organisation, account, isUpgradable } = useContext(AccountContext);
  const isWorker = account.securityRoles.some(s => s.key === "WORKER");
  const { state } = useAgendaOnboarding();
  const { featureEnabled } = useFeatures();

  const [desktopExpanded, desktopExpanderSetterFn] = useState(
    !(localStorage.getItem("ngStorage-mainNavExpanded") === "false")
  );

  const setDesktopExpanded = (expanded: boolean) => {
    desktopExpanderSetterFn(expanded);
    localStorage.setItem("ngStorage-mainNavExpanded", String(expanded));
  };

  const activeItem = getActiveNavDefinition(
    t,
    pathname,
    isUpgradable,
    state,
    featureEnabled,
    organisation
  );
  const onMobileNavClick = () => setOpen(!open);

  return (
    <>
      <SidebarOverlay />
      <Sidebar desktopExpanded={desktopExpanded} open={open}>
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
          aria-label={t("main_nav")}
          role="menubar"
        >
          {isWorker && (
            <li role="none">
              <ApplicationsSwitcher role="menuitem" sideBarCollapsed={!desktopExpanded} />
            </li>
          )}
          {activeItem && (
            <li role="none">
              <ul role="menu" aria-label={`${activeItem.label} ${t("sub_nav")}`}>
                {activeItem.links.map(link => (
                  <NavigableListItem key={link.to} role="none">
                    <NavLink
                      role="menuitem"
                      desktopExpanded={desktopExpanded}
                      onClick={onMobileNavClick}
                      {...link}
                    />
                  </NavigableListItem>
                ))}
              </ul>
            </li>
          )}
          <li
            role="presentation"
            css={css`
              margin-top: auto;
            `}
          />
          <NavLinkAnchor
            href={config.supportPageUrl}
            target="_blank"
            role="menuitem"
            desktopExpanded={desktopExpanded}
            icon={Support}
            title={t("support")}
            onClick={onMobileNavClick}
          />
          <NavLink
            desktopExpanded={desktopExpanded}
            role="menuitem"
            icon={Logout}
            to="/logout"
            title={t("logout")}
            onClick={onMobileNavClick}
          />
        </ul>
        <SidebarExpandButton
          desktopExpanded={desktopExpanded}
          setDesktopExpanded={setDesktopExpanded}
        />
      </Sidebar>
    </>
  );
};

export default Navigation;
