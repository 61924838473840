import { css } from "@emotion/core";
import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import { useI18n } from "~/hooks/use-i18n";
import { Magnify } from "~/shared/icons";
import { theme } from "~/shared/theme";

import { Label, VisuallyHidden } from "../../shared/ui";
import Input from "../../shared/ui/input";

const styles = css`
  width: 100%;

  .label {
    display: block;
    position: relative;
    width: 100%;

    svg {
      position: absolute;
      z-index: 2;
      left: ${theme.space[3]};
      top: calc(50% - 0.65rem);
      width: 1.3rem;
      height: 1.3rem;
    }

    input {
      padding-left: 2.8rem;
      z-index: 0;
    }
  }
`;

interface SearchBoxProps {
  id: string;
  labelKey?: string;
  defaultValue?: string;
  onChange: (term: string) => void;
  onKeyboardNavOut?: (event: KeyboardEvent) => void;
}

const SearchBox = ({ id, labelKey, defaultValue, onChange, onKeyboardNavOut }: SearchBoxProps) => {
  const { t } = useI18n("org");
  const [searchKey, setSearchKey] = useState(defaultValue || "");
  const [searchKeyDebounced] = useDebounce(searchKey, 600);
  const searchRef = useRef<HTMLInputElement>(null);

  const onKeyboardNav = (event: KeyboardEvent) => {
    if (event.key === "ArrowDown") {
      onKeyboardNavOut?.(event);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchKey(value);
  };

  const label = labelKey ? t(labelKey) : t("search");

  useEffect(() => {
    onChange(searchKeyDebounced);
  }, [searchKeyDebounced]);

  useEffect(() => {
    if (defaultValue === "" && searchRef?.current) {
      searchRef.current.value = "";
    }
  }, [defaultValue]);

  return (
    <div className="search-box" css={styles}>
      <Label className="label" as="span">
        <VisuallyHidden>{label}</VisuallyHidden>
        <Magnify />
        <Input
          id={id}
          ref={searchRef}
          aria-label={label}
          placeholder={label}
          defaultValue={searchKey}
          onChange={onInputChange}
          onKeyUp={onKeyboardNav as unknown as KeyboardEventHandler<HTMLInputElement>}
        />
      </Label>
    </div>
  );
};

export default SearchBox;
