import { css } from "@emotion/core";
import { NcBadge, NcHeading, NcPanel } from "@noted/noted-components";
import { groupBy, orderBy } from "lodash-es";
import { DateTime } from "luxon";
import { ReactNode, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { ConfirmActionModal } from "~/shared/components/actions-modal/confirm-actions-modal";
import { theme } from "~/shared/theme";

import {
  useAllRemindersQuery,
  useCompleteReminderMutation,
  useDeleteReminderMutation,
  useUpdateReminderMutation,
} from "../dashboard-queries";
import { NoRemindersIcon, ReminderIcon, ReminderOverdueIcon } from "../icons";
import { ReminderActionsMenu } from "./reminders-actions-menu";
import RemindersEditModal from "./reminders-edit-modal";
import { Reminder } from "./types";

const styles = {
  panel: {
    heading: css`
      font-weight: bold;
      margin-right: auto;
    `,
    empty: {
      container: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        gap: ${theme.space[2]};
        justify-content: center;
        padding: ${theme.space[6]};
      `,
      message: css`
        color: ${theme.colors.neutral.dark};
        font-style: italic;
      `,
    },
  },
  list: {
    container: css`
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    `,
    item: {
      container: css`
        position: relative;
      `,
      heading: css`
        position: sticky;
        top: 0;
        padding: ${theme.space[2]} ${theme.space[3]};
        display: flex;
        z-index: 1;
        background-color: ${theme.colors.neutral.lightest};
        border-color: ${theme.colors.neutral.light};
        border-bottom-style: solid;
        border-bottom-width: 1px;
      `,
      icon: css`
        text-align: center;
        line-height: 1;
      `,
    },
    itemNested: css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: ${theme.space[4]};
      padding: ${theme.space[3]};
    `,
    text: {
      secondary: css`
        font-size: 0.85em;
      `,
    },
  },
  details: {
    container: css`
      display: grid;
      gap: ${theme.space[1]};
    `,
    collapse: css`
      cursor: pointer;
      color: ${theme.colors.neutral.mediumDark} & [open] summary {
        margin-bottom: ${theme.space[2]};
      }
    `,
  },
};

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div css={styles.panel.empty.container}>
      <NoRemindersIcon opacity="0.5" width="8rem" />
      <p css={styles.panel.empty.message}>{t("org:dashboard.reminders.empty")}</p>
    </div>
  );
};

const ReminderInformation = ({ reminder }: { reminder: Reminder }) => {
  const { patient, title, description } = reminder;
  const { t } = useI18n("org");

  return (
    <div css={styles.details.container}>
      <div>
        {patient?.firstName} {patient?.lastName}
      </div>
      <div>
        <details css={styles.details.collapse}>
          <summary>{title}</summary>
          {description ? (
            description
          ) : (
            <div css={styles.panel.empty.message}>
              {t("org:dashboard.reminders.no_description")}
            </div>
          )}
        </details>
      </div>
    </div>
  );
};

const RemindersList = ({ reminders, children }: { reminders: Reminder[]; children: ReactNode }) => {
  const { t } = useI18n("org");
  const [editingReminder, setEditingReminder] = useState<Reminder>();
  const [deletingReminder, setDeletingReminder] = useState<Reminder>();
  const { mutate: saveReminder } = useUpdateReminderMutation();
  const { mutate: completeReminder } = useCompleteReminderMutation();
  const { mutate: deleteReminder, isLoading: isDeleting } = useDeleteReminderMutation();

  function getMonthNameAbbreviation(dueDate: string) {
    return DateTime.fromISO(dueDate).toFormat("MMM").toLocaleUpperCase();
  }

  function getDateDay(dueDate: string) {
    return DateTime.fromISO(dueDate).toFormat("dd");
  }

  const handleReminderSave = (reminder: Reminder) => {
    setEditingReminder(undefined);
    saveReminder(reminder);
  };

  const handleReminderDelete = (reminder: Reminder) => {
    setDeletingReminder(reminder);
  };

  return (
    <>
      <ul css={styles.list.container}>
        {reminders.map((reminder: Reminder) => (
          <li css={styles.list.itemNested} key={reminder.id}>
            <div css={styles.list.item.icon}>
              {children}
              <div css={styles.list.text.secondary}>
                {getDateDay(reminder.dueDate)} {getMonthNameAbbreviation(reminder.dueDate)}
              </div>
            </div>
            <ReminderInformation reminder={reminder} />
            <ReminderActionsMenu
              reminder={reminder}
              onReminderEdit={reminder => setEditingReminder(reminder)}
              onReminderComplete={({ id }) => completeReminder({ id })}
              onReminderDelete={reminder => handleReminderDelete(reminder)}
            />
          </li>
        ))}
      </ul>
      {editingReminder && (
        <RemindersEditModal
          reminder={editingReminder}
          handleExit={() => setEditingReminder(undefined)}
          onSaveReminder={handleReminderSave}
        />
      )}
      {deletingReminder && (
        <ConfirmActionModal
          title={t("org:dashboard.reminders.delete.heading")}
          message={t("org:dashboard.reminders.delete.message")}
          continueLabel={t("org:dashboard.reminders.actions.delete")}
          inProgress={isDeleting}
          onCancel={() => setDeletingReminder(undefined)}
          onContinue={() => {
            deleteReminder({ id: deletingReminder.id });
            setDeletingReminder(undefined);
          }}
          variant="danger"
        />
      )}
    </>
  );
};

const RemindersPanel = () => {
  const { t } = useI18n("org");

  const { data: reminders, isLoading, error } = useAllRemindersQuery();

  const today = DateTime.now().endOf("day");

  function groupReminders(reminders: Reminder[]) {
    return groupBy(reminders, (r: Reminder) => {
      if (isOverdue(r.dueDate)) {
        return "overdue";
      } else if (isToday(r.dueDate)) {
        return "today";
      } else {
        return "future";
      }
    });
  }

  function sortReminders(reminders: Reminder[]) {
    return orderBy(reminders, "dueDate");
  }

  const groupedReminders = groupReminders(sortReminders(reminders || []));

  function getDueDateDay(dueDate: string) {
    return DateTime.fromISO(dueDate).endOf("day");
  }

  function isOverdue(dueDate: string) {
    const dueDay = getDueDateDay(dueDate);
    return dueDay < today;
  }

  function isToday(dueDate: string) {
    const dueDay = getDueDateDay(dueDate);
    return dueDay.equals(today);
  }

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcHeading level={2} styleAs={4} css={styles.panel.heading}>
          {t("org:dashboard.reminders.heading")}
        </NcHeading>
        <NcBadge variant="defaultLight">{reminders?.length}</NcBadge>
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.reminders.name"),
              })
            : undefined
        }
      >
        {!reminders?.length && <PanelEmpty />}
        {reminders?.length ? (
          <ul style={{ position: "relative" }}>
            {groupedReminders.overdue?.length && (
              <li css={styles.list.item}>
                <div css={styles.list.item.heading}>
                  <NcBadge variant="dangerLight">{t("org:dashboard.reminders.overdue")}</NcBadge>
                </div>
                <RemindersList reminders={groupedReminders.overdue}>
                  <ReminderOverdueIcon height="1.75rem" />
                </RemindersList>
              </li>
            )}
            {groupedReminders.today?.length && (
              <li css={styles.list.item}>
                <div css={styles.list.item.heading}>
                  <NcBadge variant="defaultLight">{t("org:dashboard.reminders.today")}</NcBadge>
                </div>
                <RemindersList reminders={groupedReminders.today}>
                  <ReminderIcon height="1.75rem" />
                </RemindersList>
              </li>
            )}
            {groupedReminders.future?.length && (
              <li css={styles.list.item}>
                <div css={styles.list.item.heading}>
                  <NcBadge variant="neutralLight">{t("org:dashboard.reminders.future")}</NcBadge>
                </div>
                <RemindersList reminders={groupedReminders.future}>
                  <ReminderIcon height="1.75rem" />
                </RemindersList>
              </li>
            )}
          </ul>
        ) : null}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default RemindersPanel;
